import styled from "styled-components";
import HomeFooter from "./Footer";
import HomeNavbar from "./HomeNavbar";


const HomeWrapper = ({ children }) => {
      return (
            <>
                  <HomeNavbar />
                  <StyledHome>
                        {children}
                  </StyledHome>
                  <HomeFooter />

            </>
      );
};

export default HomeWrapper;
const StyledHome = styled.div`
      
padding-top:2.8rem !important;

`;