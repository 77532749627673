import styled from "styled-components";
import { Button } from "../../styles";
import { Link } from "react-router-dom";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { Carousel } from "react-bootstrap";
import { heroCar1, heroCar2, heroCar3, heroCar4 } from "../../assets";


const Hero = () => {
      const [accessToken] = useLocalStorage('accessToken');

      const headerImg = [
            heroCar1, heroCar2, heroCar3, heroCar4
      ];
      return (
            <div className="container">
                  <StyledHero>
                        <div className="row justify-content-between align-items-center">
                              <div className="col-md-6 col-lg-5 text-center text-md-start mb-4">
                                    <h1>
                                          Find the ideal car for your needs
                                    </h1>
                                    <p className="mt-5">
                                          Welcome to Reusautobestsales, your one-stop destination for finding the perfect foreign and new cars
                                    </p>
                                    {(accessToken === null || accessToken === undefined) ?
                                          <>
                                                <Link to='/cars' className="me-2">
                                                      <Button pry className="btn">
                                                            Buy Now
                                                      </Button>
                                                </Link>
                                                <Link to='/user/register'>
                                                      <Button className="btn border border-dark">
                                                            Get Started
                                                      </Button>
                                                </Link>
                                          </>
                                          :
                                          <Link to='/cars' className="me-2">
                                                <Button pry className="btn">
                                                      Buy Now
                                                </Button>
                                          </Link>
                                    }

                              </div>
                              <div className="col-md-6 col-lg-7 mt-5 mt-md-0 ">
                                    <Carousel
                                          indicators={false}
                                          controls={false}
                                          interval={2000}
                                    >
                                          {headerImg.map((image, idx) => (
                                                <Carousel.Item key={idx}>
                                                      <div className="main-image">
                                                            <img
                                                                  className="d-block mx-auto"
                                                                  src={image}
                                                                  alt={`Slide ${ idx }`}
                                                                  width='70%'
                                                            />
                                                      </div>
                                                </Carousel.Item>
                                          ))}
                                    </Carousel>
                              </div>
                        </div>
                  </StyledHero>
            </div>
      );
};

export default Hero;

const StyledHero = styled.section`
      margin-top: 7rem;   
      
      button {
            font-weight:bold;
      }
`;