import { Link, Navigate } from "react-router-dom";
// import { decrypt } from "../encrypt";
import { Button, ErrorPageMain } from '../styles';
import { useLocalStorage } from "../utils/useLocalStorage";
import HomeNavbar from "../layout/HomeNavbar";

const NoPageAccess = () => {

  const [accessToken] = useLocalStorage('accessToken');
  // let userData = decrypt(user_data);

  // if (accessToken) {
  //   return <Navigate to="/user" replace />;
  // }

  return (
    <>
      <HomeNavbar />
      <ErrorPageMain className="light-bg">
        <div className="container">
          <div className="col-md-7 mx-auto text-center ">
            <div className="pry-light br-md py-5 container">
              <h5 className="pry-text fw-light"> You need to be signed-in to access this page. </h5>
              <Link to="/user/login">
                <Button pry className="btn mt-3 btn-lg"> Sign In </Button>
              </Link>

            </div>
          </div>

        </div>
      </ErrorPageMain>
    </>
  );
};

export default NoPageAccess;
