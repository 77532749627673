import React from 'react';
import styled from 'styled-components';
import { profilePic } from '../../../assets';
import { Progress } from '../../../styles';
import { useLocalStorage } from '../../../utils/useLocalStorage';


const DashboardHeader = () => {
      const [userData] = useLocalStorage('userdata');

      const { profilePic: img } = userData;
      return (
            <StyledDashboardHeader>
                  <div className="row mb-4">
                        <div className="col-md-4 mb-2">
                              <div className="profile-img border">
                                    <img src={img || profilePic} alt="Profile" width="100%" />
                              </div>
                        </div>
                        <div className="col-md-4 mb-2">
                              <div className="border py-3 px-2 br-sm cards row align-content-between m-0">
                                    <div className="col-12">
                                          <p className="fw-bold">
                                                Loan Application
                                          </p>
                                    </div>
                                    <div className="col-12 p-0 ">
                                          {/* <p className="grey-bold-text m-0">
                                                In progress
                                          </p>
                                          <Progress
                                                max='100'
                                                value='70'
                                          >
                                          </Progress> */}
                                          <p className="grey-bold-text m-0">
                                                No data available
                                          </p>
                                    </div>
                              </div>
                        </div>
                        <div className="col-md-4 mb-2">
                              <div className="border py-3 px-2 br-sm cards row m-0 align-content-between">
                                    <div className="col-12">
                                          <p className="fw-bold">
                                                Car Bought
                                          </p>
                                    </div>
                                    <div className="col-12 p-0 ">
                                          <p className="grey-bold-text m-0">
                                                Total Number(s)
                                          </p>
                                          <h1 className="">
                                                0
                                          </h1>
                                    </div>
                              </div>
                        </div>
                  </div>

            </StyledDashboardHeader>
      );
};

export default DashboardHeader;

const StyledDashboardHeader = styled.div`

.cards {
height: 200px !important;
}

.profile-img {
      width:auto;
                  height:200px;
                  border-radius: 10px;

                  img {
                    width:100%;
                    height:100%;
                    margin:auto;
                    object-fit:contain;
                    border-radius: 10px;
                    background-position: top;
                  }
}




`;