import React, { useContext, useState } from 'react';
import { useFetch } from '../api/useFetch';
import FetchApi from '../api/getAxios';
import { placeholder1 } from '../assets';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Api from '../api/axios';


export const CarContext = React.createContext();


export const CarProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [carData, setCarData] = useState({ images: [placeholder1] });

    // search Fn
    const [searching, setSearching] = useState(false);
    const [filtering, setFiltering] = useState(false);
    const [result, setResult] = useState([]);

    const navigate = useNavigate();

    const { loading: loadCars, data: allCars, fetchData: getCars } = useFetch('cars');
    const { loading: loadRandomCars, data: allRandomCars, fetchData: getRandomCars } = useFetch('cars/random-cars');
    const { loading: loadRelatedCars, data: allRelatedCars, fetchData: getRelatedCars } = useFetch('car/related');

    const { loading: loadBrands, data: allBrands, fetchData: getBrands } = useFetch('brands');
    const { loading: loadEngine, data: allEngine, fetchData: getEngine } = useFetch('engines');
    const { loading: loadCarType, data: allCarType, fetchData: getCarType } = useFetch('types');


    function getCarDetails(carId) {
        setCarData({ images: [placeholder1] });
        setLoading(true);
        FetchApi.get(`/cars/${ carId }`)
            .then((res) => {
                if (res.status === false) {
                    setLoading(false);
                } else {
                    setLoading(false);
                    setCarData(res.data);
                }
            })
            .catch((error) => {
                setLoading(false);

            });
    }

    function searchFn(e,
        formField
    ) {
        e.preventDefault();
        const { type, year, brand, engine } = formField;
        const apiUrl = `type=${ type || '' }&year=${ year || '' }&brand=${ brand || '' }&engine=${ engine || '' }`;
        if (
            type || year || brand || engine
        ) {
            getRelatedCars();
            setSearching(true);
            Api.get(`cars/search?${ apiUrl }`)
                .then((res) => {
                    if (res.status === false) {
                        setSearching(false);
                    } else {
                        setSearching(false);
                        setResult(res.data.data);
                        navigate('/search/result');
                    }
                })
                .catch((error) => {
                    setSearching(false);

                });
        } else {
            setSearching(false);
            toast.warn("Empty Fields!");
        }
    }

    function filterFn(e,
        formField
    ) {
        e.preventDefault();
        const { isRate: { rate: price }, isBrand: { name: brand } } = formField;
        const apiUrl = `amount=${ price || '' }&brand=${ brand || '' }`;
        if (
            price || brand
        ) {
            getRelatedCars();
            setFiltering(true);
            Api.get(`cars/filter?${ apiUrl }`)
                .then((res) => {
                    if (res.status === false) {
                        setFiltering(false);
                    } else {
                        setFiltering(false);
                        setResult(res.data.data);
                        navigate('/search/result');
                    }
                })
                .catch((error) => {
                    setFiltering(false);

                });
        } else {
            setFiltering(false);
            toast.warn("Empty Fields!");
        }
    }




    return (
        <CarContext.Provider value={{
            cars: { loadCars, allCars, getCars },
            randomCars: { loadRandomCars, allRandomCars, getRandomCars },
            relatedCars: { loadRelatedCars, allRelatedCars, getRelatedCars },
            carInfo: { getCarDetails, carData, loading },

            brands: { loadBrands, allBrands, getBrands },
            engine: { loadEngine, allEngine, getEngine },
            carType: { loadCarType, allCarType, getCarType },

            search: { searching, searchFn, result },
            filter: { filtering, filterFn, result },
        }}>
            {children}
        </CarContext.Provider>
    );
};

// make sure use
export const useCarContext = () => {
    return useContext(CarContext);
};
