import styled from "styled-components";
import { visionImg } from "../../assets";


const Vision = () => {

      // we priotize our customers and partners interest to 

      return (
            <StyledVision>
                  <div className="container d-flex justify-content-center">
                        <div className="row justify-content-between align-items-center light-bg br-md shadow container py-5">
                              <div className="col-md-5">
                                    <img src={visionImg} alt="Car" width='100%' />
                              </div>
                              <div className="col-md-6">
                                    <h1>
                                          Our Vision
                                    </h1>
                                    <p className="mt-5">
                                          To be the leading provider of quality cars for sale and on hire purchase, ensuring customer satisfaction, financial flexibility, and fostering a culture of trust and reliability.
                                    </p>
                              </div>
                        </div>
                  </div>
            </StyledVision>
      );
};

export default Vision;

const StyledVision = styled.section`
      padding: 4rem 0;
      background-color: var(--light-bold-color)


      
`;