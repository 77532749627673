import { Link, NavLink, useNavigate } from "react-router-dom";
import { StyledHomeNav } from "./HomeNav.styled";
import { ICON_DASHBOARD, ICON_LIST, ICON_LOGOUT, ICON_USER } from "../utils/icons";
import { logo } from "../assets";
import { navLink } from "../utils/data";
import { Button } from "../styles";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import { useLocalStorage } from "../utils/useLocalStorage";
import { toast } from "react-toastify";
import { useUserContext } from "../context/user_context";
import { useState } from "react";
import { useEffect } from "react";
import FetchApi from "../api/getAxios";

const HomeNavbar = () => {

      // const { accessToken } = useUserContext();
      const [accessToken, setaccessToken] = useLocalStorage('accessToken');
      const [userdata, setuserdata] = useLocalStorage('userdata');
      const [userData, setuserData] = useState('');
      const navigate = useNavigate();

      const {
            // userData
      } = useUserContext();


      function logout() {
            toast.success('Logout successful! Redirecting...');
            setTimeout(() => {
                  setaccessToken(null);
                  setuserdata(null);
            }, 1800);
            setTimeout(() => {
                  navigate("/");
            }, 2000);
      }

      function getUserData() {
            if (accessToken) {
                  FetchApi.get('users/auth/profile', {
                        headers: {
                              'Authorization': `Bearer ${ accessToken }`
                        }
                  })
                        .then((res) => {
                              setuserData(res?.data.userInformation);
                              localStorage.setItem('userdata', JSON.stringify(res?.data.userInformation));
                        })
                        .catch((error) => {
                        });
            }
      }
      useEffect(() => {
            getUserData();
      }, []);

      return (
            <StyledHomeNav className="navbar fixed-top shadow-none navbar-expand-lg light-bg border border-bottom" id="navbar">
                  <div className="container">
                        <Link to="/" className="navbar-brand mt-2 mb-3 sec-text fit-content">
                              <img src={logo} width="40%" alt="Reni" loading="lazy" />
                        </Link>
                        <div className="d-flex mb-2">
                              <a
                                    className="navbar-toggler"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                              >
                                    <span className="navbar-toggler-icon toggler">
                                          <span className="h3 m-0 sec-text">
                                                {ICON_LIST}
                                          </span>
                                    </span>
                              </a>
                        </div>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                              <ul className="navbar-nav ms-auto text-center">
                                    {navLink?.map(({ title, to }, i) => {
                                          return (
                                                <li className="nav-item" key={i}>
                                                      <NavLink to={to} key={i}>
                                                            {title}
                                                      </NavLink>
                                                </li>
                                          );
                                    })}
                              </ul>
                              <ul className="navbar-nav ms-auto text-center">
                                    {(accessToken === null || accessToken === undefined) ? (
                                          <li className="mb-2">
                                                <Link to="/user/login">
                                                      <Button className=" btn">
                                                            Login
                                                      </Button>
                                                </Link>
                                                <Link to="/user/register">
                                                      <Button sec className=" btn">
                                                            Get Started
                                                      </Button>
                                                </Link>
                                          </li>
                                    ) : (
                                          <>

                                                <MDBDropdown className="pt-2 pt-lg-0">
                                                      <MDBDropdownToggle tag='a' className='pointer fw-bold m-0 dark-text'>
                                                            {(userData?.firstName || userdata?.firstName) || 'J Doe '}{' '}
                                                            {ICON_USER}
                                                      </MDBDropdownToggle>
                                                      <MDBDropdownMenu
                                                            className="text-center"
                                                      >
                                                            <MDBDropdownItem className="text-center">
                                                                  <Link to='/user/dashboard' className="pry-text dropdown-item">
                                                                        {ICON_DASHBOARD} Dashboard
                                                                  </Link>
                                                            </MDBDropdownItem>
                                                            <hr className="m-0 p-0" />
                                                            <MDBDropdownItem className="text-center pointer py-2" onClick={() => logout()}>
                                                                  <span className="text-danger">
                                                                        {ICON_LOGOUT} Logout
                                                                  </span>
                                                            </MDBDropdownItem>
                                                      </MDBDropdownMenu>
                                                </MDBDropdown>
                                          </>
                                    )}
                              </ul >
                        </div >
                  </div >
            </StyledHomeNav >

      );
};

export default HomeNavbar;
