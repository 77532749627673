import styled from "styled-components";
import AdminWrapper from "../../layout/AdminWrapper";
import DashboardSales from "../../components/admin/dashboard/DashboardSales";
import DashboardHeader from "../../components/admin/dashboard/DashboardHeader";
import AllUsers from "../../components/admin/dashboard/AllUsers";

const AdminDashboard = () => {
      return (
            <AdminWrapper>
                  <StyledAdminDashboard>
                        <DashboardHeader />
                        <AllUsers />
                        <DashboardSales />
                  </StyledAdminDashboard>
            </AdminWrapper>
      );
};

export default AdminDashboard;

const StyledAdminDashboard = styled.main`
      padding-bottom: 2rem;
`;