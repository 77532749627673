import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../../styles";
import { success } from "../../../assets";




const SuccessApplication = (props) => {

      const params = useParams();
      const { id } = params;

      return (
            <>
                  <Modal
                        show={props.show}
                        onHide={props.onHide}
                        keyboard={false}
                        centered
                        size="md"
                  >
                        <Modal.Body>
                              <div className="my-5 text-center">
                                    <div className="">
                                          <img src={success} alt="Business man " width='40%' />
                                    </div>
                                    <div className="my-5 container">
                                          <h5>
                                                Successful
                                          </h5>
                                          <p>
                                                You will contacted by an Admin soon.
                                          </p>
                                    </div>
                                    <div className="text-center">
                                          <Link to={`/cars`}>
                                                <Button pry className="btn"
                                                >
                                                      Close
                                                </Button>
                                          </Link>
                                    </div>

                              </div>
                        </Modal.Body>
                  </Modal>

            </>
      );
};

export default SuccessApplication;