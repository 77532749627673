import styled from "styled-components";
import Glide from "react-glidejs";

import { testimonials } from "../../utils/data";
import { useRef } from "react";
import { StyledGlide } from "../../styles";

const Testimonials = () => {

      const gliderRef = useRef(null);

      return (
            <StyledTestimonials>
                  <div className="container py-5">
                        <h4>
                              TESTIMONIALS
                        </h4>

                        <StyledGlide>
                              <Glide
                                    ref={gliderRef}
                                    startAt={0}
                                    perView={3}
                                    type="carousel"
                                    gap={20}

                                    animationDuration={1000}
                                    animationTimingFunc="ease"
                                    slideClassName="slider__frame"
                                    breakpoints={{
                                          1000: {
                                                perView: 2,
                                          },
                                          768: {
                                                perView: 1.2,
                                          },
                                    }}

                                    throttle={0}
                                    customSlideAnimation={{
                                          timeout: 500,
                                          classNames: 'fade',
                                    }}
                                    focusAt="center"
                              >
                                    {
                                          testimonials.map(({ img, name, comment }, i) =>
                                                <div className="col-md-12 mt-4 h-100" key={i}>
                                                      <div className="d-flex light-bg card-bg container py-4 br-sm shadow">
                                                            <div className="col-3">
                                                                  <img src={img} alt="Avatar" width="50%" />
                                                            </div>
                                                            <div className="col-9">
                                                                  <h5 className="">
                                                                        {name}
                                                                  </h5>
                                                                  <p className="">
                                                                        {comment}
                                                                  </p>
                                                            </div>
                                                      </div>
                                                </div>
                                          )}
                              </Glide>

                        </StyledGlide>

                        <div className="row">


                        </div>
                  </div>
            </StyledTestimonials>
      );
};

export default Testimonials;

const StyledTestimonials = styled.section`
      padding: 4rem 0;
      background-color: var(--pry-light-color);


      .card-bg {
      min-height:23rem !important;
      }
`;