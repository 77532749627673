import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
// import html2canvas from 'html2canvas';
// import { components } from 'react-select';


// Copy Item to Clipboard
export const CopyItem = ({ children, value }) => {

    return (
        <>
            <CopyToClipboard text={value}  >
                {children}
            </CopyToClipboard>
        </>
    );
};

// Remove Data from Local Storage

export function removeData(title) {
    localStorage.removeItem(title);
}

// To add comma to thousands
export const th = (e) => {
    var thNum = new Intl.NumberFormat('en-US');
    return thNum.format(e);
};


// export async function shareImage(divId, title) {
//     const div = document.getElementById(divId);

//     const url = html2canvas(div).then(canvas => {
//         const imageUrl = canvas.toDataURL();
//         return imageUrl;
//     });
//     const shareData = {
//         title, text: title, url
//     };

//     try {
//         await navigator.share(shareData);
//         toast.success('shared successfully');
//     } catch (err) {
//         console.error();
//     }
// }
// Share div as Image

// export function saveImage(divId) {
//     const div = document.getElementById(divId);
//     html2canvas(div).then(canvas => {
//         const link = document.createElement("a");
//         link.download = `reni_qr_${ Date.now() }.png`;
//         link.href = canvas.toDataURL();
//         link.click();
//     });
// }


export async function shareItem({ title, text, url }) {
    const shareData = {
        title, text, url
    };
    try {
        await navigator.share(shareData);
        toast.success('shared successfully');
    } catch (err) {
        console.error();
    }
}
// Share Item


// React Select add Image
// export const customOption = (props) => (
//     <components.Option {...props}>
//         <img
//             src={props.data.image}
//             alt={''}
//             style={{ marginRight: '10px', width: '20px', height: '20px', borderRadius: '50%' }}
//         />
//         {props.label}
//     </components.Option>
// );
// React Select add Image

// Format Date
export function shortDate() {
    const today = new Date();
    const date = new Intl.DateTimeFormat('en-us', { dateStyle: 'long', timeStyle: 'short' });
    return date.format(today);
}
// Format Date


// Select Image for Upload
export function convertPhoto(e, setformField) {
    // check max. file size is not exceeded
    // size is in bytes
    if (e.target.files[0]?.size > 1000000) {
        toast.warn("File too large");
        return;
    }
    var reader = new FileReader();

    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
        setformField(form => ({ ...form, photo: reader.result }));
    };
    reader.onerror = error => {
        console.log("Error: ", error);
    };
};
// Select Image for Upload


// generate random token
function generateRandomToken() {
    const wordPortion = Math.random().toString(36).substring(2, 9); // Generates a random 7-character word
    const randomNumber = Math.floor(Math.random() * 1000); // Generates a random number between 0 and 999

    return wordPortion + randomNumber;
}
export const randomToken = generateRandomToken();

// generate random token

// get random data
export function randomProp(arr, num) {
    const x = [...arr].sort(() => 0.5 - Math.random());
    return x.slice(0, num);
}