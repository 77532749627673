import styled from "styled-components";
import { useCarContext } from "../../context/car_context";
import AppLoader from "../../utils/AppLoader";
import CarPagination from "./CarPaginations";


const AllCars = () => {

      const {
            randomCars: { loadRandomCars, allRandomCars },
      } = useCarContext();

      return (

            <StyledAllCars>
                  <AppLoader
                        load={loadRandomCars}
                  />
                  <h5 className="">
                        Cars for Sale in Nigeria
                  </h5>

                  <div className="" id="topPage"></div>
                 
                  <CarPagination cars={allRandomCars} />


            </StyledAllCars>

      );
};

export default AllCars;

const StyledAllCars = styled.main`
      padding: 2rem 0;

`;