import styled from "styled-components";
import { brands, rates } from "../../utils/data";
import { Button, Form } from "../../styles";
import { car3, car5 } from "../../assets";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import { useCarContext } from "../../context/car_context";
import { useEffect } from "react";


const SearchHeader = () => {
      const [isRate, setisRate] = useState({
            rate: '', name: 'None'
      });
      const [isBrand, setisBrand] = useState({ name: '', img: '' });


      const {
            brands: { loadBrands, allBrands, getBrands },
            filter: { filtering, filterFn, },
      } = useCarContext();


      function editRate(e) {
            let rateIndex = rates?.findIndex(x => x.rate === e);
            let newRate = rates[rateIndex];
            setisRate(newRate);
      }
      function editBrand(e) {
            let brandIndex = brands?.findIndex(x => x.name === e);
            let newBrand = brands[brandIndex];
            setisBrand(newBrand);
      }

      const headerImg = [
            car3, car5
      ];

      useEffect(() => {
            getBrands();
      }, []);


      const formField = { isRate, isBrand };

      return (
            <StyledSearchHeader >
                  <div className="container">
                        <div className="row align-items-center">
                              <div className="col-md-5">
                                    <Carousel
                                          indicators={false}
                                          controls={false}
                                          interval={1000}
                                    >
                                          {headerImg.map((image, idx) => (
                                                <Carousel.Item key={idx}>
                                                      <div className="main-image">
                                                            <img
                                                                  className="d-block w-100"
                                                                  src={image}
                                                                  alt={`Slide ${ idx }`}
                                                            />
                                                      </div>
                                                </Carousel.Item>
                                          ))}
                                    </Carousel>

                              </div>
                              <div className="col-md-7">

                                    <Form onSubmit={(e) => filterFn(e, formField)}>
                                          <div className="shadow light-bg br-md container py-5">
                                                <h6 className="sec light-text rate-title fit-content mb-4">
                                                      BY PRICE
                                                </h6>

                                                <div className="row">
                                                      {rates?.map(({ rate, name }, i) =>
                                                            <label className="custom-radio col " key={name} htmlFor={name}>
                                                                  <input
                                                                        type="radio"
                                                                        className="rate-radio"
                                                                        name={name}
                                                                        id={name}
                                                                        value={rate}
                                                                        onChange={e => editRate(e.target.value)}
                                                                        checked={name == isRate?.name ? true : false}
                                                                  />

                                                                  {name == isRate?.name ?
                                                                        <div className="rates mb-3 h5 fw-bold p-2 py-3 pry-text">
                                                                              {name}
                                                                        </div>
                                                                        :
                                                                        <>
                                                                              <div className="rates mb-3 h5 fw-bold p-2 py-3">
                                                                                    {name}
                                                                              </div>
                                                                        </>
                                                                  }
                                                            </label>
                                                      )}
                                                </div>

                                                <h6 className="sec light-text rate-title fit-content mb-4 mt-5">
                                                      BY BRAND
                                                </h6>
                                                <div className="row justify-content-between">
                                                      {allBrands?.map(({ image: img, name }, i) =>
                                                            <label className="custom-radio col-4 col-md-3 col-lg-2 text-center " key={name} htmlFor={name}>
                                                                  <input
                                                                        type="radio"
                                                                        className="brand-radio"
                                                                        name={name}
                                                                        id={name}
                                                                        value={name}
                                                                        onChange={e => editBrand(e.target.value)}
                                                                        checked={name == isBrand?.name ? true : false}
                                                                  />
                                                                  <div className="search-brand br-sm">
                                                                        <img src={img} alt="Brand" width='100%' className="px-1" />
                                                                  </div>
                                                            </label>
                                                      )}
                                                </div>

                                                <div className="mt-5">
                                                      <Button className="btn w-100 fw-bold" sec type="submit"
                                                            disabled={filtering}
                                                      >
                                                            {filtering ? 'loading...' : "SEARCH"}
                                                      </Button>
                                                </div>
                                          </div>

                                    </Form>
                              </div>
                        </div>
                  </div>


            </StyledSearchHeader>
      );
};

export default SearchHeader;

const StyledSearchHeader = styled.main`

background-color: var(--pry-light-color);
padding: 3rem 0;
margin-bottom: 3rem;

.main-image{
      height:400px;
      border-radius: 10px;
      width:auto;
      
      img {
            width:100%;
            height:100%;
            margin:auto;
            object-fit:contain;
            border-radius: 10px;
      }
} 

.rate-title {
      margin: 0;
      padding: 5px 10px 0px;
      border-radius: 5px;
      text-align: center;
}
.rates {
      border: 1px solid var(--grey-color);
      margin: 0;
      padding: 5px 10px 0px;
      border-radius: 5px;
      text-align: center;
      white-space: nowrap;
}

img {
      
      @media screen and (max-width: 580px) {
            width: 100%;
      }
}
.custom-radio {

      input.rate-radio {
       display: none ;
     }
     
     input:checked + .rates {
           border: 1px solid var(--pry-color) !important;
      }

      input + .rates {
           border: 1px solid transparent !important;
      }

      input.brand-radio {
       display: none ;
     }
  
     input + .search-brand {
      border: 1px solid transparent !important;
     }
     input:checked + .search-brand {
      border: 1px solid var(--pry-color) !important;
     }
}
`;