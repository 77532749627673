import { StyledAddCar } from "./AddCar";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Label, Select, StyledImgUpload, Textarea } from "../../../styles";
import { useState } from "react";
import { convertPhoto, randomToken } from "../../../utils/helpers";
import { ICON_X } from "../../../utils/icons";
import { placeholder } from "../../../assets";
import { toast } from "react-toastify";
import BackButton from "../../BackButton";
import { carFeatures, naira, years } from "../../../utils/data";
import { useEffect } from "react";
import AdminApi from "../../../api/adminApi";
import { useAdminContext } from "../../../context/admin_context";
import MiniLoader from "../../../utils/MIniLoader";
import AdminWrapper from "../../../layout/AdminWrapper";


const EditCar = () => {

      const params = useParams();
      const { id } = params;

      const {
            carInfo: { getCarDetails, carData, loading }
      } = useAdminContext();

      const {
            images: avatar, vin, name, amount, source, description, engineType, brand, mileage, transmission, fuelType, gearType, year, location, interiorColor, exteriorColor, vehicleId,

            features, engine, electricals, transmissionAndClutch, suspensionAndSteering, testDrive, interior, exterior, airConditionSystem
      } = carData;


      const [image, setImage] = useState({ photo: '' });
      const [image1, setImage1] = useState({ photo: '' });
      const [image2, setImage2] = useState({ photo: '' });
      const [image3, setImage3] = useState({ photo: '' });
      const [image4, setImage4] = useState({ photo: '' });

      const [formField, setformField] = useState({
            // photo: '', vin, name, source, description, engineType, brand, mileage, transmission, fuelType, gearType, year, location, interiorColor, exteriorColor,
      });

      const [sending, setsending] = useState(false);
      const [selectedData, setSelectedData] = useState({
            // features, engine, electricals, transmissionAndClutch, suspensionAndSteering, testDrive, interior, exterior, airConditionSystem
      });


      useEffect(() => {
            getCarDetails(id);
      }, [id]);

      useEffect(() => {

            setImage({ photo: avatar[0] });
            setImage1({ photo: avatar[1] });
            setImage2({ photo: avatar[2] });
            setImage3({ photo: avatar[3] });
            setImage4({ photo: avatar[4] });

            setformField({
                  vin, name, amount, source, description, engineType, brand, mileage, transmission, fuelType, gearType, year, location, interiorColor, exteriorColor,
                  photo: '',
                  photo1: '',
                  photo2: '',
                  photo3: '',
                  photo4: '',
            });

            setSelectedData({ features, engine, electricals, transmissionAndClutch, suspensionAndSteering, testDrive, interior, exterior, airConditionSystem });
      }, [carData]);
      const {
            brands: { loadBrands, allBrands, getBrands },
            engine: { loadEngine, allEngine, getEngine },
            carType: { loadCarType, allCarType, getCarType }
      } = useAdminContext();


      // console.log(image);
      // console.log(carData);

      useEffect(() => {
            getEngine();
            getBrands();
            getCarType();
      }, []);

      function handleChange(e) {
            if (e.target.type === "file") {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field

      function submitFn(e) {
            e.preventDefault();

            if (
                  name
            ) {
                  setsending(true);
                  const data = new FormData();

                  data.append('vehicleId', 'randomToken');
                  data.append('name', formField?.name);
                  data.append('amount', formField?.amount);
                  data.append('source', formField?.source);
                  data.append('year', formField?.year);
                  data.append('engineType', formField?.engineType);
                  data.append('mileage', +formField?.mileage);
                  data.append('transmission', formField?.transmission);
                  data.append('fuelType', formField?.fuelType);
                  data.append('gearType', formField?.gearType);
                  data.append('interiorColor', formField?.interiorColor);
                  data.append('exteriorColor', formField?.exteriorColor);
                  data.append('features', JSON.stringify(selectedData?.features));
                  data.append('electricals', JSON.stringify(selectedData?.electricals));
                  data.append('transmissionAndClutch', JSON.stringify(selectedData?.transmissionAndClutch));
                  data.append('suspensionAndSteering', JSON.stringify(selectedData?.suspensionAndSteering));
                  data.append('testDrive', JSON.stringify(selectedData?.testDrive));
                  data.append('images', formField?.photo);
                  data.append('images', formField?.photo1);
                  data.append('images', formField?.photo2);
                  data.append('images', formField?.photo3);
                  data.append('images', formField?.photo4);

                  data.append('brand', formField?.brand);
                  data.append('location', formField?.location);
                  data.append('description', formField?.description);
                  data.append('vin', formField?.vin);
                  data.append('engine', JSON.stringify(selectedData?.engine));
                  data.append('airConditionSystem', JSON.stringify(selectedData?.airConditionSystem));
                  data.append('interior', JSON.stringify(selectedData?.interior));
                  data.append('exterior', JSON.stringify(selectedData?.exterior));


                  AdminApi.put(`/admin/cars/${ id }`, data, {
                        headers: {
                              "content-type": "multipart/form-data",
                        },
                  })
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    // navigate(`/admin/cars/${ res.data._id }`);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      console.log(formField);

      const handleCheckboxChange = (value, dataName) => {
            setSelectedData((prevSelectedData) => {
                  const updatedSelectedData = { ...prevSelectedData };

                  if (!updatedSelectedData[dataName]) {
                        updatedSelectedData[dataName] = [];
                  }

                  if (updatedSelectedData[dataName].includes(value)) {
                        updatedSelectedData[dataName] = updatedSelectedData[dataName].filter(
                              (item) => item !== value
                        );
                  } else {
                        updatedSelectedData[dataName] = [
                              ...updatedSelectedData[dataName],
                              value,
                        ];
                  }

                  return updatedSelectedData;
            });
      };


      return (
            <>
                  <AdminWrapper>
                        <StyledAddCar>
                              <div className="light-bg br-sm py-2 px-3">
                                    <h5 className="m-0">
                                          Edit Cars - ({name})
                                    </h5>
                              </div>

                              <div className="light-bg my-2 container py-4 br-sm">
                                    <BackButton />

                                    <Form onSubmit={(e) => submitFn(e)} className="mt-4">
                                          <div className="row">
                                                <div className="">
                                                      <div className="col-md-6">
                                                            <Label htmlFor="photo">
                                                                  Cover Photo:
                                                            </Label>
                                                            <div className="col d-flex mx-auto justify-content-center mt-2 mb-4">
                                                                  <StyledImgUpload>
                                                                        <input
                                                                              accept="image/*"
                                                                              type="file"
                                                                              id="photo"
                                                                              name='photo'
                                                                              onChange={(e) => (
                                                                                    convertPhoto(e, setImage),
                                                                                    handleChange(e)
                                                                              )}
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="w-100" >
                                                                              {image?.photo ?
                                                                                    <div className="cover-container">
                                                                                          <img src={image?.photo}
                                                                                                className="cover"
                                                                                                alt="cover " />
                                                                                    </div>
                                                                                    :
                                                                                    <img src={placeholder} alt="cover " />
                                                                              }
                                                                              {image?.photo &&
                                                                                    <div className="cancel"
                                                                                          onClick={() => (
                                                                                                setImage(''),
                                                                                                setformField(prev => ({ ...prev, photo: '' }))
                                                                                          )}
                                                                                    >
                                                                                          {ICON_X}
                                                                                    </div>
                                                                              }
                                                                        </div>
                                                                  </StyledImgUpload>

                                                            </div>
                                                            <div className="row mb-4">
                                                                  <div className="col-6 mb-3">
                                                                        <StyledImgUpload>
                                                                              <input
                                                                                    accept="image/*"
                                                                                    type="file"
                                                                                    id="photo"
                                                                                    name='photo1'
                                                                                    onChange={(e) => (
                                                                                          convertPhoto(e, setImage1),
                                                                                          handleChange(e))
                                                                                    }
                                                                                    className="w-100 h-100"
                                                                              />
                                                                              <div className="w-100" >
                                                                                    {image1?.photo ?
                                                                                          <div className="cover-container">
                                                                                                <img src={image1?.photo}
                                                                                                      className="cover"
                                                                                                      alt="cover " />
                                                                                          </div>
                                                                                          :
                                                                                          <img src={placeholder} alt="cover " />
                                                                                    }
                                                                                    {image1?.photo &&
                                                                                          <div className="cancel"
                                                                                                onClick={() => (setImage1(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                          >
                                                                                                {ICON_X}
                                                                                          </div>
                                                                                    }
                                                                              </div>
                                                                        </StyledImgUpload>
                                                                  </div>
                                                                  <div className="col-6 mb-3">
                                                                        <StyledImgUpload>
                                                                              <input
                                                                                    accept="image/*"
                                                                                    type="file"
                                                                                    id="photo"
                                                                                    name='photo2'
                                                                                    onChange={(e) => (
                                                                                          convertPhoto(e, setImage2),
                                                                                          handleChange(e))
                                                                                    }
                                                                                    className="w-100 h-100"
                                                                              />
                                                                              <div className="w-100" >
                                                                                    {image2?.photo ?
                                                                                          <div className="cover-container">
                                                                                                <img src={image2?.photo}
                                                                                                      className="cover"
                                                                                                      alt="cover " />
                                                                                          </div>
                                                                                          :
                                                                                          <img src={placeholder} alt="cover " />
                                                                                    }
                                                                                    {image2?.photo &&
                                                                                          <div className="cancel"
                                                                                                onClick={() => (setImage2(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                          >
                                                                                                {ICON_X}
                                                                                          </div>
                                                                                    }
                                                                              </div>
                                                                        </StyledImgUpload>
                                                                  </div>
                                                                  <div className="col-6 mb-3">
                                                                        <StyledImgUpload>
                                                                              <input
                                                                                    accept="image/*"
                                                                                    type="file"
                                                                                    id="photo"
                                                                                    name='photo3'
                                                                                    onChange={(e) => (
                                                                                          convertPhoto(e, setImage3),
                                                                                          handleChange(e))
                                                                                    }
                                                                                    className="w-100 h-100"
                                                                              />
                                                                              <div className="w-100" >
                                                                                    {image3?.photo ?
                                                                                          <div className="cover-container">
                                                                                                <img src={image3?.photo}
                                                                                                      className="cover"
                                                                                                      alt="cover " />
                                                                                          </div>
                                                                                          :
                                                                                          <img src={placeholder} alt="cover " />
                                                                                    }
                                                                                    {image3?.photo &&
                                                                                          <div className="cancel"
                                                                                                onClick={() => (setImage3(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                          >
                                                                                                {ICON_X}
                                                                                          </div>
                                                                                    }
                                                                              </div>
                                                                        </StyledImgUpload>
                                                                  </div>
                                                                  <div className="col-6 mb-3">
                                                                        <StyledImgUpload>
                                                                              <input
                                                                                    accept="image/*"
                                                                                    type="file"
                                                                                    id="photo"
                                                                                    name='photo4'
                                                                                    onChange={(e) => (
                                                                                          convertPhoto(e, setImage4),
                                                                                          handleChange(e))
                                                                                    }
                                                                                    className="w-100 h-100"
                                                                              />
                                                                              <div className="w-100" >
                                                                                    {image4?.photo ?
                                                                                          <div className="cover-container">
                                                                                                <img src={image4?.photo}
                                                                                                      className="cover"
                                                                                                      alt="cover " />
                                                                                          </div>
                                                                                          :
                                                                                          <img src={placeholder} alt="cover " />
                                                                                    }
                                                                                    {image4?.photo &&
                                                                                          <div className="cancel"
                                                                                                onClick={() => (setImage4(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                          >
                                                                                                {ICON_X}
                                                                                          </div>
                                                                                    }
                                                                              </div>
                                                                        </StyledImgUpload>
                                                                  </div>
                                                            </div>

                                                      </div>
                                                      <Label htmlFor="name">
                                                            Car Name:
                                                      </Label>
                                                      <Input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            value={formField.name}
                                                            required
                                                      />



                                                </div>

                                                <div className="col-md-6">
                                                      <Label htmlFor="description">
                                                            Description:
                                                      </Label><br />

                                                      <Textarea
                                                            type="text"
                                                            name="description"
                                                            id="description"
                                                            placeholder="Description"
                                                            onChange={handleChange}
                                                            value={formField.description}
                                                            required
                                                            rows='3'
                                                      />
                                                      <Label htmlFor="amount">
                                                            Amount ({naira}):
                                                      </Label>
                                                      <Input
                                                            type="number"
                                                            name="amount"
                                                            id="amount"
                                                            onChange={handleChange}
                                                            value={formField.amount}
                                                            required
                                                      />
                                                </div>

                                                <div className="col-md-6">
                                                      <div className="d-flex">
                                                            <Label htmlFor="brand">
                                                                  Brand:
                                                            </Label>
                                                            <MiniLoader
                                                                  load={loadBrands}
                                                            />

                                                      </div>
                                                      <Select
                                                            name="brand"
                                                            id="brand"
                                                            onChange={handleChange}
                                                            value={formField.brand}
                                                            required
                                                      >
                                                            <option value="">Select Brand</option>
                                                            {allBrands.map(({ name, _id: id }, i) =>
                                                                  <option value={id} key={i}>{name}</option>
                                                            )}
                                                      </Select>
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="source">
                                                            Type:
                                                      </Label>
                                                      <Select
                                                            name="source"
                                                            id="source"
                                                            onChange={handleChange}
                                                            value={formField.source}
                                                            required
                                                      >
                                                            <option value="">Select Type</option>
                                                            {allCarType.map(({ name }, i) =>
                                                                  <option value={name} key={i}>{name}</option>
                                                            )}
                                                      </Select>
                                                </div>
                                                {/* <div className="col-md-6">
                                                      <Label htmlFor="model">
                                                            Model:
                                                      </Label>
                                                      <Input
                                                            type="text"
                                                            name="model"
                                                            id="model"
                                                            onChange={handleChange}
                                                            value={formField.model}
                                                            required
                                                      />
                                                </div> */}
                                                <div className="col-md-6">
                                                      <Label htmlFor="year">
                                                            Year:
                                                      </Label>
                                                      <Select
                                                            name="year"
                                                            id="year"
                                                            onChange={handleChange}
                                                            value={formField.year}
                                                            required
                                                      >
                                                            <option value="">Select Year</option>
                                                            {years.map((year, i) =>
                                                                  <option value={year} key={i}>{year}</option>
                                                            )}
                                                      </Select>
                                                </div>
                                                {/* <div className="col-md-6">
                                                <Label htmlFor="source">
                                                      Source:
                                                </Label>
                                                <Select
                                                      name="source"
                                                      id="source"
                                                      onChange={handleChange}
                                                      value={formField.source}
                                                      required
                                                >
                                                      <option value="">Select Source</option>
                                                      <option value="Dealer A">Dealer A</option>
                                                      <option value="Dealer B">Dealer B</option>
                                                      <option value="Dealer C">Dealer C</option>
                                                </Select>
                                          </div> */}
                                                <div className="col-md-6">
                                                      <Label htmlFor="interiorColor">
                                                            Interior Color:
                                                      </Label>
                                                      <Input
                                                            type="text"
                                                            name="interiorColor"
                                                            id="interiorColor"
                                                            onChange={handleChange}
                                                            value={formField.interiorColor}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="exteriorColor">
                                                            Exterior Color:
                                                      </Label>
                                                      <Input
                                                            type="text"
                                                            name="exteriorColor"
                                                            id="exteriorColor"
                                                            onChange={handleChange}
                                                            value={formField.exteriorColor}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="vin">
                                                            VIN:
                                                      </Label>
                                                      <Input
                                                            type="text"
                                                            name="vin"
                                                            id="vin"
                                                            onChange={handleChange}
                                                            value={formField.vin}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="mileage">
                                                            mileage:
                                                      </Label>
                                                      <Input
                                                            type="number"
                                                            name="mileage"
                                                            id="mileage"
                                                            onChange={handleChange}
                                                            value={formField.mileage}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="transmission">
                                                            Transmission:
                                                      </Label>
                                                      <Select
                                                            name="transmission"
                                                            id="transmission"
                                                            onChange={handleChange}
                                                            value={formField.transmission}
                                                            required
                                                      >
                                                            <option value="">Select Transmission</option>
                                                            <option value="Automatic">Automatic</option>
                                                            <option value="Manual">Manual</option>
                                                            <option value="Both">Both</option>
                                                      </Select>
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="fuelType">
                                                            Fuel Type:
                                                      </Label>
                                                      <Select
                                                            name="fuelType"
                                                            id="fuelType"
                                                            onChange={handleChange}
                                                            value={formField.fuelType}
                                                            required
                                                      >
                                                            <option value="">Select Fuel Type</option>
                                                            <option value="Gasoline">Gasoline</option>
                                                            <option value="Diesel">Diesel</option>
                                                            <option value="Electric">Electric</option>
                                                      </Select>
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="engineType">
                                                            Engine Type:
                                                      </Label>
                                                      <Select
                                                            name="engineType"
                                                            id="engineType"
                                                            onChange={handleChange}
                                                            value={formField.engineType}
                                                            required
                                                      >
                                                            <option value="">Select Engine Type</option>
                                                            {allEngine.map(({ name }, i) =>
                                                                  <option value={name} key={i}>{name}</option>
                                                            )}
                                                      </Select>
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="gearType">
                                                            Gear Type:
                                                      </Label>
                                                      <Select
                                                            name="gearType"
                                                            id="gearType"
                                                            onChange={handleChange}
                                                            value={formField.gearType}
                                                            required
                                                      >
                                                            <option value="">Select Gear Type</option>
                                                            <option value="Front Wheel">Front Wheel</option>
                                                            {/* {allEngine.map(({ name }, i) =>
                                                      <option value={name} key={i}>{name}</option>
                                                )} */}
                                                      </Select>
                                                </div>
                                                <div className="col-md-6">
                                                      <Label htmlFor="location">
                                                            Location:
                                                      </Label>
                                                      <Input
                                                            type="text"
                                                            name="location"
                                                            id="location"
                                                            onChange={handleChange}
                                                            value={formField.location}
                                                            required
                                                      />
                                                </div>
                                          </div>


                                          <div className="row">
                                                {carFeatures.map((dataSet, index) => (
                                                      <div key={index}>
                                                            <Label htmlFor="" className="fw-bold h5 text-capitaliz my-3">
                                                                  {dataSet.title}:
                                                            </Label>
                                                            {dataSet.labels.map((label) => (
                                                                  <label key={label} className="custom-checkbox-label">
                                                                        <input
                                                                              type="checkbox"
                                                                              value={label}
                                                                              checked={selectedData[dataSet.name]?.includes(label) || false}
                                                                              onChange={() => handleCheckboxChange(label, dataSet.name)}
                                                                              className="custom-checkbox"

                                                                        />
                                                                        {label}
                                                                  </label>
                                                            ))}
                                                      </div>
                                                ))}

                                          </div>

                                          <div className="col-12 text-center">
                                                <Button
                                                      sec
                                                      className="btn"
                                                      type="submit"
                                                      disabled={sending}
                                                >
                                                      {sending ? 'loading...' : 'Proceed'}
                                                </Button>
                                          </div>


                                    </Form>


                              </div>
                        </StyledAddCar>
                  </AdminWrapper>

            </>
      );
};

export default EditCar;