import { Input, Label, Select } from "../../../styles";


const LoanFormAboutMe = ({ handleChange, formField }) => {
      return (
            <>
                  <div className="row">

                        <div className="col-md-6 text-start">
                              <Label htmlFor="firstName" className="">
                                    First Name:
                              </Label>
                              <Input
                                    type="text"
                                    id="firstName"
                                    name='firstName'
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    value={formField.firstName}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="lastName" className="">
                                    Last Name:
                              </Label>
                              <Input
                                    type="text"
                                    id="lastName"
                                    name='lastName'
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    value={formField.lastName}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="dob" className="">
                                    Date of Birth:
                              </Label>
                              <Input
                                    type="date"
                                    id="dob"
                                    name='dob'
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    value={formField.dob}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="email" className="">
                                    Email Address:
                              </Label>
                              <Input
                                    type="email"
                                    id="email"
                                    name='email'
                                    placeholder="Enter e-mail address"
                                    onChange={handleChange}
                                    value={formField.email}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="phone" className="">
                                    Phone Number:
                              </Label>
                              <Input
                                    type="number"
                                    id="phone"
                                    name='phone'
                                    placeholder="08012345678"
                                    onChange={handleChange}
                                    value={formField.phone}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="nin" className="">
                                    NIN:
                              </Label>
                              <Input
                                    type="number"
                                    id="nin"
                                    name='nin'
                                    placeholder="12345678901"
                                    onChange={handleChange}
                                    value={formField.nin}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="bvn" className="">
                                    BVN:
                              </Label>
                              <Input
                                    type="number"
                                    id="bvn"
                                    name='bvn'
                                    placeholder="12345678901"
                                    onChange={handleChange}
                                    value={formField.bvn}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="gender">
                                    Gender:
                              </Label>
                              <Select
                                    name="gender"
                                    id="gender"
                                    onChange={handleChange}
                                    value={formField.gender}
                              // required
                              >
                                    <option value="">Select Gender</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                              </Select>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="inquiry" className="">
                                    How did you hear about us?
                              </Label>
                              <Input
                                    type="text"
                                    id="inquiry"
                                    name='inquiry'
                                    // placeholder="Inquiry"
                                    onChange={handleChange}
                                    value={formField.inquiry}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="nationality" className="">
                                    Nationality:
                              </Label>
                              <Input
                                    type="text"
                                    id="nationality"
                                    name='nationality'
                                    placeholder="Nationality"
                                    onChange={handleChange}
                                    value={formField.nationality}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="nationalStatus">
                                    Nationality Status:
                              </Label>
                              <Select
                                    name="nationalStatus"
                                    id="nationalStatus"
                                    onChange={handleChange}
                                    value={formField.nationalStatus}
                              // required
                              >
                                    <option value="">Select Status</option>
                                    <option value="Citizen">Citizen</option>
                                    <option value="Resident"> Resident</option>
                                    <option value="Non Resident">Non Resident</option>
                              </Select>
                        </div>
                  </div>




            </>
      );
};

export default LoanFormAboutMe;