import { useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../../styles";
import { adminNavLink } from "../../../utils/data";
import { removeData } from "../../../utils/helpers";
import { ICON_ARROW_DOWN_CIRCLE, ICON_ARROW_RIGHT, ICON_LOGOUT, ICON_SETTING } from "../../../utils/icons";

const NavLinks = () => {

  const navigate = useNavigate();

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); };
  // Modal

  function logout() {

    removeData('mainAccess');
    toast.success('Logout successful! Redirecting...');
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }


  const [showSubLink, setShowSubLink] = useState(false);

  function openSubLink(params) {
    setShowSubLink(!showSubLink);
  }

  return (
    <>
      <div className="ml-3">
        <h4 className="ms-4 mt-3">
          Admin
        </h4>
      </div>


      <div className="row align-content-between"
        style={{ height: '90%' }}
      >
        <div className="col-12">
          <ul className="navbar-nav mt-3">
            {adminNavLink.map(({ title, to, icon, sublink, openSub }, i) => {
              return (
                <li className="nav-item row" key={i}>
                  <NavLink to={to}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className=" w-100 link">
                        {icon}
                        {title}
                      </div>
                      {sublink &&
                        <span
                          style={{ zIndex: '20000' }}
                          className=""
                          onClick={() => openSubLink(openSubLink)}
                        >
                          {sublink?.length ?
                            (<>
                              {showSubLink ? ICON_ARROW_DOWN_CIRCLE : ICON_ARROW_RIGHT}
                            </>) : null
                          }
                        </span>
                      }
                    </div>
                  </NavLink>
                  {
                    showSubLink &&
                    <div className="sublink row">
                      {sublink?.map(({ title, to, icon }, i) => {
                        return (
                          <NavLink to={to} key={i}>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                {icon}
                                {title}
                              </div>

                            </div>
                          </NavLink>
                        );
                      })}
                    </div>
                  }
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-12 border-top">
          <ul className="navbar-nav mt-3">
            <li className="nav-item">
              <NavLink to='/'>
                {ICON_SETTING}
                Setting
              </NavLink>

            </li>
            <li className="nav-item">
              <a className="text-danger pointer"
                onClick={() => handleShow()}
              >
                {ICON_LOGOUT}
                Logout
              </a>
            </li>
          </ul>

        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        size="sm"
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body >
          <div className=" text-center ">
            <div className="text-danger br-lg shadow fit-content mx-auto px-3 mb-3" style={{ fontSize: '3rem' }}>
              {ICON_LOGOUT}
            </div>
            <h5 className="my-5">
              Are you sure you want to logout?
            </h5>

            <div className="d-flex gap-2">
              <Button className="btn bg-danger light-text w-50"
                onClick={() => logout()}>
                Confirm
              </Button>
              <Button className="btn border border-danger text-danger w-50"
                onClick={() => handleClose()}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default NavLinks;
