import { ClipLoader } from "react-spinners";
import { useUserContext } from "../../context/user_context";
import { ICON_HEART, ICON_HEART_FILL } from "../../utils/icons";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";


const CarWishlist = ({ carId }) => {

      const navigate = useNavigate();
      const location = useLocation();

      const {
            wishlist: { addToWishlist, removeFromWishlist, adding, removing, loadWishlist, allWishlist, getWishlist },
            accessToken
      } = useUserContext();

      console.log(allWishlist);
      // console.log(carId);

      function checkWishList() {
            const wishlist = allWishlist.find((e) => e.carId === carId);
            console.log(wishlist);
            if (wishlist) {
                  return true;
            } else {
                  return false;
            }
      }

      function goToLogin() {
            navigate('/user/login');
            setTimeout(() => {
                  toast.warn('You have to be logged in...');
            }, 10);
      }

      return (
            <>
                  {accessToken ?
                        <>
                              {
                                    location.pathname === '/user/wishlist' ?
                                          <>
                                                <a className="fav pointer light-text"
                                                      onClick={() => removeFromWishlist(carId)}
                                                >
                                                      {removing ?
                                                            <ClipLoader
                                                                  size='15'
                                                                  color={'var(--light-color)'}
                                                            /> : ICON_HEART_FILL}
                                                </a>
                                          </>
                                          :
                                          <>
                                                {
                                                      checkWishList() ?
                                                            <a className="fav pointer light-text"
                                                                  onClick={() => removeFromWishlist(carId)}
                                                            >
                                                                  {removing ?
                                                                        <ClipLoader
                                                                              size='15'
                                                                              color={'var(--light-color)'}
                                                                        /> : ICON_HEART_FILL}
                                                            </a>
                                                            :
                                                            <a className="fav pointer light-text"
                                                                  onClick={() => addToWishlist(carId)}
                                                            >
                                                                  {adding ?
                                                                        <ClipLoader
                                                                              size='15'
                                                                              color={'var(--light-color)'}
                                                                        /> : ICON_HEART}
                                                            </a>
                                                }
                                          </>
                              }
                        </>
                        :
                        <>
                              <a className="fav pointer light-text"
                                    onClick={() => goToLogin()}
                              >
                                    {ICON_HEART}
                              </a>

                        </>

                  }


            </>
      );
};

export default CarWishlist;;