import { Audi, BMW, Chery, Daihatsu, Datsun, Honda, Hyundai, Mazda, Mercedes, Mitsubishi, Nissan, Subaru, Suzuki, Toyota, service1, service2, service3, service4, service5, service6, testimonial } from "../assets";
import { ICON_CAR, ICON_DASHBOARD, ICON_GIFT_FILL, ICON_PEOPLE_ARROW, ICON_PLUS_LG, ICON_USER } from "./icons";


// NAV LINKS
export const navLink = [
      { title: 'Home', to: '/' },
      // { title: 'Buy Cars', to: '/cars' },
      { title: 'Search Cars', to: '/search' },
      { title: 'About Us', to: '/about' },
      { title: 'Our Services', to: '/services' },
      { title: 'Contact Us', to: '/contact' },
];

export const userNavLink = [
      { title: "Dashboard", to: "/user/dashboard", icon: ICON_DASHBOARD },
      { title: "Wishlist", to: "/user/wishlist", icon: ICON_CAR },
      { title: "Cars Bought", to: "/user/purchase", icon: ICON_CAR },
      { title: "Loans", to: "/user/loans", icon: ICON_PEOPLE_ARROW },
      { title: "Buy Request", to: "/user/buy-request", icon: ICON_GIFT_FILL },

];

export const adminNavLink = [
      {
            title: "Dashboard", to: "/admin/dashboard", icon: ICON_DASHBOARD, openSub: '', sublink: []
      },
      {
            title: "Cars", to: "/admin/cars", icon: ICON_CAR, openSub: false, sublink: [
                  { title: "All Cars", to: "/admin/cars/all", icon: ICON_CAR, },
                  { title: "Add Cars", to: "/admin/cars/add", icon: ICON_PLUS_LG, }
            ]
      },
      {
            title: "Dealers", to: "/admin/dealers", icon: ICON_PEOPLE_ARROW, openSub: false, sublink: []
      },
      {
            title: "Agents", to: "/admin/agents", icon: ICON_USER, openSub: false, sublink: []
      },
      // {
      //       title: "Brand", to: "/admin/brand", icon: ICON_CAR, openSub: false, sublink: []
      // },
      // {
      //       title: "Sales/Loan", to: "/admin/sales", icon: ICON_PEOPLE_ARROW, openSub: false, sublink: []
      // },

];
// NAV LINKS


// homepage
export const whyAuto = [
      'Trust and Security', 'Fast and Easy', 'Stress Free Loan'
];

export const testimonials = [
      {
            img: testimonial,
            name: ' Edison Michael',
            comment: 'I am thrilled to share my experience with the Reusautobestsales site. As a first-time car buyer, I approached the process with some trepidation. However, from the moment I landed on the site, I felt at ease and well-supported throughout my journey.'
      },
      {
            img: testimonial,
            name: ' Abdullahi Raji.',
            comment: 'The user-friendly interface made it easy to navigate through various car options and filter the search according to my preferences. I was impressed by the vast selection of vehicles available, ensuring there was something to suit every budget and taste. '
      },
      {
            img: testimonial,
            name: ' Uche Peter.',
            comment: 'The site\'s layout was intuitive, allowing me to easily compare prices, specifications, and features of different cars side by side.It saved me countless hours that would have been spent traveling to multiple dealerships. '
      },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: 'What truly set this car dealer site apart was the exceptional customer service I received. Whenever I had a question, their responsive team was always there to assist me with patience and expertise. '
      // },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: 'Throughout the negotiation and purchasing phase, the Reusautobestsales site provided transparency and ensured that I had a clear understanding of all costs involved. The process was streamlined, and I felt reassured knowing that I was purchasing a car from a reputable dealership.'
      // },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: ' The detailed descriptions, high-resolution images, and even virtual tours gave me a comprehensive view of the car I was interested in, allowing me to make an informed decision without needing to physically visit the dealership.'
      // },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: 'They provided detailed information about the different financing options available, including the car loan program, which allowed me to make an informed decision regarding my purchase.'
      // },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: 'Upon visiting the physical dealership, I was warmly greeted by the friendly and knowledgeable staff who treated me with the utmost professionalism. The car was impeccably presented, and the entire purchase process was smooth and hassle-free.'
      // },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: 'My experience with the Resuautobestsales site was exceptional. They offer a comprehensive range of cars, provide top-notch customer service, and make the entire car buying process efficient and transparent. '
      // },
      // {
      //       img: testimonial,
      //       name: ' Edison M.',
      //       comment: 'I highly recommend their services to anyone looking to purchase a car hassle-free. Thank you, Car Loan, Car Dealer site, for making my car buying experience a breeze!  thanks to the efficiency of the car loan program offered by the car dealer site.'
      // },
];

export const values = [
      {
            title: 'Customer Satisfaction',
            content: 'We prioritize our clients\' needs, ensuring their satisfaction by providing exceptional service and delivering on our promises.'
      },
      {
            title: 'Integrity',
            content: 'We uphold the highest ethical standards, maintaining transparency, honesty, and fairness in all our interactions'
      },
      {
            title: 'Quality',
            content: 'We are committed to offering only high- quality vehicles and services, ensuring that our customers receive the best value for their investment.'
      },
      {
            title: 'Innovation',
            content: 'We embrace innovation and continuously seek new ways to improve our processes, services, and customer experience.'
      },
];

export const service = [
      {
            img: service1,
            title: 'Car Loan Financing',
            content: 'Reusautobestsales offers flexible financing options for individuals and corporate bodies seeking to purchase a car. We provide affordable payment plans tailored to suit our clients\' financial situations, enabling them to drive their desired vehicles without the burden of a lump- sum payment.',
            color: 'sec-light',
            reverse: false
      },
      {
            img: service2,
            title: 'Extensive Vehicle Inspection',
            content: 'We curate an extensive inventory of high-quality cars from various brands, ensuring that our clients have a wide range of options to choose from. Our selection includes both brand new and pre-owned vehicles, giving customers the freedom to find their perfect match.',
            color: 'pry-light',
            reverse: false
      },
      {
            img: service3,
            title: 'Thorough Inspection and Maintenance',
            content: 'Every car in our inventory undergoes a comprehensive inspection to ensure its quality and reliability. We partner with trusted mechanics and technicians to maintain our vehicles in optimal condition, providing peace of mind to our clients.',
            color: 'sec-light',
            reverse: true
      },
      {
            img: service4,
            title: 'Exceptional Customer Service',
            content: 'Reusautobestsales is dedicated to delivering an outstanding customer experience. Our knowledgeable and friendly sales team is committed to assisting clients throughout the car selection and financing process, answering their queries, and providing guidance every step of the way.',
            color: 'light-bg',
            reverse: true
      },
      {
            img: service5,
            title: 'Transparent and Ethical Practices',
            content: 'We prioritize transparency and ethical conduct in all our dealings. Our car loan agreements are designed to be clear and straightforward, with no hidden charges or unfair terms. We value the trust our clients place in us and strive to maintain the highest standards of integrity.',
            color: 'pry-light',
            reverse: false
      },
      {
            img: service6,
            title: 'Community Engagement',
            content: 'Reusautobestsales is actively involved in community initiatives and endeavors to give back to society. We support local charities, educational programs, and environmental initiatives, aiming to make a positive impact on the communities we serve.',
            color: 'sec-light',
            reverse: false
      },
];

// utils

const startYear = 2001;
const endYear = 2023;

export const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

export const days = Array.from({ length: 31 }, (_, index) => index + 1);

export const interestRates = Array.from({ length: 23 }, (_, index) => 18 + index);

export const naira = '₦';


// PLACEHOLDER DATA

export const brands = [
      { name: 'Subaru', img: Subaru },
      { name: 'Nissan', img: Nissan },
      { name: 'Chery', img: Chery },
      { name: 'Suzuki', img: Suzuki },
      { name: 'Datsun', img: Datsun },
      { name: 'Hyundai', img: Hyundai },
      { name: 'Honda', img: Honda },
      { name: 'BMW', img: BMW },
      { name: 'Mazda', img: Mazda },
      { name: 'Toyota', img: Toyota },
      { name: 'Daihatsu', img: Daihatsu },
      { name: 'Mitsubishi', img: Mitsubishi },
      { name: 'Mercedes', img: Mercedes },
      { name: 'Audi', img: Audi },

];

export const types = [
      'Brand New', 'Foreign Used', 'Locally Used'
];

export const engineType = [
      '4-Cylinder', '6-Cylinder', '8-Cylinder', '10-Cylinder', '12-Cylinder'
];

export const carModels = [
      "Toyota Camry",
      "Honda Civic",
      "Ford Mustang",
      "Chevrolet Corvette",
      "Nissan Altima",
      "BMW 3 Series",
      "Mercedes-Benz C-Class",
      "Audi A4",
      "Volkswagen Golf",
      "Subaru Outback",
      "Jeep Wrangler",
      "Tesla Model 3",
      "Kia Soul",
      "Hyundai Sonata",
      "Mazda CX-5",
      "Lexus RX",
      "Porsche 911",
      "Volvo XC60",
      "GMC Sierra",
      "Ram 1500"
];

export const carFeatures = [
      {
            title: 'Features',
            name: 'features',
            labels: ['Rank 1', 'Rank 2', 'Rank 3']
      },
      {
            title: 'Engine',
            name: 'engine',
            labels: [
                  "Start The Engine",
                  "No Engine Noise - Crank Shaft Bearings/Knocking/Metal Bearing",
                  "Smoke In Exhaust",
                  "Engine Misfiring",
                  "Arm Or Head Rest Upholstery",
                  "No Sludge Found",
                  "Oil Leakage",
                  "Coolant/Reservoir/Radiator/Hose/Cap",
                  "Engine Overhaul Extent/Repair History",
                  "Rpm At Idle",
                  "Other Engine Noise",
                  "Engine Mounts"
            ]
      },
      {
            title: 'Electricals',
            name: 'electricals',
            labels: [
                  "Dtc On Instrument Cluster",
                  "Wiring Harness/Fuse Box/Power Module",
                  "Abs",
                  "Air Bag",
                  "Transmission Electrical Malfunction",
                  "Eps",
                  "Tampered Odometer",
                  "Switch On Stereo & Play Music",
                  "Starter Operation",
                  "Ignition Key",
                  "Wiper Mechanism",
                  "Trafficator/Side Indicators",
                  "Battery Condition"
            ]
      },
      {
            title: 'Transmission and Clutch',
            name: 'transmissionAndClutch',
            labels: [
                  "Oil Leakage/Condition",
                  "Gear Mount",
                  "Clutch Operation"
            ]
      },
      {
            title: 'Suspension and Steering',
            name: 'suspensionAndSteering',
            labels: [
                  "Power Steering Pump/Steering Rack",
                  "Drive Shaft/Boot",
                  "Turn Steering Wheel Full From Leftwards And Rightwards",
                  "Air Suspension/Height Control",
                  "Struts & Lift Springs",
                  "4wd Option",
                  "No Leakage From Shocks",
                  "Brake Disc/Drums Rear"
            ]
      },
      {
            title: 'Test Drive',
            name: 'testDrive',
            labels: [
                  "Jerk/Surge In Motion",
                  "Gear Shift/Movement",
                  "Suspension Noise",
                  "Braking Efficiency & Performance",
                  "Over Heating",
                  "Drive The Vehicle & Observe",
                  "Engine Noise",
                  "Transmission Noise",
                  "Other Noises",
                  "Alternator"
            ]
      },
      {
            title: 'Interior',
            name: 'interior',
            labels: [
                  "Seat And Arm Rest Upholstery",
                  "Dashboard/Fittings/Center Console",
                  "Roof Upholstery",
                  "Door Fittings/Trim",
                  "Seat Adjuster Function/Controls",
                  "Seat Belt",
                  "Interior Floor Carpet",
                  "Door Handle Inner",
                  "Boot Trim/Fittings",
                  "Steering Wheel",
                  "Sunvisors",
                  "Inner Mirror"
            ]
      },
      {
            title: 'Exterior',
            name: 'exterior',
            labels: [
                  "Chassis",
                  "Body Pillar",
                  "First Body",
                  "Engraved",
                  "Radiator Core Support",
                  "Underbody",
                  "Boot/Trunk/Tail Gate/Pillar",
                  "Side Mirror",
                  "Bonnet",
                  "Front Windshield",
                  "Door Handles",
                  "Bumper Front",
                  "Door Frt Lh & Rh",
                  "Back Door Rh & Lh",
                  "Bumper Rear",
                  "Cowl Panel",
                  "Fender Rear Rh & Lh",
                  "Fender Frt Lh & Rh",
                  "Head/Tail Lamp",
                  "Rear Windshield"
            ]
      },
      {
            title: 'Air Condition System',
            name: 'airConditionSystem',
            labels: [
                  "Cooling Performance",
                  "Compressor",
                  "Other Control Button/Knob",
                  "Repair History"
            ]
      },
];

export const rates = [
      { rate: '', name: 'None' },
      { rate: 'Under 2M', name: 'Under 2M' },
      { rate: '2M-5M', name: '2M-5M' },
      { rate: '6M-10M', name: '6M-10M' },
      { rate: '11M-15M', name: '11M-15M' },
      { rate: 'Above 15M', name: 'Above 15M' },
];

export const loanTerm = [
      { rate: '6', name: '6 months' },
      { rate: '12', name: '12 months' },
      { rate: '24', name: '24 months' },
      { rate: '36', name: '36 months' },
      { rate: '48', name: '48 months' },
];


