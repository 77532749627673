import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import { naira } from "../../utils/data";
import { Button } from "../../styles";
import {  ICON_STAR } from "../../utils/icons";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useCarContext } from "../../context/car_context";
import ImageCarousel from "./ImgCarousel";
import { th } from "../../utils/helpers";
import AppLoader from "../../utils/AppLoader";
import RelatedCars from "./RelatedCars";
import BackButton from "../BackButton";
import { carDetailsPic } from "../../assets";
import { Fragment } from "react";
import CarLoanCalculator from "./CarLoanCalculator";


const CarDetails = () => {
      const params = useParams();
      const { id } = params;

      const {
            carInfo: { getCarDetails, carData, loading }
      } = useCarContext();

      useEffect(() => {
            getCarDetails(id);
      }, [params]);

      const { images, description, amount, name, location, interiorColor, exteriorColor, transmission, mileage, vin, fuelType, source, vehicleId, color, engineType, gearType } = carData;

      const mainDetails = [
            { title: 'COLOR', details: color },
            { title: 'CAR TYPE', details: source },
            { title: 'ENGINE TYPE', details: engineType },
            { title: 'GEAR TYPE', details: gearType },
      ];
      const moreDetails = [
            { title: 'LOCATION', details: location },
            { title: 'INTERIOR COLOR', details: interiorColor },
            { title: 'EXTERIOR COLOR', details: exteriorColor },
            { title: 'TRANSMISSION', details: transmission },
            { title: 'MILEAGE', details: th(mileage) },
            { title: 'VIN', details: vin },
            { title: 'VEHICLE ID', details: vehicleId },
            { title: 'FUEL TYPE', details: fuelType },
      ];

      const calcData = { id, amount}
      return (
            <HomeWrapper>
                  <AppLoader
                        load={loading}
                  />

                  <StyledCarDetails>
                        <div className="container">
                              <BackButton />
                              <h5 className="mt-5">
                                    Car Details
                              </h5>

                              <div className="my-5 br-sm">
                                    <div className="row">
                                          <div className="col-md-6">
                                                <ImageCarousel images={images} />

                                          </div>
                                          <div className="col-md-6 mt-4 mt-md-0">
                                                <h4 className="text-uppercase">
                                                      {name}
                                                </h4>
                                                <div className="d-flex gap-3">
                                                      <p className="">4.0
                                                            <span className="sec-text ms-1">
                                                                  {ICON_STAR}
                                                            </span>
                                                      </p>

                                                </div>
                                                <div className="pry-light container pt-3 pb-1 mb-4 br-sm">
                                                      <p className="">
                                                            {description || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur at soluta reprehenderit voluptas iusto ullam qui aperiam, dolorem repellat id corporis, saepe blanditiis quaerat. Facere aliquid maiores nihil tenetur alias.'}

                                                      </p>
                                                </div>
                                                <div className="sec-light container pt-3 pb-1 mb-4 br-sm">
                                                      <p>
                                                            Features and Specs
                                                            <ul>
                                                                  {mainDetails.map(({ title, details }, i) =>

                                                                        <li key={i}>
                                                                              {title}: {details || 'Nil'}
                                                                        </li>
                                                                  )}
                                                            </ul>
                                                      </p>
                                                </div>

                                                <div className="d-flex">
                                                      <p className="year sec light-text me-3">
                                                            2023
                                                      </p>
                                                      <p className="price">
                                                            {naira}{th(amount || '0')}
                                                      </p>
                                                </div>

                                                <div className="d-flex gap-2 mt-3 ">
                                                      <Link to={`/cars/buy/${ id }`} className=" w-100">
                                                            <Button pry className="btn  w-100">
                                                                  Buy Now
                                                            </Button>
                                                      </Link>
                                                      <Link to={`/cars/loan/setup/${ id }`} className="w-100">
                                                            <Button className="btn w-100 border border-dark shadow-none">
                                                                  Buy on Loan
                                                            </Button>
                                                      </Link>
                                                </div>
                                          </div>
                                    </div>

                              </div>
                              <div className="my-5 container py-5 br-sm" style={{ background: '#FBFBFB' }}>
                                    <h5 className="text-center mb-4">
                                          Car Details
                                    </h5>
                                    <div className="row">
                                          <div className="col-md-6">
                                                {moreDetails.map(({ title, details }, i) =>
                                                      <Fragment key={i}>
                                                            <div className="d-flex">
                                                                  <div className="col-5">
                                                                        {title}:
                                                                  </div>
                                                                  {'  '}                                                         <div className="col-7">
                                                                        {details || 'Nil'}
                                                                  </div>
                                                            </div>
                                                            <hr />
                                                      </Fragment>
                                                )}
                                          </div>
                                          <div className="col-md-6 mt-5 mt-md-0">
                                                <img src={carDetailsPic} alt="" width="100%" className="br-sm" />
                                          </div>
                                    </div>
                              </div>

                              <div className="my-5 shadow container py-5 br-sm" style={{ background: '#FBFBFB' }}>
                                    <h5 className="text-center mb-4">
                                          Loan Calculator
                                    </h5>
                                    <hr />

                                    <CarLoanCalculator
                                    {...calcData}
                                    />
                              </div>

                              <div className="d-flex justify-content-end ">
                                    <BackButton />
                              </div>
                        </div>

                  </StyledCarDetails>
                  <div className="sec-light py-5">
                        <RelatedCars />
                  </div>
            </HomeWrapper>
      );
};

export default CarDetails;

const StyledCarDetails = styled.main`
      margin: 3rem 0;

      img {
            width: 100%;
      }

   .year {
      margin: 0;
      padding: 5px 10px 0px;
      border-radius: 5px;
      text-align: center;
      white-space: nowrap;
}
    .price {
      border: 1px solid var(--dark-color);
      margin: 0;
      padding: 5px 10px 0px;
      border-radius: 5px;
      text-align: center;
      white-space: nowrap;
}

hr {
      /* border: 1px solid var(--grey-color) !important; */
}
`;