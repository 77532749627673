import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Input, Label } from "../../../styles";
import BackButton from '../../BackButton';
import AdminWrapper from '../../../layout/AdminWrapper';
import SecureApi from '../../../adminApi/secureApi';
import { useParams } from 'react-router-dom';
import { useAdminContext } from '../../../context/admin_context';
import MiniLoader from '../../../utils/MIniLoader';
import { useEffect } from 'react';

const EditAgent = () => {


      const {
            agents: { loadAgents, allAgents, getAgents },
      } = useAdminContext();

      const params = useParams();
      const { id } = params;



      const agent = allAgents?.find((e) => e._id === id) || {
            name: '', phoneNumber: '', cardNumber: '', email: '', whatsAppNumber: ''
      };
      const {
            name, phoneNumber, cardNumber, email, whatsAppNumber

      } = agent;

      useEffect(() => {
            getAgents();
      }, []);

      const [formField, setformField] = useState({
            name, phoneNumber, cardNumber, email, whatsAppNumber

      });

      const [sending, setsending] = useState(false);






      // Form Fields
      function handleChange(e) {
            if (e.target.type === "file") {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field


      function submitFn(e) {
            e.preventDefault();
            if (
                  formField.name
            ) {
                  setsending(true);
                  const data = new FormData();

                  data.append('name', formField.name);
                  data.append('whatsAppNumber', formField.whatsAppNumber);
                  data.append('cardNumber', formField.cardNumber);
                  data.append('phoneNumber', formField.phoneNumber);
                  data.append('email', formField.email);
                  // data.append('password', '12345678');
                  // data.append('passport', formField.photo);
                  // data.append('card', formField.card);

                  SecureApi.put(`/admin/agents/${ id }`, data, {
                        headers: {
                              "content-type": "multipart/form-data",
                        },
                  })
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      return (
            <>
                  <AdminWrapper>
                        <div className="light-bg br-sm py-3 px-3">
                              <h5 className="m-0">
                                    <span className="grey-bold-text">
                                          Admin /
                                    </span> Edit Agent
                                    <MiniLoader
                                          load={loadAgents}
                                    />
                              </h5>
                        </div>

                        <div className="light-bg my-2 container py-4 br-sm">
                              <BackButton />

                              <Form onSubmit={(e) => submitFn(e)} className="mt-4">
                                    <div className="row">

                                          {/* <div className="col-md-6">
                                                <Label htmlFor="photo">
                                                      Cover Photo:
                                                </Label>

                                                <Input
                                                      accept="image/*"
                                                      type="file"
                                                      id="photo"
                                                      name='photo'
                                                      onChange={handleChange}
                                                />

                                          </div>

                                    */}
                                          <div className="col-md-6">
                                                <Label htmlFor="name">
                                                      Full Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="name"
                                                      id="name"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.name}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="email">
                                                      Email Address:
                                                </Label>
                                                <Input
                                                      type="email"
                                                      name="email"
                                                      id="email"
                                                      onChange={handleChange}
                                                      value={formField.email}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="phoneNumber">
                                                      Phone Number :
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="phoneNumber"
                                                      id="phoneNumber"
                                                      onChange={handleChange}
                                                      value={formField.phoneNumber}
                                                      required
                                                />

                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="whatsAppNumber">
                                                      Whatsapp Number :
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="whatsAppNumber"
                                                      id="whatsAppNumber"
                                                      onChange={handleChange}
                                                      value={formField.whatsAppNumber}
                                                      required
                                                />

                                          </div>
                                          {/* 
                                          <div className="col-md-6">

                                                <Label htmlFor="card">
                                                      ID Card:
                                                </Label>

                                                <Input
                                                      accept="image/*"
                                                      type="file"
                                                      id="card"
                                                      name='card'
                                                      onChange={handleChange}
                                                />

                                          </div> */}

                                          <div className="col-md-6">

                                                <Label htmlFor="cardNumber">
                                                      ID Card Number:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="cardNumber"
                                                      id="cardNumber"
                                                      onChange={handleChange}
                                                      value={formField.cardNumber}
                                                      required
                                                />
                                          </div>

                                          <div className="col-12 text-center">
                                                <Button
                                                      sec
                                                      className="btn"
                                                      type="submit"
                                                      disabled={sending}
                                                >
                                                      {sending ? 'loading...' : 'Submit'}
                                                </Button>
                                          </div>
                                    </div>
                              </Form>
                        </div>
                  </AdminWrapper>



            </>
      );
};

export default EditAgent;