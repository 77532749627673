import styled from "styled-components";
import { brands } from "../../utils/data";

const PopularBrands = () => {

  return (
    <div className="container">
      <StyledPopularBrands>

        <h4 className="mb-5"> Popular Brands</h4>

        <marquee behavior="smooth" direction="left" scrollamount="20">
          {Array.from({ length: 50 }).map((_, index) => (
            <>
              {brands.map(({ img }, index) => (
                <img key={index} src={img} alt={`Image ${ index }`} />
              ))}
            </>
          )
          )}
        </marquee>
      </StyledPopularBrands>
    </div>
  );
};

export default PopularBrands;

const StyledPopularBrands = styled.section`
      padding: 4rem 0;     
      overflow-x: hidden;

      img {
        padding:0 20px;
      }


`;