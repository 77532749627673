import styled from "styled-components";
import { car3 } from "../../assets";


const HomeAbout = () => {
      return (
            <StyledHomeAbout>
                  <div className="container d-flex justify-content-center ">
                        <div className="row justify-content-between align-items-center light-bg br-md shadow container py-5 flex-md-row-reverse">
                              <div className="col-md-6">
                                    <img src={car3} alt="Car" width='100%' />
                              </div>
                              <div className="col-md-5">
                                    <h1>
                                          About Reusautobestsales
                                    </h1>
                                    <p className="mt-5">
                                          We are passionate about connecting car buyers with their dream vehicles. We understand that buying a used car can be a daunting process, filled with uncertainty and hidden surprises. That's why we've built a platform that is dedicated to providing you with a transparent, reliable, and enjoyable car buying experience
                                    </p>
                              </div>

                        </div>
                  </div>
            </StyledHomeAbout>
      );
};

export default HomeAbout;

const StyledHomeAbout = styled.section`
      padding: 4rem 0;
      background-color: var(--light-bold-color)


      
`;