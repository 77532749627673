import { Button, Input, Label, Select } from "../../../styles";


const LoanFormConsent = ({ settermsAndCondition, setconsent, sending }) => {
      return (
            <>

                  <div className="row">

                        <div className="col-md-12 text-start">
                              <Label htmlFor="" className="dark-text">
                                    I hereby consent that Autochek make enquiries regarding my credit history with any credit bureau. I consent that Autochek reports the conclusion of any credit agreement with me to the relevant credit reporting regulator. I hereby declare that the information provided by me is true and correct.
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="termsAndCondition"
                                                id="true"
                                                value={true}
                                                onChange={(e) => settermsAndCondition(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="true">
                                                Yes
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="termsAndCondition"
                                                id="false"
                                                value={false}
                                                onChange={(e) => settermsAndCondition(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="false">
                                                No
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div className="col-md-12 text-start">
                              <Label htmlFor="" className="dark-text">
                                    Finance is subject to an approval based on your credit profile and affordability of the vehicle. The submission of this finance application will not result in the immediate reservation of this vehicle, nor will it guarantee the availability of this vehicle in the future.
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="consent"
                                                id="true"
                                                value={true}
                                                onChange={(e) => setconsent(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="true">
                                                Yes
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="consent"
                                                id="false"
                                                value={false}
                                                onChange={(e) => setconsent(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="false">
                                                No
                                          </label>
                                    </div>
                              </div>
                        </div>


                  </div>

                  <Button
                        pry
                        type="submit"
                        disabled={sending}
                        className="btn m-0 w-100"  >
                        {sending ? 'loading...' : "Submit"}
                  </Button>


            </>
      );
};

export default LoanFormConsent;