import React from 'react';
import styled from 'styled-components';
import { BMW, Hyundai, Nissan, graph, graph1 } from '../../../assets';


const DashboardHeader = () => {
      return (
            <StyledDashboardHeader>
                  <div className="row mb-4">
                        <div className="col-md-4 mb-2">
                              <div className="light-bg py-3 px-2 br-sm cards row m-0 align-items-between">
                                    <div className="">
                                          <p className="fw-bold">
                                                New User
                                          </p>
                                          <h4 className="grey-bold-text">
                                                <span className="text-success"> + </span> 2,000
                                          </h4>
                                    </div>
                                    <img src={graph1} alt="Graph" width="100%" />
                              </div>
                        </div>
                        <div className="col-md-4 mb-2">
                              <div className="light-bg py-3 px-2 br-sm cards row m-0 align-items-between">
                                    <div className="">
                                          <p className="fw-bold">
                                                Total Order
                                          </p>
                                          <h1 className="grey-bold-text">
                                                <span className="text-success"> + </span> 55k
                                          </h1>
                                    </div>
                                    <img src={graph} alt="Graph" width="100%" />
                              </div>
                        </div>
                        <div className="col-md-4 mb-2">
                              <div className="light-bg  py-3 px-2 br-sm cards">
                                    <p className="fw-bold">
                                          Best selling brands
                                    </p>

                                    <div className="d-flex grey-bold-text align-items-center">
                                          <img src={BMW} alt="BMW" width='40px' />
                                          <div className="h5 ms-2 m-0"> 45 Cars </div>
                                    </div>
                                    <div className="d-flex grey-bold-text align-items-center">
                                          <img src={Hyundai} alt="BMW" width='40px' />
                                          <div className="h5 ms-2 m-0"> 35 Cars </div>
                                    </div>
                                    <div className="d-flex grey-bold-text align-items-center">
                                          <img src={Nissan} alt="BMW" width='40px' />
                                          <div className="h5 ms-2 m-0"> 25 Cars </div>
                                    </div>
                              </div>
                        </div>
                  </div>

            </StyledDashboardHeader>
      );
};

export default DashboardHeader;

const StyledDashboardHeader = styled.div`

.cards {
height: 200px !important;
}
`;