import React from 'react';
import styled from 'styled-components';
import { ICON_PLUS_LG } from '../../../utils/icons';
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../context/admin_context';


const DealersHeader = () => {
      const {
            dealers: { allDealers },
      } = useAdminContext();
      return (
            <StyledDealersHeader>
                  <div className="row mb-4">
                        <div className="col-md-4 mb-2">
                              <Link to='add'>

                                    <div className="light-bg py-3 px-2 br-sm cards">
                                          <p className="fw-bold dark-text">
                                                Add new Dealer
                                          </p>
                                          <h1 className="text-success text-center m-0"
                                                style={{ fontSize: '7rem' }}
                                          >
                                                {ICON_PLUS_LG}
                                          </h1>
                                    </div>
                              </Link>
                        </div>
                        <div className="col-md-4 mb-2">


                              <div className="light-bg py-3 px-2 br-sm cards">
                                    <p className="fw-bold dark-text">
                                          Number of Dealers
                                    </p>
                                    <h1 className="grey-bold-text text-center m-0"
                                          style={{ fontSize: '7rem' }}
                                    >
                                          {allDealers.length || '0'}

                                    </h1>
                              </div>
                        </div>

                  </div>

            </StyledDealersHeader>
      );
};

export default DealersHeader;

const StyledDealersHeader = styled.div`

.cards {
height: 250px !important;
}


 .milage {
          margin: 0;
          padding: 5px 10px 0px;
          border-radius: 2px;
          text-align: center;
          white-space: nowrap;
          font-size: 8px;
          text-transform: uppercase;
}
`;