import { Button, Input, Label, Select } from "../../../styles";
import { days, interestRates, naira } from "../../../utils/data";


const LoanFormPreference = ({
      handleChange, formField, setfirstLoanYear, setmaintenancePlanSubscription, setsubscribeRoadWorthiness, setpaymentFeesUpfront
}) => {


      const paymentItem = [
            'Vehicle Registration', 'Insurance', 'Vehicle Tracking', 'Maintenance Plan', 'Credit Life Insurance'
      ];
      return (
            <>

                  <div className="row">

                        <div className="col-md-6 text-start">
                              <Label htmlFor="desiredEquityContribution" className="">
                                    Desired Equity Contribution ({naira}):
                              </Label>
                              <Input
                                    type="number"
                                    id="desiredEquityContribution"
                                    name='desiredEquityContribution'
                                    placeholder=""
                                    onChange={handleChange}
                                    value={formField.desiredEquityContribution}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="desiredMonthlyPayment" className="">
                                    Desired Monthly Payment ({naira}):
                              </Label>
                              <Input
                                    type="number"
                                    id="desiredMonthlyPayment"
                                    name='desiredMonthlyPayment'
                                    placeholder=""
                                    onChange={handleChange}
                                    value={formField.desiredMonthlyPayment}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="desiredInterateRate" className="">
                                    Desired Interate Rate (%):
                              </Label>
                              <Select
                                    name="desiredInterateRate"
                                    id="desiredInterateRate"
                                    onChange={handleChange}
                                    value={formField.desiredInterateRate}
                              // required
                              >
                                    <option value="">Select Interest Rate</option>
                                    {interestRates.map((rate, i) =>
                                          <option value={rate} key={i}>{rate} %</option>
                                    )}
                              </Select>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="desiredResidual" className="">
                                    Desired Residual/Balloon Percentage (%):
                              </Label>
                              <Select
                                    name="desiredResidual"
                                    id="desiredResidual"
                                    onChange={handleChange}
                                    value={formField.desiredResidual}
                              // required
                              >
                                    <option value="">Select Interest Rate</option>
                                    <option value="0">0%</option>
                                    <option value="10">10%</option>
                                    <option value="20">20%</option>
                                    <option value="30">30%</option>
                                    <option value="40">40%</option>

                              </Select>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="desiredLoanTerms">
                                    Desired Loan Terms
                              </Label>
                              <Select
                                    name="desiredLoanTerms"
                                    id="desiredLoanTerms"
                                    onChange={handleChange}
                                    value={formField.desiredLoanTerms}
                              // required
                              >
                                    <option value="">Select Loan Term</option>
                                    <option value="12 months">12 months</option>
                                    <option value="24 months">24 months</option>
                                    <option value="36 months">36 months</option>
                                    <option value="48 months">48 months</option>
                                    <option value="60 months">60 months</option>

                              </Select>
                        </div>

                        <div className="col-md-6 text-start">
                              <Label htmlFor="desiredRepaymentDate" className="">
                                    Desired Repayment Day:
                              </Label>
                              <Select
                                    name="desiredRepaymentDate"
                                    id="desiredRepaymentDate"
                                    onChange={handleChange}
                                    value={formField.desiredRepaymentDate}
                              // required
                              >
                                    <option value="">Select Repayment Day</option>
                                    {days.map((day, i) =>
                                          <option value={day} key={i}>{day}</option>
                                    )}
                                    <option value="Last Day">Last Day of the Month</option>

                              </Select>
                        </div>

                        <div className="col-md-6 text-start">
                              <Label htmlFor="" className="">
                                    Do you want to continue insurance subscription after the first year of the loan?
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="firstLoanYear"
                                                id="true"
                                                value={true}
                                                onChange={(e) => setfirstLoanYear(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="true">
                                                Yes
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="firstLoanYear"
                                                id="false"
                                                value={false}
                                                onChange={(e) => setfirstLoanYear(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="false">
                                                No
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="" className="">
                                    Do you want to continue maintenance plan subscription after the first year of the loan?
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="maintenancePlanSubscription"
                                                id="true"
                                                value={true}
                                                onChange={(e) => setmaintenancePlanSubscription(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="true">
                                                Yes
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="maintenancePlanSubscription"
                                                id="false"
                                                value={false}
                                                onChange={(e) => setmaintenancePlanSubscription(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="false">
                                                No
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="" className="">
                                    Do you want to subscribe to roadworthiness for the full term of the loan?
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="subscribeRoadWorthiness"
                                                id="true"
                                                value={true}
                                                onChange={(e) => setsubscribeRoadWorthiness(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="true">
                                                Yes
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="subscribeRoadWorthiness"
                                                id="false"
                                                value={false}
                                                onChange={(e) => setsubscribeRoadWorthiness(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="false">
                                                No
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="" className="">
                                    Do you want to pay for your fees upfront or monthly as part of your installment?
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="paymentFeesUpfront"
                                                id="Upfront"
                                                // value="Upfront"
                                                value={true}
                                                onChange={(e) => setpaymentFeesUpfront(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="Upfront">
                                                Upfront
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="paymentFeesUpfront"
                                                id="Monthly"
                                                // value="Monthly"
                                                value={false}
                                                onChange={(e) => setpaymentFeesUpfront(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="Monthly">
                                                Monthly
                                          </label>
                                    </div>
                              </div>
                        </div>

                        <div className="col-md-6 text-start">
                              <Label htmlFor="paymentItems">
                                    Do you want to pay for any following items upfront instead of financing it as part of the loan? <i>(hold shift and select)</i>
                              </Label>
                              <Select
                                    name="paymentItems"
                                    id="paymentItems"
                                    onChange={handleChange}
                                    value={formField.paymentItems}
                                    // required
                                    multiple
                              >
                                    {/* <option value="">Select Payment Items</option> */}
                                    {paymentItem.map((item, i) =>
                                          <option value={item} key={i}>{item} %</option>
                                    )}
                              </Select>
                        </div>
                  </div>
            </>
      );
};

export default LoanFormPreference;