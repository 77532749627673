import HomeWrapper from "../../layout/HomeWrapper";
import { Button, Form, Input, Label } from "../../styles";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../api/axios";
import { toast } from "react-toastify";
import { StyledAuth } from "./Register";

const ForgetPassword = () => {

      const [formField, setFormField] = useState({});
      const navigate = useNavigate();
      const [sending, setsending] = useState(false);


      const handleChange = (e) => {
            setFormField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value
            }));
      };

      function submitFn(e) {
            e.preventDefault();

            if (
                  formField?.email
            ) {
                  setsending(true);
                  const data = {
                        email: formField?.email,
                  };
                  Api.post(`/users/auth/forget-password`, data)
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    navigate(
                                          "/user/login"
                                    );
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }

      }

      return (
            <HomeWrapper>
                  <StyledAuth>
                        <div className="container">

                              <div className="light-bg py-5 shadow br-md">
                                    <div className="col-md-8 col-lg-6 mx-auto text-center container">
                                          <h5 className="mb-4">
                                                Forget Password
                                          </h5>

                                          <hr className="mb-5" />

                                          <Form onSubmit={(e) => submitFn(e)} >
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="email" className="">
                                                            Email Address
                                                      </Label>
                                                      <Input
                                                            type="email"
                                                            id='email'
                                                            name='email'
                                                            placeholder="Enter e-mail address"
                                                            onChange={handleChange}
                                                            value={formField.email}
                                                            required
                                                      />
                                                </div>
                                                <Button
                                                      pry
                                                      type="submit"
                                                      disabled={sending}
                                                      className="btn m-0 w-100"  >
                                                      {sending ? 'loading...' : "Reset Password"}
                                                </Button>
                                          </Form>

                                          <h6 className="mt-4">
                                                Back to {' '}
                                                <Link to='/user/login'>
                                                      <span className="fw-bold">
                                                            Login
                                                      </span>
                                                </Link>
                                          </h6>
                                    </div>
                              </div>
                        </div>

                  </StyledAuth>
            </HomeWrapper>
      );
};

export default ForgetPassword;
