import axios from 'axios';
import { toast } from 'react-toastify';

const mainAccess = JSON.parse(localStorage.getItem('mainAccess'));

const AdminApi = axios.create({
      baseURL: process.env.REACT_APP_ENDPOINT,
      headers: {
            'Authorization': `Bearer ${ mainAccess }`
      }
});

AdminApi.interceptors.request.use(
      (request) => {
            let newRequest = {
                  ...request, data: request.data
            };
            return newRequest;
      },
      (error) => {
            return Promise.reject(error);
      }
);

AdminApi.interceptors.response.use(
      (response) => {
            return response.data;
      },
      (error) => {
            if (error.response.data) {
                  toast.warn(error.response.data.message);
            } else {
                  toast.error(error.message === "Request failed with status code 500" ? "Internal Error!" : error.message);
            }
            return Promise.reject(error);
      }
);

export default AdminApi;
