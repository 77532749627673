import React, { useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { th } from "../../../utils/helpers";
import { ICON_ARROW_UP_RIGHT, ICON_DELETE, ICON_PEN } from "../../../utils/icons";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import AdminWrapper from "../../../layout/AdminWrapper";
import { naira } from "../../../utils/data";
import { useAdminContext } from "../../../context/admin_context";
import AppLoader from "../../../utils/AppLoader";

export default function AllCars() {

      const {
            cars: { loadCars, allCars, getCars, deleteCar, removing },
      } = useAdminContext();

      useEffect(() => {
            const cars = allCars?.map(({ _id: id, vehicleId, images, name, year, amount }, i) => {
                  const carImg = images?.[0];
                  return (
                        {
                              sn: i + 1,
                              id: vehicleId,
                              edit: <Link to={`/admin/cars/${ id }`} className="text-center pry-text h4"> {ICON_PEN}</Link>,
                              delete: <a className="text-center text-danger h2"
                                    onClick={() => deleteCar(id, name)}
                              > {ICON_DELETE}</a>,
                              name: name,
                              year: year,
                              image: <img src={carImg} alt={name} width='70px' className="br-sm"></img>,
                              amount: naira + th(amount),
                        }
                  );
            });

            setDatatable({
                  ...datatable,
                  rows: cars
            });
      }, [allCars]);

      useEffect(() => {
            getCars();
      }, []);


      const [datatable, setDatatable] = React.useState({
            columns: [
                  {
                        label: "S/N",
                        field: "sn",
                        width: 150,
                        attributes: {
                              "aria-controls": "DataTable",
                              "aria-label": "sn",
                        },
                  },
                  {
                        label: "ID",
                        field: "id",
                        width: 150,

                  },

                  {
                        label: "Name",
                        field: "name",
                        width: 200,
                  },
                  {
                        label: "Year",
                        field: "year",
                        width: 200,
                  },
                  {
                        label: "Image",
                        field: "image",
                        width: 270,
                  },
                  {
                        label: "Amount ",
                        field: "amount",
                        width: 200,
                  },
                  {
                        label: "Edit",
                        field: "edit",
                        width: 50,
                  },
                  {
                        label: "Delete",
                        field: "delete",
                        width: 50,
                  },

            ],
            rows: []
      });


      return (
            <AdminWrapper>
                  <AppLoader
                        load={removing}
                  />

                  <div className="light-bg d-flex justify-content-between align-items-center br-sm py-1 px-2">
                        <div className="d-flex">
                              <h5 className="m-0 pt-2">
                                    All Cars
                              </h5>
                              <ClipLoader
                                    loading={loadCars}
                                    size='20'
                                    color={'var(--dark-color)'}
                                    className='ms-3 mt-2'
                              />
                        </div>
                        <h1 className="dark-text m-0">
                              {ICON_ARROW_UP_RIGHT}
                        </h1>
                  </div>
                  <div className="mt-3 br-md">

                        <MDBDataTableV5
                              hover
                              fullPagination
                              entries={50}
                              pagesAmount={4}
                              data={datatable}
                              pagingTop
                              searchTop
                              responsive
                              // theadColor="sec-bold pry-text"
                              maxHeight="700px"
                              // searchBottom={false}
                              className="light-bg br-sm pt-2"
                        />
                  </div>
            </AdminWrapper>

      );
}
