import axios from 'axios';
import { toast } from 'react-toastify';

const Api = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
});

Api.interceptors.request.use(
    (request) => {
        // console.log('request sent');
        let newRequest = {
            ...request, data: request.data
        };
        return newRequest;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(
    (response) => {
        // console.log('got response');
        return response.data;
    },
    (error) => {
        if (error.response.data) {
            toast.warn(error.response.data.message);
        } else {
            toast.error(error.message === "Request failed with status code 500" ? "Internal Error!" : error.message);
        }
        return Promise.reject(error);
    }
);

export default Api;
