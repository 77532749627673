import styled from "styled-components";
import { loanTerm, naira } from "../../utils/data";
import { useState } from "react";
import { Button } from "../../styles";
import { th } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import AppLoader from "../../utils/AppLoader";



const CarLoanCalculator = ({ id, amount }) => {

      const [sending, setsending] = useState(false);
      const [isRate, setisRate] = useState({
            rate: '24', name: '24 months'
      });
      const [sliderValue, setSliderValue] = useState('');
      const [upfront, setUpfront] = useState('');
      const [loanDetails, setLoanDetails] = useState('');


      function editRate(e) {
            let rateIndex = loanTerm?.findIndex(x => x.rate === e);
            let newRate = loanTerm[rateIndex];
            setisRate(newRate);
      }

      function getUpfront() {
            const minUpfront = amount * 0.7;
            const minUpfrontValue = amount - minUpfront;
            const maxUpfront = amount * 0.3;
            const maxUpfrontValue = amount - maxUpfront;
            setUpfront({ min: minUpfrontValue, max: maxUpfrontValue });
            setSliderValue(minUpfrontValue);
      }

      useEffect(() => {
            getUpfront();
      }, [amount]);

      const handleSliderChange = (e) => {
            setSliderValue(e.target.value);
      };

      function loanCalculator(value, upfront, loanTerm) {
            if (upfront > value) {
                  toast.warn('Upfront cannot be larger than value');
            }

            if (value && upfront && loanTerm) {
                  setsending(true);

                  setTimeout(() => {
                        setsending(false);
                        const InterestRate = 0.1;
                        const InterestValue = InterestRate * value;
                        const loanValue = InterestValue + value;
                        const loanValueMinusUpfront = loanValue - upfront;
                        const loanPaymentAmount = loanValueMinusUpfront / loanTerm;
                        setLoanDetails(loanPaymentAmount);
                  }, 1000);


            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }

      }

      // useEffect(() => {
      //       loanCalculator(10000, 2000, 24);
      // }, []);

      return (
            <StyledCarLoanCalculator>
                  <AppLoader
                        load={sending}
                  />

                  <div className="row">
                        <div className="col-md-6 py-4">
                              <p className="fw-bold">
                                    Loan Preference
                              </p>
                              <div className="d-flex justify-content-between mb-4 pry-light py-2 px-2 br-sm">
                                    <p className="mb-0">
                                          Car Value
                                    </p>
                                    <p className="mb-0">
                                          {naira}{th(amount || 0)}
                                    </p>
                              </div>
                              <div className="mb-4 pry-light py-2 px-2 br-sm">
                                    <p className="">
                                          Loan term (Optional)
                                    </p>
                                    <div className="row">
                                          {loanTerm?.map(({ rate, name }, i) =>
                                                <label className="custom-radio col " key={name} htmlFor={name}>
                                                      <input
                                                            type="radio"
                                                            className="rate-radio"
                                                            name={name}
                                                            id={name}
                                                            value={rate}
                                                            onChange={e => editRate(e.target.value)}
                                                            checked={name == isRate?.name ? true : false}
                                                      />

                                                      {name == isRate?.name ?
                                                            <div className="rates mb-3 small fw-bold p-2 py-1 pry-text">
                                                                  {name}
                                                            </div>
                                                            :
                                                            <>
                                                                  <div className="rates mb-3 small fw-bold p-2 py-1">
                                                                        {name}
                                                                  </div>
                                                            </>
                                                      }
                                                </label>
                                          )}
                                    </div>
                              </div>

                              <div className="mb-4">
                                    <p className="">
                                          Upfront Payment (Optional)
                                    </p>
                                    <p>Value: {naira}{th(sliderValue)}</p>
                                    <SliderContainer>
                                          <SliderInput
                                                type="range"
                                                min={upfront?.min}
                                                max={upfront?.max}
                                                value={sliderValue}
                                                onChange={handleSliderChange}
                                          />
                                          <div className="d-flex justify-content-between">
                                                <p className="small grey-bold-text">
                                                      Min 30% of car value
                                                </p>
                                                <p className="small grey-bold-text">
                                                      Max 70% of car value
                                                </p>
                                          </div>
                                    </SliderContainer>
                              </div>

                              <div className="col-12 text-center">
                                    <Button
                                          sec
                                          className="btn btn-sm w-100 m-0"
                                          type="submit"
                                          disabled={sending}
                                          onClick={() => loanCalculator(amount, sliderValue, isRate?.rate)}
                                    >
                                          {sending ? 'loading...' : 'Calculate'}
                                    </Button>
                              </div>
                        </div>
                        <div className="col-md-6 mt-5 mt-md-0">
                              <div className="pry-light br-md px-3 py-5 h-100 calc-result ">
                                    {
                                         !loanDetails && 
                                    <div class="blurry-mask"></div>
                                    }

                                    <div className="">
                                          <div className="text-center">
                                                <p className="small">
                                                      Estimated Value
                                                </p>
                                                <h4 className="">
                                                      {naira}{th(Math.ceil(loanDetails))}.00
                                                </h4>
                                          </div>
                                          <div className="d-flex justify-content-between mb-4 pry-light py-2 px-2 br-sm">
                                                <p className="mb-0">
                                                      Car Value
                                                </p>
                                                <p className="mb-0">
                                                      {naira}{th(amount || 0)}
                                                </p>
                                          </div>
                                          <div className="d-flex justify-content-between mb-4 pry-light py-2 px-2 br-sm">
                                                <p className="mb-0">
                                                      Down Payment
                                                </p>
                                                <p className="mb-0">
                                                      {naira}{th(sliderValue || 0)}
                                                </p>
                                          </div>
                                          <div className="d-flex justify-content-between mb-4 pry-light py-2 px-2 br-sm">
                                                <p className="mb-0">
                                                      Loan Tenure
                                                </p>
                                                <p className="mb-0">
                                                      {isRate?.name}
                                                </p>
                                          </div>
                                    </div>
                                    <div className="">
                                          <Link to={`/cars/loan/setup/${ id }`}>
                                                <Button
                                                      sec
                                                      className="btn btn-sm w-100 m-0"
                                                      type="submit"
                                                >
                                                      Apply for loan
                                                </Button>
                                          </Link>
                                    </div>

                              </div>
                        </div>
                  </div>

            </StyledCarLoanCalculator>
      );
};

export default CarLoanCalculator;

const StyledCarLoanCalculator = styled.div`

      .custom-radio {

      input.rate-radio {
       display: none ;
     }
     
     input:checked + .rates {
           border: 1px solid var(--pry-color) !important;
      }
     input + .rates {
           border: 1px solid var(--grey-color) !important;
           text-align: center;
      }

}

.calc-result{
        position: relative;    
        width: 100%;
        height: 100%;
}

  .blurry-mask {
  width: 95%;
  height: 80%;
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
  position: absolute;
}

`;

const SliderContainer = styled.div`
  width: 100%;
  margin: 20px auto;
`;

const SliderInput = styled.input`
  width: 100%;
  background: var(--dark-color);
  border: none;
  height: 3px;
  outline: none;
  border-radius: 4px;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: var(--sec-color);
    border-radius: 50%;
    cursor: pointer;
  }

`;

