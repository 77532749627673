import styled from "styled-components";



export const StyledTopNav = styled.nav`
margin-top: 6rem;
margin-right: 170px;
margin-left: 420px !important;

border-radius: .5rem;
margin-left: auto;
  background-color: var(--light-color);
  transition: 0.5s ease;
  padding: 1rem 1rem;
  z-index: 1000;

   .bell {
  left: 18px !important;
  top: 5px !important;
  font-size: 7px;
  }

  svg {
    font-size: 20px;
  }

  img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-size: contain;
    
  }
  
@media screen and (max-width: 992px) {
  margin-left: 266px !important;
    margin-right: 1rem !important;
/* margin-top: 1rem; */

}

@media screen and (max-width: 580px) {
  margin-left: 0px !important;
margin-right: 0rem !important;
/* margin-top: 0rem; */

}
`;

export const StyledSideNav = styled.aside`
 margin-top: 6rem;
  margin-left: 150px;
  height: 83%;
  width: 250px;
  border-radius: .5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--light-color);
  overflow: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  z-index: 1003 !important;
 
  .navbar-brand {
    width: 100% !important;
    padding: 1rem !important;
    color: var(--pry-color);
    margin-bottom: 1rem;
  }
  
    
  .nav-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    
    a {
     width: 100% !important;
     color: var(--dark-color);
     font-size: 1rem;
     padding: 0rem 1rem;
     border: 2px solid transparent;
     
     .link {
      padding: 0.5rem 0;
     }
     :hover {
       border: 2px solid var(--pry-color);
      }

      svg {
        font-size: 1.2rem;
        margin-right:10px;
        margin-bottom:3px;
      }
    }

    a.active {
     background-color: var(--pry-color);
     color: var(--light-color);
    }
    
    .sublink {
      a {
        width: 100% !important;
        color: var(--dark-color);
        font-size: 1rem;
        padding: 0.5rem 0rem;
        padding-left: 3rem;
     border: 2px solid transparent;
     
     :hover {
background-color: var(--light-bold-color);
     color: var(--dark-color);
          }

      svg {
        font-size: 1.2rem;
        margin-right:10px;
        margin-bottom:3px;
      }
    }

    a.active {
     background-color: var(--light-bold-color);
     color: var(--dark-color);
    }
    }
   }



@media screen and (max-width: 992px) {
  width: 0;
  margin-left: 0rem;
  overflow-y: hidden;
}
@media screen and (max-width: 580px) {
  width: 0;
  margin-left: 0rem;
  overflow-y: hidden;
}


`;

export const Hamburger = styled.span`
svg {
    color: var(--pry-color);
    font-size: 27px;
    cursor:pointer;
    display: none;
}
@media screen and (max-width: 580px) {
  svg {
  display: block;
  }
}
`;
export const CloseBtn = styled.span`
svg {
  position: absolute;
  right: 15px;
  top: 6px;
  font-size: 36px;
  margin-left: 50px;
  display: none;
}

@media screen and (max-width: 580px) {
  svg {
  display: block;
  }
}
`;
