import { Alert } from "react-bootstrap";
import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import { useCarContext } from "../../context/car_context";
import CarItem from "../CarPage/CarItem";
import RelatedCars from "../CarPage/RelatedCars";
import BackButton from "../BackButton";


const SearchResult = () => {

      const {
            search: { result },
      } = useCarContext();
      console.log(result);
      return (

            <HomeWrapper>
                  <StyledSearchResult>

                        <div className="container">
                              <BackButton/>
                              <Alert className="mb-4" variant={result?.length === 0 ? 'danger' : 'success'}>
                                    <h5 className="m-0">
                                          {result?.length} result(s) found
                                    </h5>
                              </Alert>


                              <div className="" id="topPage"></div>
                              <div className="row">
                                    {result?.map((item, i) =>
                                          <div className="col-md-6 col-lg-4 mb-4" key={i}>
                                                <CarItem {...item} key={i} />
                                          </div>
                                    )}
                              </div>

                              <div className="">
                                    <RelatedCars />
                              </div>
                        </div>


                  </StyledSearchResult>
            </HomeWrapper>
      );
};

export default SearchResult;

const StyledSearchResult = styled.main`
      margin: 2rem 0;

`;