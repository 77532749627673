import { useRef } from "react";
import { ICON_ARROW_RIGHT, ICON_ARROW_UP_RIGHT } from "../../../utils/icons";
import BuyFormCar from "../../CarPage/ApplyForCar/BuyFormCar";
import Glide from "react-glidejs";
import { StyledGlide } from "../../../styles";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../context/admin_context";



const FeaturedCars = () => {

      const gliderRef = useRef(null);

      const {
            cars: { loadCars, allCars },
      } = useAdminContext();


      const featuredCars = allCars?.slice(0, 5);

      return (
            <>
                  <div className="light-bg d-flex justify-content-between align-items-center br-sm py-1 px-2">
                        <div className="d-flex">
                              <h5 className="m-0 pt-2">
                                    All Cars
                              </h5>
                              <ClipLoader
                                    loading={loadCars}
                                    size='20'
                                    color={'var(--dark-color)'}
                                    className='ms-3 mt-2'
                              />
                        </div>
                        <Link to='all'>
                              <h1 className="dark-text m-0">
                                    {ICON_ARROW_UP_RIGHT}
                              </h1>
                        </Link>
                  </div>

                  <div className="light-bg mt-2 py-4 px-1 br-sm">
                        <StyledGlide>
                              <Glide
                                    ref={gliderRef}
                                    startAt={0}
                                    perView={3}
                                    type="carousel"
                                    gap={20}
                                    animationDuration={1000}
                                    animationTimingFunc="ease"
                                    slideClassName="slider__frame"
                                    breakpoints={{
                                          1000: {
                                                perView: 2,
                                          },
                                          800: {
                                                perView: 1.5,
                                          },
                                          768: {
                                                perView: 1.1,
                                          },
                                    }}
                                    throttle={0}
                                    customSlideAnimation={{
                                          timeout: 500,
                                          classNames: 'fade',
                                    }}
                                    focusAt="center"
                              >
                                    {featuredCars?.map((item, i) =>
                                          <div className="col-md-12 mb-4" key={i}>
                                                <Link to={item?._id} className="dark-text">
                                                      <BuyFormCar {...item} />
                                                </Link>
                                          </div>
                                    )}
                              </Glide>

                        </StyledGlide>
                  </div>
            </>
      );
};

export default FeaturedCars;