import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Input, Label } from "../../../styles";
import BackButton from '../../BackButton';
import AdminWrapper from '../../../layout/AdminWrapper';
import SecureApi from '../../../adminApi/secureApi';
import { useAdminContext } from '../../../context/admin_context';

const CreateDealers = () => {

      const [formField, setformField] = useState({

      });
      const [sending, setsending] = useState(false);
      const navigate = useNavigate();

      const {
            dealers: { getDealers },
      } = useAdminContext();

      // Form Fields
      function handleChange(e) {
            if (e.target.type === "file") {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field


      function submitFn(e) {
            e.preventDefault();
            if (
                  formField.companyName
            ) {
                  setsending(true);
                  const data = {
                        companyName: formField.companyName,
                        contactPersonName: formField.contactPersonName,
                        whatsAppNumber: formField.whatsapp,
                        phoneNumber: formField.phone,
                        email: formField.email,
                        location: formField.location,
                        address: formField.address,
                  };


                  SecureApi.post(`/admin/dealers/create`, data)
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    getDealers();
                                    navigate(`/admin/dealers`);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      return (
            <>
                  <AdminWrapper>
                        <div className="light-bg br-sm py-3 px-3">
                              <h5 className="m-0">
                                    <span className="grey-bold-text">
                                          Admin /
                                    </span> Add new Dealer
                              </h5>
                        </div>

                        <div className="light-bg my-2 container py-4 br-sm">
                              <BackButton />

                              <Form onSubmit={(e) => submitFn(e)} className="mt-4">
                                    <div className="row">
                                          <div className="col-md-6">
                                                <Label htmlFor="companyName">
                                                      Company Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="companyName"
                                                      id="companyName"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.companyName}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="email">
                                                      Email Address:
                                                </Label>
                                                <Input
                                                      type="email"
                                                      name="email"
                                                      id="email"
                                                      onChange={handleChange}
                                                      value={formField.email}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="phone">
                                                      Phone Number :
                                                </Label>
                                                <Input
                                                      type="number"
                                                      name="phone"
                                                      id="phone"
                                                      onChange={handleChange}
                                                      value={formField.phone}
                                                      required
                                                />

                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="whatsapp">
                                                      Whatsapp Number :
                                                </Label>
                                                <Input
                                                      type="number"
                                                      name="whatsapp"
                                                      id="whatsapp"
                                                      onChange={handleChange}
                                                      value={formField.whatsapp}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="contactPersonName">
                                                      Contact Person Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="contactPersonName"
                                                      id="contactPersonName"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.contactPersonName}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="location">
                                                      Location:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="location"
                                                      id="location"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.location}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="address">
                                                      Address:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="address"
                                                      id="address"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.address}
                                                      required
                                                />
                                          </div>


                                          <div className="col-12 text-center">
                                                <Button
                                                      sec
                                                      className="btn"
                                                      type="submit"
                                                      disabled={sending}
                                                >
                                                      {sending ? 'loading...' : 'Submit'}
                                                </Button>
                                          </div>

                                    </div>

                              </Form>
                        </div>
                  </AdminWrapper>



            </>
      );
};

export default CreateDealers;