import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import SearchHeader from "../../components/search/SearchPageHeader";
import { useCarContext } from "../../context/car_context";
import CarItem from "../../components/CarPage/CarItem";

const SearchPage = () => {

      const {
            randomCars: { loadRandomCars, allRandomCars, getRandomCars }
      } = useCarContext();

      return (
            <HomeWrapper>
                  <StyledSearchPage>
                        <SearchHeader />
                        <div className="container">

                              <div className="row">
                                    {allRandomCars?.map((item, i) => {
                                          return (

                                                <div className="col-md-6 col-lg-4 mb-4" key={i}>
                                                      <CarItem {...item} carExists={false} key={i} />
                                                </div>
                                          );
                                    }




                                    )}
                              </div>



                        </div>
                  </StyledSearchPage>
            </HomeWrapper>
      );
};

export default SearchPage;

const StyledSearchPage = styled.main`
      padding-bottom: 2rem;
`;