import { Navigate } from 'react-router-dom';
// import { decrypt } from '../encrypt';
import { useLocalStorage } from '../utils/useLocalStorage';

const ProtectUser = ({ children }) => {

  const [accessToken] = useLocalStorage('accessToken');

  // let userData = decrypt(user_data);


  if (!accessToken) {
    return <Navigate to="/noAccess" replace />;
  }
  return children;
};

export default ProtectUser;
