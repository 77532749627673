import React from "react";
import { useNavigate } from "react-router-dom";
import { ICON_ARROW_LEFT } from "../utils/icons";
import styled from "styled-components";

const BackButton = () => {
    const navigate = useNavigate();

    function gooBack() {
        navigate(-1);
    }
    return (
        <>
            <StyledBackButton className="d-flex align-items-center fit-content pointer mb-3 mb-sm-2" onClick={gooBack}>
                <p className=" mr-2 m-0">
                    {ICON_ARROW_LEFT}
                </p>
                <p className='mr-2 m-0 mt-1'>
                    Back </p>
            </StyledBackButton>
        </>
    );
};

export default BackButton;


const StyledBackButton = styled.a`
    color: var(--dark-color);
    border-bottom: 1px var(--sec-color) solid;

`;
