import Navbar from '../components/admin/adminNav/Navbar';
import { DashboardWrapper } from '../styles';
import ProtectAdmin from '../app/protectAdmin';
import HomeNavbar from './HomeNavbar';


const AdminWrapper = ({ children }) => {


    return (
        <main>
            <ProtectAdmin>
                <HomeNavbar />
                <div className="light-bold-bg">
                    <div className="dark-text mx-auto">
                        <Navbar
                        />
                        <DashboardWrapper>
                            <div className='admin-container'>
                                {children}
                            </div>
                        </DashboardWrapper>
                    </div>
                </div>
            </ProtectAdmin>
        </main>
    );
};

export default AdminWrapper;