import styled from "styled-components";
import BuyFormCar from "./BuyFormCar";
import AppLoader from "../../../utils/AppLoader";
import { bizMan, form, inspect } from "../../../assets";
import { Button } from "../../../styles";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCarContext } from "../../../context/car_context";
import HomeWrapper from "../../../layout/HomeWrapper";
import { Modal } from "react-bootstrap";
import BackButton from "../../BackButton";


const LoanSetup = () => {

      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = (props) => setShow(true);;


      const params = useParams();
      const { id } = params;

      const {
            carInfo: { getCarDetails, carData, loading }
      } = useCarContext();

      useEffect(() => {
            getCarDetails(id);
      }, []);

      return (
            <HomeWrapper>
                  <AppLoader
                        load={loading}
                  />

                  <div className="container">
                        <StyledLoanSetup>
                              <BackButton />
                              <div className="row justify-content-center mt-4">
                                    <div className="col-md-4 h-100">
                                          <BuyFormCar {...carData} />
                                    </div>
                                    <div className="col-md-8 h-100 text-center">
                                          <div className="shadow container py-5">
                                                <h4 className=""> How Reusautobestsales Financing Works </h4>

                                                <div className="row py-5">
                                                      <div className="col-6 border-end ">
                                                            <img src={form} alt="Fill Form" width="50%" />
                                                            <p className="fw-bold mt-4">
                                                                  Fill the loan form in just 5 mins
                                                            </p>
                                                      </div>
                                                      <div className="col-6">
                                                            <img src={inspect} alt="Fill Form" width="50%" />
                                                            <p className="fw-bold mt-4">
                                                                  Inspect the car, pay equity and drive your car home
                                                            </p>
                                                      </div>
                                                </div>

                                                <div className="text-center">
                                                      <Button pry className="btn"
                                                            onClick={() => handleShow()}
                                                      >
                                                            Start Application
                                                      </Button>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </StyledLoanSetup>
                  </div>
                  <ApplyLoan
                        show={show}
                        onHide={handleClose}
                        {...carData}
                  />
            </HomeWrapper>
      );
};


export default LoanSetup;


const ApplyLoan = (props) => {
      const params = useParams();
      const { id } = params;

      const loanProcess = [
            'Fill a form and instantly get an offer',
            'Upload documents',
            'Inspect the car',
            'Pay equity',
            'Loan is disbursed',
            'Drive your car home'

      ];

      return (
            <Modal
                  show={props.show}
                  onHide={props.onHide}
                  keyboard={false}
                  centered
                  size="md"
            >
                  <Modal.Body>
                        <StyledModal>
                              <div className="my-2">
                                    <img src={bizMan} alt="Business man " width='100%' />
                              </div>
                              <div className="my-5 container">
                                    <h5>
                                          You are about to embark on an amazing experience. Here is what to expect before you drive your car
                                    </h5>

                                    <ul className="mt-4">
                                          {loanProcess?.map((item, i) =>
                                                <li key={i}>

                                                      {item}
                                                </li>
                                          )}
                                    </ul>
                              </div>
                              <div className="text-center">
                                    <Link to={`/cars/loan/${ id }`}>
                                          <Button pry className="btn"
                                          >
                                                Start Application
                                          </Button>
                                    </Link>
                              </div>
                        </StyledModal>
                  </Modal.Body>
            </Modal>
      );
};

const StyledLoanSetup = styled.section`
padding: 5rem 0;      

`;
const StyledModal = styled.section`

li {
 list-style: none; /* Remove default list bullet/number */
      position: relative; 
      font-size:20px;
}
   li::before {
      content: '✅'; /* Emoji or content you want to add */
      margin-right: 5px;
      
    }

`;