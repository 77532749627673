import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Api from "../api/axios";
import { toast } from "react-toastify";

const HomeFooter = () => {

      const links = [
            { title: 'Home', link: '/' },
            { title: 'Contact Us', link: '/contact' },
            { title: 'Login', link: '/user/login' },
            { title: 'Signup', link: '/user/register' },
      ];

      const [email, setEmail] = useState('');
      const [sending, setsending] = useState(false);

      function submitFn(e) {
            e.preventDefault();

            if (email) {
                  setsending(true);
                  const data = { email };
                  Api.post(`/subscribe`, data)
                        .then((res) => {
                              console.log(res);
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    toast.success(res.message);
                                    setsending(false);
                                    setEmail('');
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("email is empty!");
            }
      }

      return (
            <StyledFooter>

                  <div className="footer">
                        <div className="container py-4 light-text">

                              <div className="row justify-content-between ">
                                    <div className="col-md-6 mt-4 mt-md-0 text-center text-md-start">
                                          <div className="d-flex gap-3 flex-wrap">
                                                <div className="col-md-2 col-12">
                                                      <h5 className="m-0">Autobest</h5>
                                                </div>
                                                <div className=" col-md-7 col-12 mx-auto">
                                                      <div className="d-flex gap-3 flex-wrap justify-content-center ">
                                                            {links.map(({ title, link }, i) =>
                                                                  <Link to={link} key={i} className="light-text ">
                                                                        <small className="m-0 ">
                                                                              {title}
                                                                        </small>
                                                                  </Link>
                                                            )}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="col-md-6 mb-3 mt-5 mt-md-0">
                                          <form onSubmit={(e) => submitFn(e)}>
                                                <label htmlFor="subscribe" className="mb-2">
                                                      Subscribe to Newsletter
                                                </label>
                                                <div className="input-group">
                                                      <div className="col">
                                                            <input
                                                                  type="email"
                                                                  id="subscribe"
                                                                  name='email'
                                                                  placeholder="email address"
                                                                  onChange={(e) => setEmail(e.target.value)}
                                                                  value={email}
                                                                  required
                                                            />
                                                      </div>
                                                      <button
                                                            className="btn pry light-text py-2"
                                                            type="submit"
                                                            disabled={sending}
                                                      >
                                                            {sending ? 'sending' : 'Subscribe'}
                                                      </button>
                                                </div>
                                          </form>
                                    </div>
                              </div>

                        </div>
                  </div>
                  <h6 className=" text-center py-4 fw-bold">
                        2023 Reusautobestsales | All rights reserved
                  </h6>

            </StyledFooter>
      );
};

export default HomeFooter;

const StyledFooter = styled.footer`
      background-color: var(--light-bold-color);

      .footer {
            background-color: #393E46;

            .input-group {
                  display: flex;
                  align-items:center;
                  justify-content: space-between;
                  background-color: var(--light-color);
                  border-radius:8px;
                  padding-right:4px;
                  
                  input {
                        width: 100%;
                        padding: 12px 20px;
                        margin: 0;
                        display: inline-block;
                        border: none;
                        box-sizing: border-box;
                        font-size: 13px;
                        line-height: 24px;
                        color: var(--dark-color);
                        box-shadow: none;
                        margin-bottom: 0 !important;
                        background-color: none;
                        border-radius:8px;
                        
                        ::placeholder{
                              color: var(--grey-bold-color);
                        } 
                  }
                  button {
                  line-height: 1.5 !important;
            }
            }
      }
`;