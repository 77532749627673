import React, { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT, ICON_X } from '../../utils/icons';

const ImageCarousel = ({ images }) => {

      // For Carousel
      const [index, setIndex] = useState(0);

      const handleSelect = (selectedIndex) => {
            setIndex(selectedIndex);
      };

      useEffect(() => {
            const interval = setInterval(() => {
                  handleSelect((index + 1) % images?.length);
            }, 3000); // Auto slide every 3 seconds

            return () => clearInterval(interval);
      }, [index, images?.length]);
      // For Carousel

      // For LightBox

      const [isOpen, setIsOpen] = useState(false);
      const [selectedImageIndex, setSelectedImageIndex] = useState(0);

      const openLightbox = (index) => {
            setSelectedImageIndex(index);
            setIsOpen(true);
      };

      const closeLightbox = () => {
            setIsOpen(false);

      };

      const nextImage = () => {
            setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
      };

      const prevImage = () => {
            setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images?.length) % images.length);
      };

      // For LightBox


      return (
            <StyledImageCarousel>
                  <div className="lightbox-container">
                        <div className={`lightbox ripple ${ isOpen ? 'open' : 'closed' }`}>
                              <span className="lightbox-close" onClick={closeLightbox}>
                                    {ICON_X}
                              </span>
                              <div className="arrow light-bg">
                                    <button className="lightbox-prev" onClick={prevImage}>
                                          {ICON_ARROW_LEFT}
                                    </button>
                                    <button className="lightbox-next" onClick={nextImage}>
                                          {ICON_ARROW_RIGHT}
                                    </button>
                              </div>
                              <div className="image-container">
                                    <img
                                          src={images?.[selectedImageIndex]}
                                          alt={`Image ${ selectedImageIndex + 1 }`}
                                          className="lightbox-image"
                                    />
                              </div>
                        </div>
                  </div>
                  <Container className="mt-5">
                        <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} >
                              {images?.map((image, idx) => (
                                    <Carousel.Item key={idx}>
                                          <div className="main-image hover-zoom">
                                                <img
                                                      className="d-block w-100"
                                                      src={image}
                                                      alt={`Slide ${ idx }`}
                                                      onClick={() => openLightbox(index)}

                                                />
                                          </div>
                                    </Carousel.Item>
                              ))}
                        </Carousel>
                        <Row className="mt-3">

                              <Col>
                                    <div className="indicator-slide" >
                                          {images?.map((image, idx) => (
                                                <div key={idx} className="text-center col-3 p-2" style={{ transform: `translateX(-${ index * 100 }%)` }} >
                                                      <div
                                                            className={`carousel-indicator ${ idx === index ? 'active' : ''
                                                                  }`}
                                                      >
                                                            <img
                                                                  onClick={() => handleSelect(idx)}
                                                                  className="d-block w-100"
                                                                  src={image}
                                                                  alt="Car"
                                                                  width="100%"
                                                            />
                                                      </div>
                                                </div>
                                          ))}
                                    </div>
                              </Col>

                        </Row>
                  </Container>
            </StyledImageCarousel>
      );
};

export default ImageCarousel;


const StyledImageCarousel = styled.div`
      .main-image{
            width:auto;
            height:400px;
            border-radius: 10px;
            
            img {
                  width:100%;
                  height:100%;
                  margin:auto;
                  object-fit:contain;
                  border-radius: 10px;
            }
      }  
      
      .indicator-slide {
            background: none;
            ::-webkit-scrollbar {
            }
            ::-webkit-scrollbar-track {
                  border: none;
                  background: none;
            }
            ::-webkit-scrollbar-thumb {
                  background: none;
            }
            
            display: flex;
            gap: 2;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            
            > div {
                  scroll-snap-align: start;
            }
      }

.carousel-pointer {
                  cursor: pointer;
            }
            
            .carousel-indicator {
                  width:auto;
                  height:40px;
                  border-radius: 10px;
                  
                  img {
                        width:100%;
                        height:100%;
                        margin:auto;
                        object-fit:contain;
                        border-radius: 4px;
                        filter: grayscale(100%);
                        
                  }
            }

            .carousel-indicator.active img {
                  filter: grayscale(0%);
                  border: 2px var(--dark-color) solid;
}


/* LightBox */
/* Lightbox container */
.lightbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Lightbox */
  .lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Initial opacity */
    transition: opacity 0.3s ease-in-out; /* Add a transition for opacity */
  }

  .lightbox.open {
    display: flex;
    animation: zoomIn 0.3s forwards; 
  }
 

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }


 .image-container{
            width:auto;
            height:700px;
            border-radius: 10px;
            padding: 1rem;
            
            .lightbox-image {
                  width:100%;
                  height:100%;
                  margin:auto;
                  object-fit:contain;
                  border-radius: 10px !important;
            }
      } 



.lightbox-close
{
  position: absolute;
  top: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 5rem;
  width: 40px;
  height: 40px;
  text-align: center;
  
  :hover {
        background: #ffffff41;

  }
}
.arrow {
  position: absolute;
  bottom: 20px;
  width: 30%;
  display: flex;
  justify-content: space-between;
  border-radius: 5rem;
  padding: 5px 1rem;

  .lightbox-prev, .lightbox-next {

  color: #000;
  font-size: 30px;
  cursor: pointer;
  background: transparent;
  border: none;
  }
}

.lightbox-close {
  right: 20px;
}

.lightbox-next {
  right: 50px;
}

.lightbox-prev {
  left: 50px;
}

/* LightBox */

`;