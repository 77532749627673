import styled from "styled-components";
import Hero from "../../components/home/Hero";
import HomeSearch from "../../components/home/HomeSearch";
import PopularBrands from "../../components/home/PopularBrands";
import WhyAutoSale from "../../components/home/WhyAutoSale";
import Testimonials from "../../components/home/Testimonials";
import Vision from "../../components/home/Vision";
import HomeWrapper from "../../layout/HomeWrapper";
import NewCars from "../../components/home/NewCars";
import HomeAbout from "../../components/home/HomeAbout";



const Home = () => {

  return (
    <HomeWrapper>
      <StyledHome>
        <Hero />
        <HomeSearch />
        <PopularBrands />
        <NewCars />
        <WhyAutoSale />
        <Testimonials />
        < HomeAbout />
        <Vision />
      </StyledHome>
    </HomeWrapper>

  );
};

export default Home;

const StyledHome = styled.main`
background-color: var(--light-color);
color: var(--dark-color);
/* min-height: 100vh; */
align-items: center;
justify-content: center;
overflow-x: hidden !important;
 margin: 0 auto;
  padding: 0;
  /* height: 100vh; */
  width: 100%;
`;