import { Input, Label, Select } from "../../../styles";
import { naira } from "../../../utils/data";


const LoanFormProfession = ({ handleChange, formField, setvehiclePurpose }) => {
      return (
            <>
                  <div className="row">
                        <div className="col-md-6 text-start">
                              <Label htmlFor="profession" className="">
                                    Profession:
                              </Label>
                              <Input
                                    type="text"
                                    id="profession"
                                    name='profession'
                                    placeholder="Profession"
                                    onChange={handleChange}
                                    value={formField.profession}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="industry" className="">
                                    Industry:
                              </Label>
                              <Input
                                    type="text"
                                    id="industry"
                                    name='industry'
                                    placeholder="Industry"
                                    onChange={handleChange}
                                    value={formField.industry}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="nameOfBusiness" className="">
                                    Name of Business:
                              </Label>
                              <Input
                                    type="text"
                                    id="nameOfBusiness"
                                    name='nameOfBusiness'
                                    placeholder="Name of Business"
                                    onChange={handleChange}
                                    value={formField.nameOfBusiness}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="businessRegNumber" className="">
                                    Business Reg Number:
                              </Label>
                              <Input
                                    type="text"
                                    id="businessRegNumber"
                                    name='businessRegNumber'
                                    placeholder="FAT123334"
                                    onChange={handleChange}
                                    value={formField.businessRegNumber}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="businessType" className="">
                                    Business Type:
                              </Label>
                              <Input
                                    type="text"
                                    id="businessType"
                                    name='businessType'
                                    placeholder="(e.g. Corporation)"
                                    onChange={handleChange}
                                    value={formField.businessType}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="areYouADirector" className="">
                                    Are you a Director?
                              </Label>

                              <Select
                                    name="areYouADirector"
                                    id="areYouADirector"
                                    onChange={handleChange}
                                    value={formField.areYouADirector}
                              // required
                              >
                                    <option value="">Select</option>

                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                              </Select>
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="monthlyGrossIncomeBeforeDeduction" className="">
                                    Monthly Gross Income Before Deduction ({naira}):
                              </Label>
                              <Input
                                    type="number"
                                    id="monthlyGrossIncomeBeforeDeduction"
                                    name='monthlyGrossIncomeBeforeDeduction'
                                    placeholder=""
                                    onChange={handleChange}
                                    value={formField.monthlyGrossIncomeBeforeDeduction}
                              // required
                              />
                        </div>
                        <div className="col-md-6 text-start">
                              <Label htmlFor="businessMonthlyNetProfit" className="">
                                    Business Monthly Net Profit ({naira}):
                              </Label>
                              <Input
                                    type="number"
                                    id="businessMonthlyNetProfit"
                                    name='businessMonthlyNetProfit'
                                    placeholder=""
                                    onChange={handleChange}
                                    value={formField.businessMonthlyNetProfit}
                              // required
                              />
                        </div>


                        <div className="col-md-12 text-start">
                              <Label htmlFor="" className="">
                                    Vehicle Purpose:
                              </Label>
                              <div className="d-flex gap-5">
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="vehiclePurpose"
                                                id="Business"
                                                value="Business"
                                                onChange={(e) => setvehiclePurpose(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="Business">
                                                Business
                                          </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                          <input
                                                type="radio"
                                                className="custom-control-input me-2"
                                                name="vehiclePurpose"
                                                id="Personal"
                                                value="Personal"
                                                onChange={(e) => setvehiclePurpose(e.target.value)}
                                          />
                                          <label className="custom-control-label dark-color" for="Personal">
                                                Personal
                                          </label>
                                    </div>
                              </div>
                        </div>

                  </div>

            </>
      );
};

export default LoanFormProfession;