import HomeWrapper from "../../../layout/HomeWrapper";
import { Button, Form, Input, Label } from "../../../styles";
import { useEffect, useState } from "react";
import Api from "../../../api/axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { StyledAuth } from "../../../pages/auth/Register";
import { useCarContext } from "../../../context/car_context";
import BackButton from "../../BackButton";

import AppLoader from "../../../utils/AppLoader";
import SuccessApplication from "./SuccessApplication";
import BuyFormCar from "./BuyFormCar";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import SecureApi from "../../../api/secureApi";


const BuyForm = () => {

      const [formField, setFormField] = useState({});
      const [sending, setsending] = useState(false);
      const [accessToken] = useLocalStorage('accessToken');


      // Modal
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);;


      const params = useParams();
      const { id } = params;

      const {
            carInfo: { getCarDetails, carData, loading }
      } = useCarContext();

      useEffect(() => {
            getCarDetails(id);

      }, []);

      const handleChange = (e) => {
            setFormField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value
            }));
      };

      function submitFn(e) {
            e.preventDefault();
            if (
                  formField?.email &&
                  formField.phone &&
                  formField.name
            ) {
                  setsending(true);
                  const data = {
                        carId: id,
                        fullName: formField.name,
                        phone: formField.phone,
                        email: formField.email
                  };
                  SecureApi.post(`/payment/cash`, data)
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    handleShow();
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      return (
            <HomeWrapper>
                  <AppLoader
                        load={loading}
                  />
                  <div className="">
                        <StyledAuth>
                              <div className="container">
                                    <div className="light-bg py-5 shadow br-md">
                                          <div className="col-md-10 col-lg-10 mx-auto container">
                                                <BackButton />

                                                <div className="row">
                                                      <div className="col-lg-5 mb-5">
                                                            <h5 className="text-center">
                                                                  You have selected this Car
                                                            </h5>
                                                            <BuyFormCar {...carData} />
                                                            <div className="pt-2 d-block d-md-none">
                                                                  <hr />
                                                            </div>
                                                      </div>
                                                      <div className="col-lg-7">
                                                            <h5 className="mb-4 text-center ">
                                                                  Please fill in your contact details
                                                            </h5>
                                                            <Form onSubmit={(e) => submitFn(e)} >
                                                                  <div className="col-md-12 text-start">
                                                                        <Label htmlFor="name" className="">
                                                                              Full Name
                                                                        </Label>
                                                                        <Input
                                                                              type="text"
                                                                              id="name"
                                                                              name='name'
                                                                              placeholder="Full Name"
                                                                              onChange={handleChange}
                                                                              value={formField.name}
                                                                              required
                                                                        />
                                                                  </div>
                                                                  <div className="col-md-12 text-start">
                                                                        <Label htmlFor="email" className="">
                                                                              Email Address
                                                                        </Label>
                                                                        <Input
                                                                              type="email"
                                                                              id="email"
                                                                              name='email'
                                                                              placeholder="Enter e-mail address"
                                                                              onChange={handleChange}
                                                                              value={formField.email}
                                                                              required
                                                                        />
                                                                  </div>
                                                                  <div className="col-md-12 text-start">
                                                                        <Label htmlFor="phone" className="">
                                                                              Phone Number
                                                                        </Label>
                                                                        <Input
                                                                              type="number"
                                                                              id="phone"
                                                                              name='phone'
                                                                              placeholder="08012345678"
                                                                              onChange={handleChange}
                                                                              value={formField.phone}
                                                                              required
                                                                        />
                                                                  </div>


                                                                  <Button
                                                                        sec
                                                                        type="submit"
                                                                        disabled={sending}
                                                                        className="btn m-0 w-100"  >
                                                                        {sending ? 'loading...' : "Submit"}
                                                                  </Button>
                                                            </Form>
                                                      </div>

                                                </div>



                                          </div>
                                    </div>
                              </div>

                        </StyledAuth>
                  </div>
                  <SuccessApplication
                        show={show}
                        onHide={handleClose}
                  />
            </HomeWrapper>
      );
};

export default BuyForm;
