import { useNavigate } from "react-router-dom";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import FetchApi from "../../../api/getAxios";



const Navbar = ({ pagename }) => {

  // const { accessToken } = useUserContext();
  const [accessToken] = useLocalStorage('accessToken');
  const [userData, setuserData] = useState('');
  const navigate = useNavigate();


  function getUserData() {
    if (accessToken) {
      FetchApi.get('users/auth/profile', {
        headers: {
          'Authorization': `Bearer ${ accessToken }`
        }
      })
        .then((res) => {
          setuserData(res?.data.userInformation);
          localStorage.setItem('userdata', JSON.stringify(res?.data.userInformation));
        })
        .catch((error) => {
        });
    }
  }
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="d-flex align-items-center">
      {/* <TopNav
        pagename={pagename}
      /> */}
      <SideNav />
    </div>
  );
};

export default Navbar;
