import { naira } from "../../../utils/data";
import { ICON_HEART, ICON_HEART_FILL, ICON_STAR } from "../../../utils/icons";
import { th } from "../../../utils/helpers";
import { StyledCarItem } from "../CarItem";
import { car2, placeholder1 } from "../../../assets";


const BuyFormCar = ({ name, mileage, engineType, amount, _id, images }) => {

      const carImg = images[0] || car2;

      return (
            <StyledCarItem className="py-2 br-sm">
                  <div className="bg-image">
                        <div className="fav pointer">
                              {ICON_HEART_FILL}
                              {/* {fav === '0' ? ICON_HEART : ICON_HEART_FILL} */}
                        </div>
                        <img src={carImg || placeholder1} alt={name} />
                  </div>
                  <div className="pt-4 px-2">
                        <div className="pb-3">
                              <div className="d-flex justify-content-between flex-wrap">
                                    <div className="col mt-2">
                                          <p className="small fw-bold text-break car-name">
                                                {name}
                                          </p>
                                    </div>
                                    <div className=" mt-2">
                                          <p className="year fit-content ms-auto ">
                                                2003
                                          </p>
                                    </div>
                              </div>
                              <div className="d-flex justify-content-start mb-3 flex-wrap" >
                                    <div className="mt-2 me-2">
                                          <p className="m-0 p-0">
                                                4.0
                                                <span className="sec-text ms-1 small">
                                                      {ICON_STAR}
                                                </span>
                                          </p>
                                    </div>
                                    <div className="mt-2">
                                          <p className="milage grey-bold-bg light-text me-1">
                                                {engineType}
                                          </p>
                                    </div>
                                    <div className="mt-2">
                                          <p className="milage grey-bold-bg light-text">
                                                {th(mileage)} MILES
                                          </p>
                                    </div>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between mb-3">
                                    <p className="price">
                                          {naira}{th(amount)}
                                    </p>
                                    <div className="">
                                          <p className="price fw-bold">
                                                {naira}{th(120000)}/Mo
                                          </p>
                                          <p className="small grey-bold-text">
                                                20% Down Payment
                                          </p>
                                    </div>
                              </div>
                        </div>
                  </div>

            </StyledCarItem>
      );
};

export default BuyFormCar;
