import HomeWrapper from "../../layout/HomeWrapper";
import { facebook, google } from "../../assets";
import { Button, Form, Input, Label } from "../../styles";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../api/axios";
import { toast } from "react-toastify";
import { StyledAuth } from "./Register";
import { useLocalStorage } from "../../utils/useLocalStorage";

const Login = () => {

      const [formField, setFormField] = useState({});
      const navigate = useNavigate();
      const [sending, setsending] = useState(false);
      const [accessToken, setaccessToken] = useLocalStorage('accessToken');


      const handleChange = (e) => {
            setFormField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value
            }));
      };


      function submitFn(e) {
            e.preventDefault();

            if (
                  formField?.email &&
                  formField.password
            ) {
                  setsending(true);
                  const data = {
                        email: formField?.email,
                        password: formField.password,
                  };
                  Api.post(`/users/auth/signIn`, data)
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setaccessToken(res.data.accessToken);
                                    setsending(false);
                                    // console.log(res.data);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    // getUserData();
                                    navigate(
                                          "/"
                                    );
                                    setTimeout(() => {
                                          window.location.reload();
                                    }, 50);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }

      }

      return (
            <HomeWrapper>
                  <StyledAuth>
                        <div className="container">

                              <div className="light-bg py-5 shadow br-md">
                                    <div className="col-md-8 col-lg-6 mx-auto text-center container">
                                          <h5 className="mb-4">
                                                Login
                                          </h5>
                                          <small className="">
                                                Use Social Login
                                          </small>
                                          <div className="d-flex justify-content-center gap-2 my-2">
                                                <div className="col text-end">

                                                      <img src={facebook} alt="Facebook Login" />
                                                </div>
                                                <div className="col text-start ">
                                                      <img src={google} alt="Facebook Login" />
                                                </div>
                                          </div>
                                          <div className="pt-2">
                                                <hr />
                                                <p className="small or fit-content mx-auto"> or </p>
                                          </div>

                                          <Form onSubmit={(e) => submitFn(e)} >
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="email" className="">
                                                            Email Address
                                                      </Label>
                                                      <Input
                                                            type="email"
                                                            id='email'
                                                            name='email'
                                                            placeholder="Enter e-mail address"
                                                            onChange={handleChange}
                                                            value={formField.email}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="password" className="">
                                                            Password
                                                      </Label>
                                                      <Input
                                                            type="password"
                                                            id='password'
                                                            name='password'
                                                            placeholder="************"
                                                            onChange={handleChange}
                                                            value={formField.password}
                                                            required
                                                      />
                                                </div>

                                                <div className="d-flex justify-content-between">
                                                      <div className="d-flex">
                                                            <div className="col">
                                                                  <Input
                                                                        type="checkbox"
                                                                        name='email'
                                                                        placeholder="Enter e-mail address"
                                                                  // onChange={}
                                                                  // value={}
                                                                  />
                                                            </div>
                                                            <div className="col">
                                                                  <label className="remember" htmlFor="remember">
                                                                        Remember me
                                                                  </label>
                                                            </div>
                                                      </div>
                                                      <Link to='/user/forget-password'>
                                                            <p className="">
                                                                  Forget Password?
                                                            </p>
                                                      </Link>
                                                </div>
                                                <Button
                                                      pry
                                                      type="submit"
                                                      disabled={sending}
                                                      className="btn m-0 w-100"  >
                                                      {sending ? 'loading...' : "Login"}
                                                </Button>
                                          </Form>

                                          <h6 className="mt-4">
                                                New to AutoBest Sales? {' '}
                                                <Link to='/user/register'>
                                                      <span className="fw-bold">
                                                            Create an Account
                                                      </span>
                                                </Link>
                                          </h6>
                                    </div>
                              </div>
                        </div>

                  </StyledAuth>
            </HomeWrapper>
      );
};

export default Login;
