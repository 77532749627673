import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import { facebook, google } from "../../assets";
import { Button, Form, Input, Label } from "../../styles";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../api/axios";
import { toast } from "react-toastify";

const Register = () => {


      const [formField, setFormField] = useState({});
      const navigate = useNavigate();
      const [sending, setsending] = useState(false);

      const handleChange = (e) => {
            setFormField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value
            }));
      };

      function submitFn(e) {
            e.preventDefault();

            if (formField.password !== formField.cpassword) {
                  toast.warn("Confirm password does not match password");
                  return;
            }
            if (
                  formField?.email &&
                  formField.fname &&
                  formField.lname &&
                  formField.password &&
                  formField.phone
            ) {
                  setsending(true);
                  const data = {
                        email: formField?.email,
                        firstName: formField.fname,
                        lastName: formField.lname,
                        phone: formField.phone,
                        password: formField.password,
                  };
                  Api.post(`/users/auth/signup`, data)
                        .then((res) => {
                              console.log(res);
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    toast.success(res.message);
                                    setsending(false);
                                    navigate(
                                          "/user/login",
                                          // { state: formField?.email }
                                    );
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }

      }

      return (
            <HomeWrapper>
                  <StyledAuth>
                        <div className="container">

                              <div className="light-bg py-5 shadow br-md">
                                    <div className="col-md-8 col-lg-6 mx-auto text-center container">
                                          <h5 className="mb-4">
                                                Create Account
                                          </h5>
                                          <small>
                                                Use Social Register
                                          </small>
                                          <div className="d-flex justify-content-center gap-2 my-2">
                                                <div className="col text-end">

                                                      <img src={facebook} alt="Facebook Register" />
                                                </div>
                                                <div className="col text-start ">
                                                      <img src={google} alt="Facebook Register" />
                                                </div>
                                          </div>
                                          <div className="pt-2">
                                                <hr />
                                                <p className="small or fit-content mx-auto"> or </p>
                                          </div>

                                          <Form onSubmit={(e) => submitFn(e)} >
                                                <div className="row">
                                                      <div className="col-md-6 text-start">
                                                            <Label htmlFor="fname">
                                                                  First Name
                                                            </Label>
                                                            <Input
                                                                  type="text"
                                                                  id='fname'
                                                                  name='fname'
                                                                  // placeholder="Enter e-mail address"
                                                                  onChange={handleChange}
                                                                  value={formField.fname}
                                                                  required
                                                            />
                                                      </div>
                                                      <div className="col-md-6 text-start">
                                                            <Label htmlFor="lname">
                                                                  Last Name
                                                            </Label>
                                                            <Input
                                                                  type="text"
                                                                  id='lname'
                                                                  name='lname'
                                                                  // placeholder="Enter e-mail address"
                                                                  onChange={handleChange}
                                                                  value={formField.lname}
                                                                  required
                                                            />
                                                      </div>
                                                </div>
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="email">
                                                            Email Address
                                                      </Label>
                                                      <Input
                                                            type="email"
                                                            id='email'
                                                            name='email'
                                                            placeholder="Enter e-mail address"
                                                            onChange={handleChange}
                                                            value={formField.email}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="phone">
                                                            Phone Number
                                                      </Label>
                                                      <Input
                                                            type="phone"
                                                            id='phone'
                                                            name='phone'
                                                            placeholder="08012345678"
                                                            onChange={handleChange}
                                                            value={formField.phone}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="password">
                                                            Password
                                                      </Label>
                                                      <Input
                                                            type="password"
                                                            id='password'
                                                            name='password'
                                                            placeholder="************"
                                                            onChange={handleChange}
                                                            value={formField.password}
                                                            required
                                                      />
                                                </div>
                                                <div className="col-md-12 text-start">
                                                      <Label htmlFor="cpassword">
                                                            Confirm Password
                                                      </Label>
                                                      <Input
                                                            type="password"
                                                            id='cpassword'
                                                            name='cpassword'
                                                            placeholder="************"
                                                            onChange={handleChange}
                                                            value={formField.cpassword}
                                                            required
                                                      />
                                                </div>

                                                <Button
                                                      pry
                                                      type="submit"
                                                      disabled={sending}
                                                      className="btn m-0 w-100"  >
                                                      {sending ? 'loading...' : "Get Started"}
                                                </Button>
                                          </Form>

                                          <h6 className="mt-4">
                                                Have an Account? {' '}
                                                <Link to='/user/login'>
                                                      <span className="fw-bold">
                                                            Login
                                                      </span>
                                                </Link>
                                          </h6>
                                    </div>
                              </div>
                        </div>

                  </StyledAuth>
            </HomeWrapper>
      );
};

export default Register;

export const StyledAuth = styled.main`
      background-color: var(--pry-light-color);
      padding-top: 4rem ;
      padding-bottom: 4rem ;
      min-height: 100vh;

img {

      width: 200px;
@media screen and (max-width: 580px) {
      width: 100%;
}

}

.or {
       position: absolute;
      margin-top:-2.5rem;
      right: 0;
      left: 0;
      background-color: var(--light-color);
      padding: 10px 15px;
      border-radius:50%;
}

.remember {
      white-space: nowrap;
      margin-left: 5px;
      margin-top: 2px;
}

label { 
      font-size: 15px;
      color: var(--grey-bold-color);
}

`;