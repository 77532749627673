import React from 'react';
import styled from 'styled-components';
import { car3 } from '../../../assets';
import { ICON_PLUS_LG, ICON_STAR } from '../../../utils/icons';
import { th } from '../../../utils/helpers';
import { Link } from 'react-router-dom';


const CarHeader = () => {
      return (
            <StyledCarHeader>
                  <div className="row mb-4">
                        <div className="col-md-4 mb-2">
                              <Link to='add'>

                                    <div className="light-bg py-3 px-2 br-sm cards">
                                          <p className="fw-bold dark-text">
                                                Add new car
                                          </p>
                                          <h1 className="text-success text-center m-0"
                                                style={{ fontSize: '7rem' }}
                                          >
                                                {ICON_PLUS_LG}
                                          </h1>
                                    </div>
                              </Link>
                        </div>
                        <div className="col-md-4 mb-2">
                              <div className="light-bg py-3 px-2 br-sm cards">
                                    <p className="fw-bold">
                                          Latest Upload
                                    </p>
                                    <div className="">
                                          <div className="d-flex grey-bold-text align-items-center justify-content-between">
                                                <div className="col-4">
                                                      <img src={car3} alt="BMW" width='100%' />
                                                </div>
                                                <div className="col-7">
                                                      <p className='fw-bold'>
                                                            Mercedes-Benz
                                                            GLE 350
                                                      </p>
                                                      <p className="border p-1 m-0  pb-0 border-dark fit-content"> 2018 </p>
                                                </div>
                                          </div>
                                          <div className="d-flex justify-content-start mb-3 flex-wrap" >
                                                <div className="mt-2 me-2">
                                                      <p className="m-0 p-0">
                                                            4.0
                                                            <span className="sec-text ms-1 small">
                                                                  {ICON_STAR}
                                                            </span>
                                                      </p>
                                                </div>
                                                <div className="mt-2">
                                                      <p className="milage grey-bold-bg light-text me-1">
                                                            {/* {engine?.map((item, i) => <span key={i}>{item}{' '} </span>)} */}
                                                            Lorem, ipsum.
                                                      </p>
                                                </div>
                                                <div className="mt-2">
                                                      <p className="milage grey-bold-bg light-text">
                                                            {/* {th(mileage)} MILES */}
                                                            {th(400000)} MILES
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className="col-md-4 mb-2">
                              <div className="light-bg  py-3 px-2 br-sm cards">
                                    <p className="fw-bold">
                                          Monthly Sales
                                    </p>


                              </div>
                        </div>
                  </div>

            </StyledCarHeader>
      );
};

export default CarHeader;

const StyledCarHeader = styled.div`

.cards {
height: 250px !important;
}


 .milage {
          margin: 0;
          padding: 5px 10px 0px;
          border-radius: 2px;
          text-align: center;
          white-space: nowrap;
          font-size: 8px;
          text-transform: uppercase;
}
`;