import ProtectUser from '../app/protectUser';
import Navbar from '../components/user/userNav/Navbar';
import { DashboardWrapper } from '../styles';
import HomeNavbar from './HomeNavbar';

const UserWrapper = ({ children }) => {

    return (
        <main>
            <ProtectUser>
                <HomeNavbar />
                <div className="light-bold-bg">
                    <div className="dark-text mx-auto">
                        <Navbar
                        />
                        <DashboardWrapper>
                            <div>
                                {children}
                            </div>
                        </DashboardWrapper>

                    </div>

                </div>
            </ProtectUser>
        </main>
    );
};

export default UserWrapper;