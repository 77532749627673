
import styled from 'styled-components';
import { ICON_SEARCH } from './icons';

const EmptyData = ({ data, title }) => {
    return (
        <>
            {!data?.length &&
                < StyledEmptyData >
                    {ICON_SEARCH}
                    <p className="">
                        You don’t have {title} yet
                    </p>
                </StyledEmptyData>
                //  : null
            }
        </>
    );
};

export default EmptyData;

const StyledEmptyData = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  justify-content: center;
  align-items:center;
  min-height:40vh;

  svg {
    font-size:50px;
  }
`;