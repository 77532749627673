import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Input, Label } from "../../../styles";
import BackButton from '../../BackButton';
import AdminWrapper from '../../../layout/AdminWrapper';
import SecureApi from '../../../adminApi/secureApi';
import { useAdminContext } from '../../../context/admin_context';
import MiniLoader from '../../../utils/MIniLoader';
import { useEffect } from 'react';

const EditDealer = () => {


      const {
            dealers: { loadDealers, allDealers, getDealers },
      } = useAdminContext();

      const params = useParams();
      const { id } = params;



      const dealer = allDealers?.find((e) => e._id === id) || {
            companyName: '', address: '', contactPersonName: '', email: '', location: '', whatsAppNumber: '', phoneNumber: ''
      };
      const {
            companyName, address, contactPersonName, email, location, whatsAppNumber, phoneNumber
      } = dealer;


      useEffect(() => {
            getDealers();
      }, []);

      const [formField, setformField] = useState({
            companyName, address, contactPersonName, email, location, whatsAppNumber, phoneNumber
      });
      const [sending, setsending] = useState(false);

      // Form Fields
      function handleChange(e) {
            if (e.target.type === "file") {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field

      function submitFn(e) {
            e.preventDefault();
            if (
                  formField.companyName
            ) {
                  setsending(true);
                  const data = {
                        companyName: formField.companyName,
                        contactPersonName: formField.contactPersonName,
                        whatsAppNumber: formField.whatsAppNumber,
                        phoneNumber: formField.phoneNumber,
                        email: formField.email,
                        location: formField.location,
                        address: formField.address,
                  };

                  // 
                  SecureApi.put(`/admin/dealers/${ id }`, data)
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    // navigate(`/admin/dealers`);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      return (
            <>
                  <AdminWrapper>
                        <div className="light-bg br-sm py-3 px-3">
                              <h5 className="m-0">
                                    <span className="grey-bold-text">
                                          Admin /
                                    </span> Edit Dealer
                                    <MiniLoader
                                          load={loadDealers}
                                    />
                              </h5>
                        </div>

                        <div className="light-bg my-2 container py-4 br-sm">
                              <BackButton />

                              <Form onSubmit={(e) => submitFn(e)} className="mt-4">
                                    <div className="row">
                                          <div className="col-md-6">
                                                <Label htmlFor="companyName">
                                                      Company Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="companyName"
                                                      id="companyName"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.companyName}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="email">
                                                      Email Address:
                                                </Label>
                                                <Input
                                                      type="email"
                                                      name="email"
                                                      id="email"
                                                      onChange={handleChange}
                                                      value={formField.email}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="phoneNumber">
                                                      Phone Number :
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="phoneNumber"
                                                      id="phoneNumber"
                                                      onChange={handleChange}
                                                      value={formField.phoneNumber}
                                                      required
                                                />

                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="whatsAppNumber">
                                                      Whatsapp Number :
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="whatsAppNumber"
                                                      id="whatsAppNumber"
                                                      onChange={handleChange}
                                                      value={formField.whatsAppNumber}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="contactPersonName">
                                                      Contact Person Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="contactPersonName"
                                                      id="contactPersonName"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.contactPersonName}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="location">
                                                      Location:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="location"
                                                      id="location"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.location}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="address">
                                                      Address:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="address"
                                                      id="address"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.address}
                                                      required
                                                />
                                          </div>


                                          <div className="col-12 text-center">
                                                <Button
                                                      sec
                                                      className="btn"
                                                      type="submit"
                                                      disabled={sending}
                                                >
                                                      {sending ? 'loading...' : 'Submit'}
                                                </Button>
                                          </div>

                                    </div>

                              </Form>
                        </div>
                  </AdminWrapper>



            </>
      );
};

export default EditDealer;