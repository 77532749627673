import Styled from 'styled-components';


export const DashboardWrapper = Styled.section`
  min-height: 100vh;
padding-right: 170px;
padding-left: 420px !important;
  margin-top: 6rem !important;
  padding-bottom: 2rem !important;

  .admin-container{
    padding-top: 4.2rem !important;
  }


  @media screen and (max-width: 992px) {
    /* padding-top: 0rem !important; */
    padding-bottom: 7rem !important;
    padding-left: 266px !important;
    padding-right: 1rem !important;
  }
  @media screen and (max-width: 580px) {
    /* padding-top: 0rem !important; */
    padding-bottom: 7rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

`;

export const Button = Styled.button`
margin: 0 !important;
text-transform: capitalize;
font-size: 16px;
font-family: Manjari;
 background: ${ (props) => (
    props.pry ? 'var(--pry-color)'
      : props.sec ? 'var(--sec-color)'
        : props.light ? 'var(--light-color)'
          : props.dark ? 'var(--dark-bold-color)'

            : '') };
color: ${ (props) => (props.pry ? 'var(--light-color)'
    : props.sec ? 'var(--light-color)'
      : props.light ? 'var(--pry-color)'
        : props.dark ? 'var(--light-color)'
          : '') };

:hover, :focus{
color: ${ (props) => (props.pry ? 'var(--light-color)'
    : props.sec ? 'var(--light-color)'
      : props.light ? 'var(--pry-color)'
        : props.dark ? 'var(--light-color)'
          : '') };
}
`;

export const Form = Styled.form`

.toggle-eye {
  position: absolute;
 right: 5%;
  top: 5%;
  cursor: pointer;
  font-weight: bolder;
  font-size: 2rem;
}

.toggle-eye svg {
    font-size: 22px !important;
    color: var(--grey-bold-color);
}


/* Focus and Out focus*/
.css-t3ipsp-control, .css-13cymwt-control {
  width: 100%;
  padding: 7px 10px;
  margin: 8px 0;
  border: 1px solid var(--grey-bold-color);
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 13px;
  line-height: 24px;
  color: var(--dark-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;

  :hover,:focus{
      border: 1px solid var(--pry-color) !important;
    }
    ::placeholder{
        color: var(--grey-bold-color);
    }  
}

{
  color: var(--dark-color) !important;
}
/* select field */
.css-1nmdiq5-menu{
  background-color: var(--light-color) !important;
}


/* Disabled */
.css-16xfy0z-control {
  width: 100%;
  padding: 7px 10px;
  margin: 8px 0;
  border: 1px solid var(--grey-bold-color);
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 13px;
  line-height: 24px;
  color: var(--dark-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;
  background-color: var(--grey-color);

  :hover,:focus{
      border: 1px solid var(--pry-color) !important;
    }
    
    ::placeholder{
        color: var(--grey-bold-color);
    } 
}
`;

export const Input = Styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--grey-bold-color);
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 13px;
  line-height: 24px;
  color: var(--dark-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;
  background-color: var(--light-color);
  
  :hover,:focus{
      border: 1px solid var(--pry-color) !important;
    }
    
    ::placeholder{
        color: var(--grey-bold-color);
    } 
    :disabled{
        background-color: var(--light-bold-color);
    } 
    
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      appearance: none !important;
}
`;
export const Select = Styled.select`
  width: 100%;
  padding: 12px 20px 12px 10px ;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--grey-bold-color);
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-bold-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;
  background-color: var(--light-color);

    -webkit-appearance: none !important;
    appearance: none !important;
  
  
  :hover,:focus{
      border: 1px solid var(--pry-color) !important;
    }
    
    ::placeholder{
        color: var(--grey-bold-color);
    } 
    
    :disabled{
        background-color: var(--light-bold-color);
    } 
`;

export const Textarea = Styled.textarea`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--grey-bold-color);
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 13px;
  line-height: 24px;
  color: var(--dark-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;
  
  :hover,:focus{
      border: 1px solid var(--pry-color) !important;
    }
    
    ::placeholder{
        color: var(--grey-bold-color);
    } 
  
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
}
`;

export const Label = Styled.label`
margin:0;
color: var(--dark-color);
font-size: 16px; 
`;



export const PasswordInput = Styled(Input)`
padding: 12px 40px 12px 20px; 

`;

export const Progress = Styled.progress`
  
  width: 100%;
  height: 10px;
  border: none;
  appearance: none;
  background-color: #767676;
  border-radius: 10px;


  ::-webkit-progress-bar {
    background-color: #767676;
    border-radius: 10px;
  }
  
  ::-webkit-progress-value {
    background-color: #CBFFAF; /* Change this to your desired fill color */
    border-radius: 10px;
  }

`;

export const StyledTab = Styled.div`
  background: var(--grey-color);
  display: flex;
  gap: 12px ;
  border-radius: 5px;
  padding: .4rem;
  width: fit-content;
  /* margin-left: auto ; */
  
  
  .links {
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
    border-radius: 3px;
    padding:0 .8rem;
    font-size: 14px;
    text-transform:capitalize;
    cursor:pointer;
  }
  .links.active{
    background: var(--light-color);
  }
  `;


export const ErrorPageMain = Styled.main`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    color: var(--dark-color);
  `;
export const StyledEmptyData = Styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  justify-content: center;
  align-items:center;
  min-height:40vh;

  svg {
    font-size:50px;
  }
`;

export const StyledGlide = Styled.div`
  .gliding {
  margin-top: 120px;
}

.glide-flex {
  display: flex;
}

._10XKC {
  background-color: #eeeeeeaa !important;
}
._10XKC:hover {
  background-color: #EEE !important;
}

.glide-title::after {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 4px;
  height: 4px;
  width: 60%;
  background: #ffcfcf;
}

.glide__arrow {
  background: #fff;
  color: #000;
}
`;

export const StyledImgUpload = Styled.div`
        position: relative;

        input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
        
        img {
            width: 100%;
        }
    
        .cancel {
          position:absolute;
          top:0;
          right: 0;
    
          svg {
                font-size: 25px;
                background-color: #ffffff90;
                border-radius: 50%;
                margin:.3rem;
          }
        }
  
        .cover-container{
              position: relative;
              width:100%;
              height:300px;
              img.cover {
                    border-radius: 1rem;
                    width: 100%;
                    height:100%;
                    margin:auto;
                    object-fit:contain;
              }
        }
`;

