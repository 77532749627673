import React, { useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { ICON_ARROW_UP_RIGHT, ICON_DELETE, ICON_PEN } from "../../../utils/icons";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAdminContext } from "../../../context/admin_context";
import AppLoader from "../../../utils/AppLoader";

export default function AllDealers() {

      const {
            dealers: { loadDealers, allDealers, getDealers, deleteDealer, removing },
      } = useAdminContext();

      useEffect(() => {
            const dealer = allDealers?.map(({ _id, phoneNumber, email, whatsAppNumber, contactPersonName, companyName, location, address }, i) => {

                  return (
                        {
                              id: (i + 1),
                              edit: <Link to={`/admin/dealers/${ _id}`} className="text-center pry-text h4"> {ICON_PEN}</Link>,
                              delete: <a className="text-center text-danger h2"
                                    onClick={() => deleteDealer(_id, companyName)}
                              > {ICON_DELETE}</a>,
                              phoneNumber: phoneNumber,
                              whatsappNumber: whatsAppNumber,
                              email: email,
                              companyName: companyName,
                              contactPersonName: contactPersonName,
                              address: address,
                              location: location,

                        }
                  );
            });

            setDatatable({
                  ...datatable,
                  rows: dealer
            });
      }, [allDealers]);

      useEffect(() => {
            getDealers();
      }, []);


      const [datatable, setDatatable] = React.useState({
            columns: [
                  {
                        label: "ID",
                        field: "id",
                        width: 150,
                        attributes: {
                              "aria-controls": "DataTable",
                              "aria-label": "Id",
                        },
                  },
                  {
                        label: "Company Name ",
                        field: "companyName",
                        width: 200,
                  },
                  {
                        label: "Phone Number",
                        field: "phoneNumber",
                        width: 200,
                  },
                  {
                        label: "Whatsapp Number",
                        field: "whatsappNumber",
                        width: 270,
                  },
                  {
                        label: "Email ",
                        field: "email",
                        width: 200,
                  },
                  {
                        label: "Contact Person Name ",
                        field: "contactPersonName",
                        width: 200,
                  },
                  {
                        label: "Location ",
                        field: "location",
                        width: 200,
                  },
                  {
                        label: "Address ",
                        field: "address",
                        width: 200,
                  },
                  {
                        label: "Edit",
                        field: "edit",
                        width: 50,
                  },
                  {
                        label: "Delete",
                        field: "delete",
                        width: 50,
                  },

            ],
            rows: []
      });


      return (
            <>
                  <AppLoader
                        load={removing}
                  />
                  <div className="light-bg d-flex justify-content-between align-items-center br-sm py-1 px-2">
                        <div className="d-flex">
                              <h5 className="m-0 pt-2">
                                    All Dealers
                              </h5>
                              <ClipLoader
                                    loading={loadDealers}
                                    size='20'
                                    color={'var(--dark-color)'}
                                    className='ms-3 mt-2'
                              />
                        </div>
                        <h1 className="dark-text m-0">
                              {ICON_ARROW_UP_RIGHT}
                        </h1>
                  </div>
                  <div className="mt-3 br-md">

                        <MDBDataTableV5
                              hover
                              fullPagination
                              entries={50}
                              pagesAmount={4}
                              data={datatable}
                              pagingTop
                              searchTop
                              responsive
                              // theadColor="sec-bold pry-text"
                              maxHeight="700px"
                              // searchBottom={false}
                              className="light-bg br-sm pt-2"
                        />
                  </div>
            </>

      );
}
