import styled from "styled-components";
 import DashboardHeader from "../../components/user/dashboard/DashboardHeader";
import UserWrapper from "../../layout/UserWrapper";
import NewCars from "../../components/home/NewCars";

const UserDashboard = () => {
      return (
            <UserWrapper>
                  <StyledUserDashboard>
                        <div className="light-bg py-4 container-fluid br-sm min-height-lg">
                              <DashboardHeader />
                              <NewCars />
                        </div>
                  </StyledUserDashboard>
            </UserWrapper>
      );
};

export default UserDashboard;

const StyledUserDashboard = styled.main`
      padding-bottom: 2rem;
`;