import React from 'react';
import { ClipLoader, ClockLoader } from 'react-spinners';
import styled from 'styled-components';

const AppLoader = (props) => {


  return (
    <>
      {props.load &&
        <StyledLoader>
          <main>
            <ClockLoader color={"var(--sec-color)"} size="100" speedMultiplier="1.2" />
            <h4 className=" light-text mt-4">
              Loading...
            </h4>
          </main>
        </StyledLoader>
      }
    </>
  );
};

export default AppLoader;




const StyledLoader = styled.div`
   width: 100%;
  height: 100vh;
  background: var(--loader-bg);
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000 !important;
`;