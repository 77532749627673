import styled from "styled-components";
import AdminWrapper from "../../layout/AdminWrapper";
import CarHeader from "../../components/admin/cars/CarHeader";
import FeaturedCars from "../../components/admin/cars/FeaturedCars";


const AdminCar = () => {
      return (
            <AdminWrapper>
                  <StyledAdminCar>
                        <CarHeader />
                        <FeaturedCars />
                  </StyledAdminCar>
            </AdminWrapper>
      );
};

export default AdminCar;

const StyledAdminCar = styled.main`
      padding-bottom: 2rem;
`;