import { useEffect } from "react";
import UserWrapper from "../../../layout/UserWrapper";
import MiniLoader from "../../../utils/MIniLoader";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import BackButton from "../../BackButton";
import secureGetApi from "../../../api/secureGetApi";



const LoanReview = () => {

      const params = useParams();
      const { id } = params;

      const [loading, setLoading] = useState(false);
      const [loanData, setLoanData] = useState('');

      function getLoanDetails() {
            // setCarData({ images: [placeholder1] });
            setLoading(true);
            secureGetApi.get(`/loan/apply/${ id }`)
                  .then((res) => {
                        if (res.status === false) {
                              setLoading(false);
                        } else {
                              setLoading(false);
                              setLoanData(res.data);
                        }
                  })
                  .catch((error) => {
                        setLoading(false);

                  });

      }

      useEffect(() => {
            getLoanDetails();
      }, []);

      console.log(loanData);

      const {
            firstName,
            lastName,
            phone,
            email,
            dob,
            nin,
            inquiry,
            bvn,
            gender,
            nationality,
            nationalStatus,
            profession,
            industry,
            nameOfBusiness,
            businessRegNumber,
            businessType,
            areYouADirector,
            monthlyGrossIncomeBeforeDeduction,
            businessMonthlyNetProfit,
            desiredEquityContribution,
            desiredMonthlyPayment,
            desiredInterateRate,
            desiredResidual,
            desiredLoanTerms,
            desiredRepaymentDate,
            paymentItems,
            firstLoanYear, maintenancePlanSubscription,
            subscribeRoadWorthiness, paymentFeesUpfront,
            consent, termsAndCondition
      } = loanData;

      return (
            <UserWrapper>
                  <StyledLoanReview className="light-bg py-4 container-fluid br-sm min-height-lg">
                        <BackButton />
                        <div className="d-flex mt-4">
                              <h5>
                                    Loan
                              </h5>
                              <MiniLoader
                                    load={loading}
                              />
                        </div>

                        <p className="fw-bold mt-4">
                              <span className="grey-bold-text">
                                    Loan status /
                              </span> Application Review
                        </p>
                        <hr />

                        <div className="row">
                              <div className="col-md-6 mb-4">
                                    <div className="br-sm border py-5">
                                          <div className="p-2">
                                                <div className="profile-img">
                                                      <img src={loanData?.image} alt={loanData?.carName} width='100%' className="br-sm" />
                                                </div>
                                                <h5 className="mt-3">
                                                      {loanData?.carName}
                                                </h5>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-6  mb-4">
                                    <div className="br-sm border py-1 px-1">
                                          <div className="group">
                                                <p className="">
                                                      Name: {firstName} {lastName}
                                                </p>
                                                <p className="">
                                                      Phone: {phone}
                                                </p>
                                                <p className="">
                                                      Email: {email}
                                                </p>
                                                <p className="">
                                                      Date of Birth: {dob}
                                                </p>
                                                <p className="">
                                                      NIN: {nin}
                                                </p>
                                                <p className="">
                                                      BVN: {bvn}
                                                </p>
                                                <p className="">
                                                      Nationality: {nationality}
                                                </p>
                                                <p className="">
                                                      Nationality Status: {nationalStatus}
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                    <div className="br-sm border py-1 px-1">
                                          <p className="">
                                                Profession: {profession}
                                          </p>
                                          <p className="">
                                                Industry: {industry}
                                          </p>
                                          <p className="">
                                                Name of Business: {nameOfBusiness}
                                          </p>
                                          <p className="">
                                                Business Reg. Number: {businessRegNumber}
                                          </p>
                                          <p className="">
                                                Business Type: {businessType}
                                          </p>
                                          <p className="">
                                                Are you a Director? {areYouADirector}
                                          </p>
                                          <p className="">
                                                Monthly Gross Income Before Deduction: {monthlyGrossIncomeBeforeDeduction}
                                          </p>
                                          <p className="">
                                                Business Monthly Net Profit: {businessMonthlyNetProfit}
                                          </p>
                                          <p className="">
                                                Phone: {phone}
                                          </p>
                                    </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                    <div className="br-sm border py-1 px-1">
                                          <p className="">
                                                Desired Equity Contribution: {desiredEquityContribution}
                                          </p>
                                          <p className="">
                                                Desired Monthly Payment: {desiredMonthlyPayment}
                                          </p>
                                          <p className="">
                                                Desired Interest Rate: {desiredInterateRate}
                                          </p>
                                          <p className="">
                                                Desired Residual: {desiredResidual}
                                          </p>
                                          <p className="">
                                                Desired loan Term: {desiredLoanTerms}
                                          </p>
                                          <p className="">
                                                Desired Repayment Date {desiredRepaymentDate}
                                          </p>

                                          <p className="">
                                                First Year Loan: {firstLoanYear}
                                          </p>
                                          <p className="">
                                                Payment Fees Upfront: {paymentFeesUpfront}
                                          </p>
                                    </div>
                              </div>
                        </div>
                        <div className="mt-5">
                              <Alert variant="success" className='py-4 dark-text text-center'
                              >
                                    You will be contacted after a successful review of your application
                              </Alert>
                        </div>
                  </StyledLoanReview>
            </UserWrapper>
      );
};

export default LoanReview;


const StyledLoanReview = styled.div`
.profile-img {
      width:auto;
                  height:200px;
                  border-radius: 10px;

                  img {
                    width:100%;
                    height:100%;
                    margin:auto;
                    object-fit:contain;
                    background-position: top;
                  }
}

`;