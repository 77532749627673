import styled from "styled-components";
import UserWrapper from "../../layout/UserWrapper";
import AllCarBought from "./AllCarBought";
import { useAdminContext } from "../../context/admin_context";
import MiniLoader from "../../utils/MIniLoader";


const UserCar = () => {

      const {
            cars: { loadCars },
      } = useAdminContext();


      return (
            <UserWrapper>
                  <StyledUserCar>
                        <div className="light-bg py-4 container-fluid br-sm min-height-lg">
                              <div className="d-flex">
                                    <h5 className="mb-0">
                                          Cars Bought
                                    </h5>
                                    <MiniLoader
                                          load={loadCars}
                                    />
                              </div>
                              <hr className="mb-5" />

                              <AllCarBought />
                        </div>
                  </StyledUserCar>
            </UserWrapper>
      );
};

export default UserCar;

const StyledUserCar = styled.main`
      padding-bottom: 2rem;
`;