import styled from "styled-components";
import { naira } from "../../utils/data";
import { ICON_STAR } from "../../utils/icons";
import { Button } from "../../styles";
import { Link, useLocation } from "react-router-dom";
import { placeholder1 } from "../../assets";
import { th } from "../../utils/helpers";
import CarWishlist from "./CarWishlist";

const CarItem = ({ name, mileage, engineType, amount, _id: carId, image, images, year, location: address, carId: wishlistId }) => {

      const location = useLocation();

      console.log(wishlistId);
      console.log(carId);

      const carImg = images?.[0];

      const InterestRate = 0.1;
      const upfront = amount * 0.7;
      const minUpfrontValue = amount - upfront;
      const loanTerm = 48;

      const InterestValue = InterestRate * amount;
      const loanValue = InterestValue + amount;
      const loanValueMinusUpfront = loanValue - minUpfrontValue;
      const loanPaymentAmount = loanValueMinusUpfront / loanTerm;



      return (
            <StyledCarItem className=" br-sm h-100 shadow">
                  <div className="bg-image ">

                        <CarWishlist carId={carId} />


                        <Link to={`/cars/${ location?.pathname === '/user/wishlist' ? wishlistId : carId }`} className="dark-text">
                              <img src={(carImg || image) || placeholder1} alt={name} />
                        </Link>
                  </div>
                  <div className="px-2">

                        <Link to={`/cars/${ location?.pathname === '/user/wishlist' ? wishlistId : carId }`} className="dark-text">
                              <div className="pt-2">
                                    <div className="pb-3">
                                          <div className="d-flex justify-content-between flex-wrap">
                                                <div className="col-9 mt-2">
                                                      <p className="small fw-bold text-break car-name text-truncate">
                                                            {name}
                                                      </p>
                                                </div>
                                                <div className="col mt-2">
                                                      <p className="year fit-content ms-auto">
                                                            {year || '1970'}
                                                      </p>
                                                </div>
                                          </div>
                                          <div className="d-flex justify-content-start mb-3  overflow-x-scroll car-details" >
                                                <div className="mt-2 me-2">
                                                      <p className="m-0 p-0">
                                                            4.0
                                                            <span className="sec-text ms-1 small">
                                                                  {ICON_STAR}
                                                            </span>
                                                      </p>
                                                </div>
                                                <div className="mt-2">
                                                      <p className="milage grey-bold-bg light-text me-1">
                                                            {engineType}
                                                      </p>
                                                </div>
                                                <div className="mt-2">
                                                      <p className="milage grey-bold-bg light-text">
                                                            {th(mileage)} MILES
                                                      </p>
                                                </div>
                                          </div>
                                          <hr />

                                          <div className="d-flex justify-content-between">
                                                <div className="col-6 ">
                                                      <p className="price">
                                                            {naira}{th(amount)}
                                                      </p>
                                                      <p className="small-text grey-bold-text text-truncate">
                                                            {address}
                                                      </p>
                                                </div>
                                                <div className="">
                                                      <p className="price fw-bold">
                                                            {naira}{th(Math.ceil(loanPaymentAmount))}/Mo
                                                      </p>
                                                      <p className="small-text grey-bold-text">
                                                            30% Down Payment
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </Link>
                        <div className="d-flex gap-2 actions mb-2">
                              <Link to={`/cars/buy/${ location?.pathname === '/user/wishlist' ? wishlistId : carId }`} className="w-100">
                                    <Button pry className="btn w-100">
                                          Buy
                                    </Button>
                              </Link>
                              <Link to={`/cars/loan/setup/${ location?.pathname === '/user/wishlist' ? wishlistId : carId }`} className="w-100">
                                    <Button className="btn w-100 border border-dark shadow-none ">
                                          Buy on Loan
                                    </Button>
                              </Link>
                        </div>
                  </div>
            </StyledCarItem>
      );
};

export default CarItem;

export const StyledCarItem = styled.main`
    /* box-shadow: 0px 10px 24px 0px #6061701A; */
    background-color: var(--light-color) !important;

    img {
      width: 100% !important;
      border-radius:10px 10px 0 0;
      object-fit: cover;
      height: 300px;
      /* background-size: cover; */
    }
     /* .bg-image{
                  width:auto;
                  height:300px;
                  border-radius: 10px;

                  img {
                    width:100%;
                    height:100%;
                    margin:auto;
                    object-fit:contain;
                    border-radius: 10px;
                  }
      }   */

      .car-name{
            font-size: 18px;
            text-transform: uppercase;
      }

      .fav {
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px 10px 0 0;
            padding: 7px;
            border-radius: 50%;
            background-color: #000000c2;
            z-index: 200;
            display: flex;
            justify-content: center;
            align-items: center;
      }

      .year {
          margin: 0;
          padding: 5px 10px 0px;
          text-align: center;
          font-size: 16px;
          white-space: nowrap;
          border: 1px var(--dark-color) solid;
}

      .milage {
          margin: 0;
          padding: 5px 10px 0px;
          border-radius: 2px;
          text-align: center;
          white-space: nowrap;
          font-size: 14px;
          text-transform: uppercase;
}

    .price {
      margin: 0;
      padding: 5px 0px 0px;
      /* text-align: center; */
      white-space: nowrap;
      font-size: 16px;
      /* letter-spacing: 2px; */
}

.small-text {
      font-size: 12px;
}


/* Scrollbar  */

.car-details::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.car-details::-webkit-scrollbar-track {
      border: none !important;
      width: 0px !important;
}
.car-details::-webkit-scrollbar-thumb {
  height: 1px !important;
}
/* Scrollbar  */


button {
      margin-bottom: 10px !important;
      padding: 10px 1px !important;

  @media screen and (max-width: 580px) {
      font-size: 14px;
  }
}
`;