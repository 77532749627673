import React, { useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { th } from "../../utils/helpers";
import { useAdminContext } from "../../context/admin_context";
import AppLoader from "../../utils/AppLoader";
import styled from "styled-components";
import UserWrapper from "../../layout/UserWrapper";
import { Link } from "react-router-dom";
import { ICON_ARROW_UP_RIGHT } from "../../utils/icons";
import { Progress } from "../../styles";
import { useUserContext } from "../../context/user_context";

export default function UserBuyRequest() {


      const {
            payment: { loadPayment, allPayment, getPayment },
      } = useUserContext();

      useEffect(() => {
            const buyRequest = allPayment?.map(({ _id: id, phone, fullName, carName, image, email, amount }, i) => {
                  return (
                        {
                              id: i + 1,
                              name: fullName,
                              email: email,
                              phone: phone,
                              amount: th(amount),
                              carName: carName,
                              image: <img src={image} alt={carName} width='70px' className="br-sm"></img>,
                              // link:
                              //       <Link to={id}>
                              //             <h1 className="dark-text m-0">
                              //                   {ICON_ARROW_UP_RIGHT}
                              //             </h1>
                              //       </Link>
                        }
                  );
            });

            setDatatable({
                  ...datatable,
                  rows: buyRequest
            });
      }, [allPayment]);

      useEffect(() => {
            getPayment();
      }, []);

      const [datatable, setDatatable] = React.useState({
            columns: [
                  {
                        label: "ID",
                        field: "id",
                        width: 150,
                        attributes: {
                              "aria-controls": "DataTable",
                              "aria-label": "Id",
                        },
                  },
                  {
                        label: "Name",
                        field: "name",
                        width: 200,
                  },
                  {
                        label: "Email",
                        field: "email",
                        width: 200,
                  },
                  {
                        label: "Phone",
                        field: "phone",
                        width: 270,
                  },
                  {
                        label: "Car Name ",
                        field: "carName",
                        width: 200,
                  },
                  {
                        label: "Image ",
                        field: "image",
                        width: 300,
                  },
                  {
                        label: "",
                        field: "link",
                        width: 200,
                  },
            ],
            rows: []
      });

      return (

            <UserWrapper>
                  <StyledUserLoan>
                        <div className="light-bg py-4 container-fluid br-sm min-height-lg">

                              <AppLoader
                                    load={loadPayment}
                              />

                              <h5>
                                    Buy Request
                              </h5>
                              <div className="mt-3 br-md">

                                    <MDBDataTableV5
                                          hover
                                          fullPagination
                                          entries={10}
                                          pagesAmount={4}
                                          data={datatable}
                                          pagingTop
                                          searchTop
                                          responsive
                                          // theadColor="sec-bold pry-text"
                                          maxHeight="700px"
                                          // searchBottom={false}
                                          className="light-bg br-sm pt-2"
                                    />
                              </div>
                        </div>
                  </StyledUserLoan>
            </UserWrapper>
      );
}

const StyledUserLoan = styled.main`
      padding-bottom: 2rem;
`;
