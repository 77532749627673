import styled from "styled-components";
import { useCarContext } from "../../context/car_context";
import { useEffect, useRef } from "react";
import CarItem from "../CarPage/CarItem";
import { Link } from "react-router-dom";
import Glide from "react-glidejs";
import { StyledGlide } from "../../styles";
import { ClipLoader } from "react-spinners";


const NewCars = () => {

      const {
            cars: { loadCars, allCars, getCars }
      } = useCarContext();

      const gliderRef = useRef(null);

      useEffect(() => {
            getCars();
      }, []);

      const newCars = allCars?.slice(0, 6) || [];

      return (

            <StyledNewCars>

                  <div className="container">
                        <div className="d-flex justify-content-between mb-4">
                              <div className="d-flex">
                                    <h5 className="">
                                          New Cars
                                    </h5>
                                    <ClipLoader
                                          loading={loadCars}
                                          size='20'
                                          color={'var(--dark-color)'}
                                          className='mx-2'
                                    />

                              </div>
                                                  </div>

                        <StyledGlide>

                              <Glide
                                    ref={gliderRef}
                                    startAt={0}
                                    perView={3}
                                    type="carousel"
                                    gap={20}
                                    animationDuration={1000}
                                    animationTimingFunc="ease"
                                    slideClassName="slider__frame"
                                    breakpoints={{
                                          1000: {
                                                perView: 2,
                                          },
                                          768: {
                                                perView: 1.2,
                                          },
                                    }}

                                    throttle={0}
                                    customSlideAnimation={{
                                          timeout: 500,
                                          classNames: 'fade',
                                    }}
                                    focusAt="center"
                              >
                                    {newCars?.map((item, i) =>
                                          <div className="col-md-12 mb-4" key={i}>
                                                <CarItem {...item} />
                                          </div>
                                    )}
                              </Glide>

                        </StyledGlide>
                  </div>
            </StyledNewCars>

      );
};

export default NewCars;

const StyledNewCars = styled.main`
      margin: 4rem 0;


 
`;