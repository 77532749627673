import styled from "styled-components";
import AdminWrapper from "../../../layout/AdminWrapper";
import { Button, Form, Input, Label, Select, StyledImgUpload, Textarea } from "../../../styles";
import { useState } from "react";
import { convertPhoto, randomToken } from "../../../utils/helpers";
import { ICON_X } from "../../../utils/icons";
import { placeholder } from "../../../assets";
import { toast } from "react-toastify";
import BackButton from "../../BackButton";
import { carFeatures, naira, years } from "../../../utils/data";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../context/admin_context";
import MiniLoader from "../../../utils/MIniLoader";
import { useEffect } from "react";
import AdminApi from "../../../api/adminApi";


const AddCar = () => {

      const [formField, setformField] = useState({});
      const [image, setImage] = useState('');
      const [image1, setImage1] = useState('');
      const [image2, setImage2] = useState('');
      const [image3, setImage3] = useState('');
      const [image4, setImage4] = useState('');
      const [sending, setsending] = useState(false);
      const [selectedData, setSelectedData] = useState({
            features: [], engine: [], electricals: [], transmissionAndClutch: [], suspensionAndSteering: [], testDrive: [], interior: [], exterior: [], airconditionSystem: []
      });
      const navigate = useNavigate();

      const { photo, photo1, photo2, photo3, photo4, vin, name, source, description, engineType, brand, mileage, transmission, fuelType, gearType, year, location, interiorColor, exteriorColor, amount
      } = formField;
      const { features, engine, electricals, transmissionAndClutch, suspensionAndSteering, testDrive, interior, exterior, airconditionSystem } = selectedData;

      const {
            brands: { loadBrands, allBrands, getBrands },
            engine: { loadEngine, allEngine, getEngine },
            carType: { loadCarType, allCarType, getCarType }
      } = useAdminContext();

      // console.log(image);
      // console.log(selectedData);

      useEffect(() => {
            getEngine();
            getBrands();
            getCarType();
      }, []);

      function handleChange(e) {
            if (e.target.type === "file") {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field




      const handleCheckboxChange = (value, dataName) => {
            setSelectedData((prevSelectedData) => {
                  const updatedSelectedData = { ...prevSelectedData };

                  if (!updatedSelectedData[dataName]) {
                        updatedSelectedData[dataName] = [];
                  }

                  if (updatedSelectedData[dataName].includes(value)) {
                        updatedSelectedData[dataName] = updatedSelectedData[dataName].filter(
                              (item) => item !== value
                        );
                  } else {
                        updatedSelectedData[dataName] = [
                              ...updatedSelectedData[dataName],
                              value,
                        ];
                  }

                  return updatedSelectedData;
            });
      };


      // Define your data sets and checkboxes here

      function submitFn(e) {
            e.preventDefault();

            if (
                  name
            ) {
                  setsending(true);
                  const data = new FormData();

                  data.append('vehicleId', randomToken);
                  data.append('name', name);
                  data.append('source', source);
                  data.append('year', year);
                  data.append('engineType', engineType);
                  data.append('mileage', +mileage);
                  data.append('transmission', transmission);
                  data.append('fuelType', fuelType);
                  data.append('gearType', gearType);
                  data.append('interiorColor', interiorColor);
                  data.append('exteriorColor', exteriorColor);
                  data.append('features', JSON.stringify(features));

                  data.append('electricals', JSON.stringify(electricals));
                  data.append('transmissionAndClutch', JSON.stringify(transmissionAndClutch));
                  data.append('suspensionAndSteering', JSON.stringify(suspensionAndSteering));
                  data.append('testDrive', JSON.stringify(testDrive));

                  data.append('images', photo);
                  data.append('images', photo1);
                  data.append('images', photo2);
                  data.append('images', photo3);
                  data.append('images', photo4);

                  data.append('brand', brand);
                  data.append('location', location);
                  data.append('description', description);
                  data.append('vin', vin);
                  data.append('amount', amount);
                  data.append('engine', JSON.stringify(engine));
                  data.append('airConditionSystem', JSON.stringify(airconditionSystem));
                  data.append('interior', JSON.stringify(interior));
                  data.append('exterior', JSON.stringify(exterior));


                  AdminApi.post(`/admin/cars/create`, data, {
                        headers: {
                              "content-type": "multipart/form-data",
                        },
                  })
                        .then((res) => {
                              console.log(res);
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    navigate(`/admin/cars/${ res.data._id }`);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }


      return (
            <AdminWrapper>

                  <StyledAddCar>
                        <div className="light-bg br-sm py-2 px-3">
                              <h5 className="m-0">
                                    Add Cars
                              </h5>
                        </div>

                        <div className="light-bg my-2 container py-4 br-sm">
                              <BackButton />

                              <Form onSubmit={(e) => submitFn(e)} className="mt-4">
                                    <div className="row">

                                          <div className="col-md-6">
                                                <div className="">
                                                      <Label htmlFor="photo">
                                                            Cover Photo:
                                                      </Label>
                                                      <div className="col d-flex mx-auto justify-content-center mt-2 mb-4">
                                                            <StyledImgUpload>
                                                                  <input
                                                                        accept="image/*"
                                                                        type="file"
                                                                        id="photo"
                                                                        name='photo'
                                                                        onChange={(e) => (
                                                                              convertPhoto(e, setImage),
                                                                              handleChange(e))
                                                                        }
                                                                        className="w-100 h-100"
                                                                  />
                                                                  <div className="w-100" >
                                                                        {image?.photo ?
                                                                              <div className="cover-container">
                                                                                    <img src={image?.photo}
                                                                                          className="cover"
                                                                                          alt="cover " />
                                                                              </div>
                                                                              :
                                                                              <img src={placeholder} alt="cover " />
                                                                        }
                                                                        {image?.photo &&
                                                                              <div className="cancel"
                                                                                    onClick={() => (setImage(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                              >
                                                                                    {ICON_X}
                                                                              </div>
                                                                        }
                                                                  </div>
                                                            </StyledImgUpload>

                                                      </div>

                                                      <div className="row mb-4">
                                                            <div className="col-6 mb-3">
                                                                  <StyledImgUpload>
                                                                        <input
                                                                              accept="image/*"
                                                                              type="file"
                                                                              id="photo"
                                                                              name='photo1'
                                                                              onChange={(e) => (
                                                                                    convertPhoto(e, setImage1),
                                                                                    handleChange(e))
                                                                              }
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="w-100" >
                                                                              {image1?.photo ?
                                                                                    <div className="cover-container">
                                                                                          <img src={image1?.photo}
                                                                                                className="cover"
                                                                                                alt="cover " />
                                                                                    </div>
                                                                                    :
                                                                                    <img src={placeholder} alt="cover " />
                                                                              }
                                                                              {image1?.photo &&
                                                                                    <div className="cancel"
                                                                                          onClick={() => (setImage1(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                    >
                                                                                          {ICON_X}
                                                                                    </div>
                                                                              }
                                                                        </div>
                                                                  </StyledImgUpload>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                  <StyledImgUpload>
                                                                        <input
                                                                              accept="image/*"
                                                                              type="file"
                                                                              id="photo"
                                                                              name='photo2'
                                                                              onChange={(e) => (
                                                                                    convertPhoto(e, setImage2),
                                                                                    handleChange(e))
                                                                              }
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="w-100" >
                                                                              {image2?.photo ?
                                                                                    <div className="cover-container">
                                                                                          <img src={image2?.photo}
                                                                                                className="cover"
                                                                                                alt="cover " />
                                                                                    </div>
                                                                                    :
                                                                                    <img src={placeholder} alt="cover " />
                                                                              }
                                                                              {image2?.photo &&
                                                                                    <div className="cancel"
                                                                                          onClick={() => (setImage2(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                    >
                                                                                          {ICON_X}
                                                                                    </div>
                                                                              }
                                                                        </div>
                                                                  </StyledImgUpload>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                  <StyledImgUpload>
                                                                        <input
                                                                              accept="image/*"
                                                                              type="file"
                                                                              id="photo"
                                                                              name='photo3'
                                                                              onChange={(e) => (
                                                                                    convertPhoto(e, setImage3),
                                                                                    handleChange(e))
                                                                              }
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="w-100" >
                                                                              {image3?.photo ?
                                                                                    <div className="cover-container">
                                                                                          <img src={image3?.photo}
                                                                                                className="cover"
                                                                                                alt="cover " />
                                                                                    </div>
                                                                                    :
                                                                                    <img src={placeholder} alt="cover " />
                                                                              }
                                                                              {image3?.photo &&
                                                                                    <div className="cancel"
                                                                                          onClick={() => (setImage3(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                    >
                                                                                          {ICON_X}
                                                                                    </div>
                                                                              }
                                                                        </div>
                                                                  </StyledImgUpload>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                  <StyledImgUpload>
                                                                        <input
                                                                              accept="image/*"
                                                                              type="file"
                                                                              id="photo"
                                                                              name='photo4'
                                                                              onChange={(e) => (
                                                                                    convertPhoto(e, setImage4),
                                                                                    handleChange(e))
                                                                              }
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="w-100" >
                                                                              {image4?.photo ?
                                                                                    <div className="cover-container">
                                                                                          <img src={image4?.photo}
                                                                                                className="cover"
                                                                                                alt="cover " />
                                                                                    </div>
                                                                                    :
                                                                                    <img src={placeholder} alt="cover " />
                                                                              }
                                                                              {image4?.photo &&
                                                                                    <div className="cancel"
                                                                                          onClick={() => (setImage4(''), setformField(prev => ({ ...prev, photo: '' })))}
                                                                                    >
                                                                                          {ICON_X}
                                                                                    </div>
                                                                              }
                                                                        </div>
                                                                  </StyledImgUpload>
                                                            </div>
                                                      </div>

                                                </div>

                                                <Label htmlFor="name">
                                                      Car Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="name"
                                                      id="name"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.name}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="description">
                                                      Description:
                                                </Label><br />

                                                <Textarea
                                                      type="text"
                                                      name="description"
                                                      id="description"
                                                      placeholder="Description"
                                                      onChange={handleChange}
                                                      value={formField.description}
                                                      required
                                                      minLength='5'
                                                      rows='3'
                                                />
                                                <Label htmlFor="amount">
                                                      Amount ({naira}):
                                                </Label>
                                                <Input
                                                      type="number"
                                                      name="amount"
                                                      id="amount"
                                                      onChange={handleChange}
                                                      value={formField.amount}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <div className="d-flex">
                                                      <Label htmlFor="brand">
                                                            Brand:
                                                      </Label>
                                                      <MiniLoader
                                                            load={loadBrands}
                                                      />

                                                </div>
                                                <Select
                                                      name="brand"
                                                      id="brand"
                                                      onChange={handleChange}
                                                      value={formField.brand}
                                                      required
                                                >
                                                      <option value="">Select Brand</option>
                                                      {allBrands.map(({ name, _id: id }, i) =>
                                                            <option value={id} key={i}>{name}</option>
                                                      )}
                                                </Select>
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="source">
                                                      Type:
                                                </Label>
                                                <Select
                                                      name="source"
                                                      id="source"
                                                      onChange={handleChange}
                                                      value={formField.source}
                                                      required
                                                >
                                                      <option value="">Select Type</option>
                                                      {allCarType.map(({ name }, i) =>
                                                            <option value={name} key={i}>{name}</option>
                                                      )}
                                                </Select>
                                          </div>
                                          {/* <div className="col-md-6">
                                                <Label htmlFor="model">
                                                      Model:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="model"
                                                      id="model"
                                                      onChange={handleChange}
                                                      value={formField.model}
                                                      required
                                                />
                                          </div> */}
                                          <div className="col-md-6">
                                                <Label htmlFor="year">
                                                      Year:
                                                </Label>
                                                <Select
                                                      name="year"
                                                      id="year"
                                                      onChange={handleChange}
                                                      value={formField.year}
                                                      required
                                                >
                                                      <option value="">Select Year</option>
                                                      {years.map((year, i) =>
                                                            <option value={year} key={i}>{year}</option>
                                                      )}
                                                </Select>
                                          </div>
                                          {/* <div className="col-md-6">
                                                <Label htmlFor="source">
                                                      Source:
                                                </Label>
                                                <Select
                                                      name="source"
                                                      id="source"
                                                      onChange={handleChange}
                                                      value={formField.source}
                                                      required
                                                >
                                                      <option value="">Select Source</option>
                                                      <option value="Dealer A">Dealer A</option>
                                                      <option value="Dealer B">Dealer B</option>
                                                      <option value="Dealer C">Dealer C</option>
                                                </Select>
                                          </div> */}
                                          <div className="col-md-6">
                                                <Label htmlFor="interiorColor">
                                                      Interior Color:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="interiorColor"
                                                      id="interiorColor"
                                                      onChange={handleChange}
                                                      value={formField.interiorColor}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="exteriorColor">
                                                      Exterior Color:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="exteriorColor"
                                                      id="exteriorColor"
                                                      onChange={handleChange}
                                                      value={formField.exteriorColor}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="vin">
                                                      VIN:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="vin"
                                                      id="vin"
                                                      onChange={handleChange}
                                                      value={formField.vin}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="mileage">
                                                      mileage:
                                                </Label>
                                                <Input
                                                      type="number"
                                                      name="mileage"
                                                      id="mileage"
                                                      onChange={handleChange}
                                                      value={formField.mileage}
                                                      required
                                                />
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="transmission">
                                                      Transmission:
                                                </Label>
                                                <Select
                                                      name="transmission"
                                                      id="transmission"
                                                      onChange={handleChange}
                                                      value={formField.transmission}
                                                      required
                                                >
                                                      <option value="">Select Transmission</option>
                                                      <option value="Automatic">Automatic</option>
                                                      <option value="Manual">Manual</option>
                                                      <option value="Both">Both</option>
                                                </Select>
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="fuelType">
                                                      Fuel Type:
                                                </Label>
                                                <Select
                                                      name="fuelType"
                                                      id="fuelType"
                                                      onChange={handleChange}
                                                      value={formField.fuelType}
                                                      required
                                                >
                                                      <option value="">Select Fuel Type</option>
                                                      <option value="Gasoline">Gasoline</option>
                                                      <option value="Diesel">Diesel</option>
                                                      <option value="Electric">Electric</option>
                                                </Select>
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="engineType">
                                                      Engine Type:
                                                </Label>
                                                <Select
                                                      name="engineType"
                                                      id="engineType"
                                                      onChange={handleChange}
                                                      value={formField.engineType}
                                                      required
                                                >
                                                      <option value="">Select Engine Type</option>
                                                      {allEngine.map(({ name }, i) =>
                                                            <option value={name} key={i}>{name}</option>
                                                      )}
                                                </Select>
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="gearType">
                                                      Gear Type:
                                                </Label>
                                                <Select
                                                      name="gearType"
                                                      id="gearType"
                                                      onChange={handleChange}
                                                      value={formField.gearType}
                                                      required
                                                >
                                                      <option value="">Select Gear Type</option>
                                                      <option value="Front Wheel">Front Wheel</option>
                                                      {/* {allEngine.map(({ name }, i) =>
                                                      <option value={name} key={i}>{name}</option>
                                                )} */}
                                                </Select>
                                          </div>
                                          <div className="col-md-6">
                                                <Label htmlFor="location">
                                                      Location:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="location"
                                                      id="location"
                                                      onChange={handleChange}
                                                      value={formField.location}
                                                      required
                                                />
                                          </div>
                                    </div>


                                    <div className="row">
                                          {carFeatures.map((dataSet, index) => (
                                                <div key={index}>
                                                      <Label htmlFor="" className="fw-bold h5 text-capitaliz my-3">
                                                            {dataSet.title}:
                                                      </Label>
                                                      {dataSet.labels.map((label) => (
                                                            <label key={label} className="custom-checkbox-label">
                                                                  <input
                                                                        type="checkbox"
                                                                        value={label}
                                                                        checked={selectedData[dataSet.name]?.includes(label) || false}
                                                                        onChange={() => handleCheckboxChange(label, dataSet.name)}
                                                                        className="custom-checkbox"

                                                                  />
                                                                  {label}
                                                            </label>
                                                      ))}
                                                </div>
                                          ))}

                                    </div>

                                    <div className="col-12 text-center">
                                          <Button
                                                sec
                                                className="btn"
                                                type="submit"
                                                disabled={sending}
                                          >
                                                {sending ? 'loading...' : 'Proceed'}
                                          </Button>
                                    </div>


                              </Form>


                        </div>
                  </StyledAddCar>
            </AdminWrapper>
      );
};

export default AddCar;


export const StyledAddCar = styled.section`

`;