import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import { contactPic } from "../../assets";
import { ICON_CALL, ICON_EMAIL, ICON_LOCATION } from "../../utils/icons";
import { Button, Form, Input, Label, Textarea } from "../../styles";
import { useState } from "react";
import Api from "../../api/axios";
import { toast } from "react-toastify";

const Contact = () => {

      const [formField, setFormField] = useState({});
      const [sending, setsending] = useState(false);


      const handleChange = (e) => {
            setFormField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value
            }));
      };

      function submitFn(e) {
            e.preventDefault();
            if (
                  formField?.name &&
                  formField.phone &&
                  formField.message
            ) {
                  setsending(true);
                  const data = {
                        name: formField?.name,
                        email: formField.email,
                        phone: formField.phone,
                        message: formField.message,
                  };
                  Api.post(`/contact`, data)
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    toast.success(res.message);
                                    setFormField({ name: '', phone: '', email: '', message: '' });
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }

      }

      return (
            <HomeWrapper>
                  <StyledContact>
                        <div className="">
                              {/* <h5 className="my-5 text-center"> Contact Us</h5> */}
                              <img src={contactPic} alt="Contact" width="100%" />

                        </div>
                        <div className="container pt-0 pt-md-5">

                              <div className="col-md-9 mx-auto text-center br-sm border mb-5 py-3 mt-4 mt-md-0">
                                    Any question or remarks? Just write us a message!
                              </div>
                              <div className="row justify-content-around align-items-cente ">

                                    <div className="col-md-6 text-center h-100">
                                          <div className="pry light-text br-md container py-5">
                                                <div className="py-4  br-sm px-3 mb-4">
                                                      <small>{ICON_LOCATION}</small>
                                                      <h5 className="fw-light">
                                                            Suite 2, Fundex Building, Bodija Oju Irin, Ibadan, Oyo State
                                                      </h5>
                                                </div>

                                                <div className="mb-4">
                                                      <p className="m-0">  080234455677, 090765432345</p>
                                                      <small>{ICON_CALL} Call Us</small>
                                                </div>
                                                <div className="mb-4">
                                                      <p className="m-0">info@Reusautobestsales.com </p>
                                                      <small>{ICON_EMAIL} Email Us</small>
                                                </div>
                                          </div>
                                    </div>

                                    <div className="col-md-6 mt-4 mt-md-0 h-100">
                                          <div className="border br-md container py-4">
                                                <Form onSubmit={(e) => submitFn(e)} >
                                                      <div className="row">

                                                            <div className="col-md-12 text-start">
                                                                  <Label htmlFor="name" className="">
                                                                        Name
                                                                  </Label>
                                                                  <Input
                                                                        type="text"
                                                                        id='name'
                                                                        name='name'
                                                                        onChange={handleChange}
                                                                        value={formField.name}
                                                                        required
                                                                  />
                                                            </div>

                                                            <div className="col-md-6 text-start">
                                                                  <Label htmlFor="email" className="">
                                                                        Email Address
                                                                  </Label>
                                                                  <Input
                                                                        type="email"
                                                                        id='email'
                                                                        name='email'
                                                                        onChange={handleChange}
                                                                        value={formField.email}
                                                                        required
                                                                  />
                                                            </div>

                                                            <div className="col-md-6 text-start">
                                                                  <Label htmlFor="phone" className="">
                                                                        Phone Number
                                                                  </Label>
                                                                  <Input
                                                                        type="number"
                                                                        id='phone'
                                                                        name='phone'
                                                                        onChange={handleChange}
                                                                        value={formField.phone}
                                                                        required
                                                                  />
                                                            </div>

                                                            <div className="col-md-12 text-start">
                                                                  <Label htmlFor="message" className="">
                                                                        Message
                                                                  </Label>
                                                                  <Textarea
                                                                        type="text"
                                                                        id='message'
                                                                        name='message'
                                                                        rows='4'
                                                                        onChange={handleChange}
                                                                        value={formField.message}
                                                                        required
                                                                  />
                                                            </div>
                                                      </div>

                                                      <Button
                                                            pry
                                                            type="submit"
                                                            disabled={sending}
                                                            className="btn m-0"  >
                                                            {sending ? 'loading...' : "Send Message"}
                                                      </Button>
                                                </Form>

                                          </div>
                                    </div>
                              </div>

                              <div className="col-md-9 mx-auto text-center br-sm border my-5 py-3 ">
                                    Join us at ReusReusautobestsale and let us help you drive your dreams!
                              </div>
                        </div>
                  </StyledContact>
            </HomeWrapper>
      );
};

export default Contact;

const StyledContact = styled.main`
      padding-bottom: 4rem 0;
`;