import axios from 'axios';
import { toast } from 'react-toastify';

const FetchApi = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    // headers: {
    //     'Authorization': `Bearer ${ process.env.REACT_APP_APPTOKEN }`
    // }
});

FetchApi.interceptors.request.use(
    (request) => {

        let newRequest = {
            ...request, data: request.data
        };
        return newRequest;
    },
    (error) => {
        return Promise.reject(error);
    }
);

FetchApi.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response.data) {
            toast.warn(error.response.data.message);
        } else {
            // toast.error(error.message === "Request failed with status code 500" ? "Internal Error!" : error.message);
        }
        return Promise.reject(error);
    }
);

export default FetchApi;
