import { useState, useEffect } from 'react';
import FetchApi from './getAxios';
import secureGetApi from './secureGetApi';
import adminGetApi from '../api/adminGetApi';


export const useFetch = (url, datas) => {

    const [loading, setloading] = useState(false);
    const [data, setdata] = useState([]);

    const fetchData = async (e) => {
        setloading(true);
        const data = {
            ...datas
        };
        FetchApi.get(`/${ url }`, data)
            .then((res) => {
                if (res.status === false) {
                    setloading(false);
                } else {
                    setloading(false);
                    setdata(res.data);
                }
            })
            .catch(() => {
                setloading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { loading, data, setdata, fetchData };
};
export const useTokenFetch = (url, datas) => {

    const [loading, setloading] = useState(false);
    const [data, setdata] = useState([]);

    const fetchData = async (e) => {
        setloading(true);
        const data = {
            ...datas
        };
        secureGetApi.get(`/${ url }`, data)
            .then((res) => {
                if (res.status === false) {
                    setloading(false);
                } else {
                    setloading(false);
                    setdata(res.data);
                }
            })
            .catch(() => {
                setloading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { loading, data, setdata, fetchData };
};
export const useAdminFetch = (url, datas) => {

    const [loading, setloading] = useState(false);
    const [data, setdata] = useState([]);

    const fetchData = async (e) => {
        setloading(true);
        const data = {
            ...datas
        };
        adminGetApi.get(`/${ url }`, data)
            .then((res) => {
                if (res.status === false) {
                    setloading(false);
                } else {
                    setloading(false);
                    setdata(res.data);
                }
            })
            .catch(() => {
                setloading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { loading, data, setdata, fetchData };
};
