import React, { useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";

import { th } from "../../../utils/helpers";
import { useAdminContext } from "../../../context/admin_context";

export default function DashboardSales() {

      const allSales = [
            {
                  name: 'Mercedes Benz E Class',
                  year: '2009',
                  status: 'Done',
                  id: 'ADDF77656',
                  amount: '800000'
            },
            {
                  name: 'Mercedes Benz E Class',
                  year: '2009',
                  status: 'Done',
                  id: 'ADDF77656',
                  amount: '800000'
            },
            {
                  name: 'Mercedes Benz E Class',
                  year: '2009',
                  status: 'Done',
                  id: 'ADDF77656',
                  amount: '800000'
            },
            {
                  name: 'Mercedes Benz E Class',
                  year: '2009',
                  status: 'Done',
                  id: 'ADDF77656',
                  amount: '800000'
            },
            {
                  name: 'Mercedes Benz E Class',
                  year: '2009',
                  status: 'Done',
                  id: 'ADDF77656',
                  amount: '800000'
            },
            {
                  name: 'Mercedes Benz E Class',
                  year: '2009',
                  status: 'Done',
                  id: 'ADDF77656',
                  amount: '800000'
            },

      ];

      const {
            loans: { loadLoans, allLoans, getLoans },
      } = useAdminContext();

      useEffect(() => {
            const expenses = allSales?.map(({ id, status, name, year, amount }, i) => {
                  return (
                        {
                              id: id,
                              name: name,
                              year: year,
                              status: status,
                              amount: th(amount),
                        }
                  );
            });

            setDatatable({
                  ...datatable,
                  rows: expenses
            });
      }, [allSales]);


      const [datatable, setDatatable] = React.useState({
            columns: [
                  {
                        label: "ID",
                        field: "id",
                        width: 150,
                        attributes: {
                              "aria-controls": "DataTable",
                              "aria-label": "Id",
                        },
                  },
                  {
                        label: "Name",
                        field: "name",
                        width: 200,
                  },
                  {
                        label: "Year",
                        field: "year",
                        width: 200,
                  },
                  {
                        label: "Status",
                        field: "status",
                        width: 270,
                  },
                  {
                        label: "Amount ",
                        field: "amount",
                        width: 200,
                  },
            ],
            rows: []
      });


      return (
            <>
                  <h5 className="mt-4">
                        Car Sales
                  </h5>
                  <div className="mt-3 br-md">

                        <MDBDataTableV5
                              hover
                              fullPagination
                              entries={10}
                              pagesAmount={4}
                              data={datatable}
                              pagingTop
                              searchTop
                              responsive
                              // theadColor="sec-bold pry-text"
                              maxHeight="700px"
                              // searchBottom={false}
                              className="light-bg br-sm pt-2"
                        />
                  </div>
            </>

      );
}
