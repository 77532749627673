import { Link } from "react-router-dom";
import { useUserContext } from "../../context/user_context";
import BuyFormCar from "../../components/CarPage/ApplyForCar/BuyFormCar";
import { useAdminContext } from "../../context/admin_context";
import EmptyData from "../../utils/EmptyData";


const AllCarBought = () => {


      const {
            cars: { allCars },
      } = useAdminContext();



      return (
            <>

                  <div className="row">

                        <EmptyData
                              title={'Purchase'}
                        />
                        {/* {allCars?.map((item, i) =>
                              <div className="col-lg-4 col-md-6 mb-4" key={i}>
                                    <Link to={item?._id} className="dark-text">
                                          <BuyFormCar {...item} />
                                    </Link>
                              </div>
                        )} */}
                  </div>



            </>
      );
};

export default AllCarBought;