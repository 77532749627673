import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import { about1, about2, aboutHeader } from "../../assets";
import { Button } from "../../styles";
import { Link } from "react-router-dom";
import { values } from "../../utils/data";

const About = () => {


      return (
            <HomeWrapper>
                  <StyledContact>
                        <div className="">
                              <h4 className="my-5 text-center"> About Us</h4>
                              <img src={aboutHeader} alt="About" width="100%" />

                        </div>
                        <div className="container pt-5">

                              <div className="row justify-content-around align-items-center ">
                                    <div className="col-md-5">
                                          <img src={about1} alt="About1" width='100%' />
                                    </div>
                                    <div className="col-md-5 mt-4 mt-md-0">
                                          <h4 className="mb-4">
                                                Company Overview
                                          </h4>
                                          <p className="">
                                                Reusautobestsales is a reputable car dealership and financing company. We specialize in providing high quality cars on car loan, offering a convenient and affordable option for individuals and corporate bodies who desire to own a car but may not have the immediate financial means to purchase one outright. With our commitment to quality, reliability, and customer satisfaction, we aim to revolutionize the car ownership experience.
                                          </p>
                                    </div>
                              </div>

                              <div className="row justify-content-around align-items-center mt-4 flex-row-reverse">
                                    <div className="col-md-5 ">
                                          <img src={about2} alt="About1" width='100%' />
                                    </div>
                                    <div className="col-md-5 mt-4 mt-md-0">
                                          <h4 className="mb-4">
                                                Our Services
                                          </h4>
                                          <p className="fw-bold">
                                                Car Loan Financing:
                                          </p>
                                          <p className="">
                                                Reusautobestsales offers flexible financing options for individuals and corporate bodies seeking to purchase a car. We provide affordable payment plans tailored to suit our clients' financial situations, enabling them to drive their desired vehicles without the burden of a lump-sum payment
                                          </p>
                                          <Link to='/services'>
                                                <Button pry className="btn btn-sm">
                                                      View all
                                                </Button>
                                          </Link>
                                    </div>

                              </div>

                              <div className="col-md-9 mx-auto text-center my-5 ">
                                    <h4 className="">
                                          Company Values
                                    </h4>

                                    {values.map(({ title, content }, i) =>
                                          <div className="br-sm border my-3 py-4 px-2" key={i}>
                                                <div className="col-md-8 mx-auto">
                                                      <h6 className="fw-bold">
                                                            {title}
                                                      </h6>
                                                      <p className="">
                                                            {content}
                                                      </p>
                                                </div>
                                          </div>
                                    )}

                              </div>

                        </div>
                  </StyledContact>
            </HomeWrapper>
      );
};

export default About;

const StyledContact = styled.main`
      padding-bottom: 4rem 0;
`;