import styled from "styled-components";

import { useState } from "react";
import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from 'mdb-react-ui-kit';
import CarItem from "./CarItem";




const CarPagination = ({ cars }) => {

      const itemsPerPage = 15;

      const [currentPage, setCurrentPage] = useState(1);

      const totalPages = Math.ceil(cars.length / itemsPerPage);

      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const displayedItems = cars.slice(startIndex, endIndex);

      const handlePageChange = page => {
            setCurrentPage(page);
      };

      return (
            <>
                  <h6 className="pry-text text-center mt-4">
                        Page {currentPage} of {totalPages}
                  </h6>
                  <div className="row">
                        {displayedItems?.map((item, i) =>
                              <div className="col-md-6 col-lg-4 mb-4" key={i}>
                                    <CarItem {...item} key={i} />
                              </div>
                        )}
                  </div>

                  <StyledPagination aria-label='...'>
                        <MDBPagination circle className=' flex-wrap mb-0 justify-content-center '>
                              <MDBPaginationItem
                                    disabled={currentPage === 1}
                              >
                                    <MDBPaginationLink
                                          href="#topPage"
                                          onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                          Previous
                                    </MDBPaginationLink>
                              </MDBPaginationItem>
                              {Array.from({ length: totalPages }).map((_, index) => (
                                    <>
                                          <MDBPaginationItem aria-current='page'
                                                key={index} active={index + 1 === currentPage}
                                          >
                                                <MDBPaginationLink
                                                      href="#topPage"
                                                      onClick={() => handlePageChange(index + 1)}>
                                                      {index + 1}
                                                </MDBPaginationLink>
                                          </MDBPaginationItem>
                                    </>
                              ))}

                              <MDBPaginationItem
                                    disabled={currentPage === totalPages}
                              >
                                    <MDBPaginationLink
                                          href="#topPage"
                                          onClick={() => handlePageChange(currentPage + 1)}
                                    >Next</MDBPaginationLink>
                              </MDBPaginationItem>
                        </MDBPagination>
                  </StyledPagination>
            </>
      );
};

export default CarPagination;






const StyledPagination = styled.nav`
      
      .page-item.disabled {
            color: var(--grey-color);
      }

      .page-link{
            color: var(--dark-color);
            font-size: larger;

      }
`;