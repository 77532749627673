import styled from "styled-components";
import UserWrapper from "../../layout/UserWrapper";
import { Button, Form, Input, Label, StyledImgUpload } from "../../styles";
import { placeholder1 } from "../../assets";
import { ICON_BIN, ICON_PEN } from "../../utils/icons";
import { useState } from "react";
import { useUserContext } from "../../context/user_context";
import { useLocalStorage } from "../../utils/useLocalStorage";
import MiniLoader from "../../utils/MIniLoader";

const UserSetting = () => {

      const [userData] = useLocalStorage('userdata');

      const { email, firstName, lastName, phone, profilePic } = userData;
      const [formField, setformField] = useState({
            photo: profilePic, firstName, lastName, phone, email, opword: '', npword: '', cpword: ''
      });
      const [saving, setSaving] = useState(false);
      const [changing, setChanging] = useState(false);
      const [updating, setUpdating] = useState(false);

      // Form Fields
      function handleChange(e) {
            if (e.target.name === 'photo') {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field

      const {
            updateProfile, changePassword, changeAvatar
      } = useUserContext();


      return (
            <UserWrapper>
                  <StyledUserSetting>
                        <div className="light-bg py-4 container-fluid br-sm min-height-lg">
                              <h5 className="">
                                    Edit Profile
                              </h5>

                              <div className="">
                                    <Form>
                                          <div className="col-md-4 my-4">
                                                <div className="mt-2 col-8 col-md-12">
                                                      <StyledImgUpload className="me-auto mb-4">
                                                            {saving ?
                                                                  <h1 className="text-center">
                                                                        <MiniLoader
                                                                              load={saving}
                                                                              class=""
                                                                        />
                                                                  </h1>
                                                                  :
                                                                  <>
                                                                        <input
                                                                              accept="image/*"
                                                                              type="file"
                                                                              id="photo"
                                                                              name='photo'
                                                                              onChange={(e) => changeAvatar(e, e.target.files[0], setSaving, setformField)}
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="w-100" >

                                                                              {formField?.photo ?
                                                                                    <div className="cover-container">
                                                                                          <img src={formField?.photo}
                                                                                                className="cover"
                                                                                                alt="cover " />
                                                                                    </div>
                                                                                    :
                                                                                    <img src={placeholder1} alt="cover " />
                                                                              }
                                                                              {formField?.photo &&
                                                                                    <div className="cancel"
                                                                                          onClick={() => setformField(prev => ({ ...prev, photo: '' }))}
                                                                                    >
                                                                                          {ICON_PEN}
                                                                                    </div>
                                                                              }
                                                                        </div>
                                                                  </>
                                                            }

                                                      </StyledImgUpload>
                                                      {/* <Button
                                                            pry
                                                            type="submit"
                                                            className="btn"
                                                            disabled={saving}
                                                      >
                                                            {saving ? 'loading' : 'Save Image'}
                                                      </Button> */}
                                                </div>
                                          </div>

                                    </Form>

                                    <hr />

                                    <Form onSubmit={(e) => updateProfile(e, formField, setUpdating)}>

                                          <div className="col-md-7 ">

                                                <Label htmlFor="firstName">
                                                      First Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="firstName"
                                                      id="firstName"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.firstName}
                                                      required
                                                />
                                                <Label htmlFor="lastName">
                                                      Last Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="lastName"
                                                      id="lastName"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.lastName}
                                                      required
                                                />

                                                <Label htmlFor="email">
                                                      Email:
                                                </Label>
                                                <Input
                                                      type="email"
                                                      name="email"
                                                      id="email"
                                                      onChange={handleChange}
                                                      value={formField.email}
                                                />
                                                <Label htmlFor="phone">
                                                      Phone Number:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="phone"
                                                      id="phone"
                                                      onChange={handleChange}
                                                      value={formField.phone}
                                                      required
                                                />
                                                <Button
                                                      pry
                                                      type="submit"
                                                      className="btn"
                                                      disabled={updating}
                                                >
                                                      {updating ? 'loading' : 'Save'}
                                                </Button>
                                          </div>

                                    </Form>

                                    <hr />

                                    <Form onSubmit={(e) => changePassword(e, formField, setChanging, setformField)}>

                                          <div className="col-md-7">

                                                <Label htmlFor="opword">
                                                      Old Password:
                                                </Label>
                                                <Input
                                                      type="password"
                                                      name="opword"
                                                      id="opword"
                                                      onChange={handleChange}
                                                      value={formField.opword}
                                                      required
                                                />
                                                <Label htmlFor="npword">
                                                      New Password:
                                                </Label>
                                                <Input
                                                      type="password"
                                                      name="npword"
                                                      id="npword"
                                                      onChange={handleChange}
                                                      value={formField.npword}
                                                      required
                                                />
                                                <Label htmlFor="cpword">
                                                      Confirm Password:
                                                </Label>
                                                <Input
                                                      type="password"
                                                      name="cpword"
                                                      id="cpword"
                                                      onChange={handleChange}
                                                      value={formField.cpword}
                                                      required
                                                />
                                                <Button
                                                      pry
                                                      type="submit"
                                                      className="btn"
                                                      disabled={changing}
                                                >
                                                      {changing ? 'loading' : 'Save'}
                                                </Button>
                                          </div>
                                          <hr />

                                    </Form>
                              </div>
                        </div>

                  </StyledUserSetting>
            </UserWrapper>
      );
};

export default UserSetting;


const StyledUserSetting = styled.section`
      


`;