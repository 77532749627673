import styled from "styled-components";
import { Button, Form, Label, Select } from "../../styles";
import { years } from "../../utils/data";
import { ICON_SEARCH } from "../../utils/icons";
import { useEffect, useState } from "react";
import { useCarContext } from "../../context/car_context";
import MiniLoader from "../../utils/MIniLoader";


const HomeSearch = () => {

      const [formField, setformField] = useState({
            type: '', year: '', brand: '', engine: ''
      });

      const {
            brands: { loadBrands, allBrands, getBrands },
            engine: { loadEngine, allEngine, getEngine },
            carType: { loadCarType, allCarType, getCarType },
            search: { searching, searchFn, },
      } = useCarContext();

      // Form Fields
      function handleChange(e) {
            setformField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value,
            }));
      }
      // Form Field

      useEffect(() => {
            getBrands(); getEngine(); getCarType();
      }, []);


      return (
            <StyledHomeSearch className="border d-flex justify-content-center">
                  <div className="row justify-content-between align-items-center light-bg br-sm shadow container py-5 ">
                        <div className="search-content">
                              <Form
                                    onSubmit={(e) => searchFn(e, formField)}>
                                    <div className="row">
                                          <div className="col-md-2 text-center ">
                                                <Button
                                                      type="submit"
                                                      className="btn shadow-none m-0 p-0"
                                                      disabled={searching}
                                                >
                                                      <div className="fit-content sec br-sm py-3 mt-3 ml-3 px-4 light-text mx-auto search-icon mb-4 mb-md-0">
                                                            {searching ? '...' : ICON_SEARCH}
                                                      </div>
                                                </Button>
                                          </div>
                                          <div className="col-md-9 ">

                                                <div className="row">
                                                      <div className="col-md-3 col-6">
                                                            <Label htmlFor="year">
                                                                  Year
                                                            </Label>
                                                            <Select
                                                                  name="year"
                                                                  id="year"
                                                                  onChange={handleChange}
                                                                  value={formField.year}
                                                            >
                                                                  <option value="">Model Year</option>
                                                                  {years.map((year, i) =>
                                                                        <option value={year} key={i}>{year}</option>
                                                                  )}
                                                            </Select>
                                                      </div>
                                                      <div className="col-md-3 col-6">
                                                            <Label htmlFor="brand">
                                                                  Brand
                                                                  <MiniLoader
                                                                        load={loadBrands}
                                                                  // class='mt-2'
                                                                  />
                                                            </Label>
                                                            <Select
                                                                  name="brand"
                                                                  id="brand"
                                                                  onChange={handleChange}
                                                                  value={formField.brand}
                                                            >
                                                                  <option value="">Car Brand</option>
                                                                  {allBrands.map(({ name }, i) =>
                                                                        <option value={name} key={i}>{name}</option>
                                                                  )}
                                                            </Select>
                                                      </div>
                                                      <div className="col-md-3 col-6">
                                                            <Label htmlFor="type">
                                                                  Car Type
                                                            </Label>
                                                            <Select
                                                                  name="type"
                                                                  id="type"
                                                                  onChange={handleChange}
                                                                  value={formField.type}
                                                            >
                                                                  <option value="">Car Type</option>
                                                                  {allCarType?.map(({ name }, i) =>
                                                                        <option value={name} key={i}>{name}</option>
                                                                  )}
                                                            </Select>
                                                      </div>
                                                      <div className="col-md-3 col-6">
                                                            <Label htmlFor="engine">
                                                                  Engine Type
                                                            </Label>
                                                            <Select
                                                                  name="engine"
                                                                  id="engine"
                                                                  onChange={handleChange}
                                                                  value={formField.engine}
                                                            >
                                                                  <option value="">Engine Type</option>
                                                                  {allEngine?.map(({ name }, i) =>
                                                                        <option value={name} key={i}>{name}</option>
                                                                  )}
                                                            </Select>
                                                      </div>
                                                </div>

                                          </div>

                                    </div>
                              </Form>
                        </div>

                  </div>
            </StyledHomeSearch>
      );
};

export default HomeSearch;

const StyledHomeSearch = styled.section`
      padding: 4rem 0;
      background-color: var(--light-bold-color);


      label {
            font-weight: 600;
      }
      select {
            border: 1px solid var(--grey-color);
      }

      .search-content {
            width:90%;
            margin: auto;
      }

      .search-icon{
            font-size:20px;
      }




      
`;