import React, { useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";

import { useAdminContext } from "../../../context/admin_context";
import AppLoader from "../../../utils/AppLoader";

export default function AllUsers() {


      const {
            users: { loadUsers, allUsers, getUsers },
      } = useAdminContext();

      useEffect(() => {
            const users = allUsers?.map(({ id, phone, firstName, lastName, email, active }, i) => {
                  return (
                        {
                              id: i + 1,
                              name: firstName + ' ' + lastName,
                              email: email,
                              phone: phone,
                              active: active ? 'active' : 'inactive',
                        }
                  );
            });

            setDatatable({
                  ...datatable,
                  rows: users
            });
      }, [allUsers]);

      useEffect(() => {
            getUsers();
      }, []);


      const [datatable, setDatatable] = React.useState({
            columns: [
                  {
                        label: "ID",
                        field: "id",
                        width: 150,
                        attributes: {
                              "aria-controls": "DataTable",
                              "aria-label": "Id",
                        },
                  },
                  {
                        label: "Name",
                        field: "name",
                        width: 200,
                  },
                  {
                        label: "Email",
                        field: "email",
                        width: 200,
                  },
                  {
                        label: "Phone",
                        field: "phone",
                        width: 270,
                  },
                  {
                        label: "Status ",
                        field: "active",
                        width: 200,
                  },
            ],
            rows: []
      });


      return (
            <>
                  <AppLoader
                        load={loadUsers}
                  />

                  <h5>
                        All Users
                  </h5>
                  <div className="mt-3 br-md">

                        <MDBDataTableV5
                              hover
                              fullPagination
                              entries={10}
                              pagesAmount={4}
                              data={datatable}
                              pagingTop
                              searchTop
                              responsive
                              // theadColor="sec-bold pry-text"
                              maxHeight="700px"
                              // searchBottom={false}
                              className="light-bg br-sm pt-2"
                        />
                  </div>
            </>

      );
}
