import styled from "styled-components";
import AdminWrapper from "../../layout/AdminWrapper";
import AgentHeader from "../../components/admin/agents/AgentHeader";
import AllAgents from "../../components/admin/agents/AllAgents";



const AdminAgent = () => {
      return (
            <AdminWrapper>
                  <StyledAdminAgent>
                        <AgentHeader />
                        <AllAgents />
                  </StyledAdminAgent>
            </AdminWrapper>
      );
};

export default AdminAgent;

const StyledAdminAgent = styled.main`
      padding-bottom: 2rem;
`;