import styled from "styled-components";
import AdminWrapper from "../../layout/AdminWrapper";
import DealersHeader from "../../components/admin/dealers/DealersHeader";
import AllDealers from "../../components/admin/dealers/AllDealers";

const AdminDealer = () => {
      return (
            <AdminWrapper>
                  <StyledAdminDealer>
                        <DealersHeader />
                        <AllDealers />
                  </StyledAdminDealer>
            </AdminWrapper>
      );
};

export default AdminDealer;

const StyledAdminDealer = styled.main`
      padding-bottom: 2rem;
`;