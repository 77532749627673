import React from 'react';
import { ClipLoader } from 'react-spinners';

const MiniLoader = (props) => {


  return (
    <>
      <ClipLoader
        loading={props.load}
        size='20'
        color={'var(--dark-color)'}
        className={`ms-3 ${ props.class } `}
      />

    </>
  );
};

export default MiniLoader;

