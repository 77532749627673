import React, { useContext, useState } from 'react';
import { useFetch } from '../api/useFetch';
import FetchApi from '../api/getAxios';
import { placeholder1 } from '../assets';
import { useAdminFetch, useTokenFetch } from '../adminApi/useFetch';
import SecureApi from '../adminApi/secureApi';
import { toast } from 'react-toastify';
import AdminApi from '../api/adminApi';


export const AdminContext = React.createContext();

export const AdminProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [carData, setCarData] = useState({ images: [placeholder1] });

    const { loading: loadCars, data: allCars, fetchData: getCars } = useAdminFetch('admin/cars');
    const { loading: loadBrands, data: allBrands, fetchData: getBrands } = useFetch('brands');
    const { loading: loadEngine, data: allEngine, fetchData: getEngine } = useFetch('engines');
    const { loading: loadCarType, data: allCarType, fetchData: getCarType } = useFetch('types');
    const { loading: loadLoans, data: allLoans, fetchData: getLoans } = useFetch('admin/loans');
    const { loading: loadUsers, data: allUsers, fetchData: getUsers } = useTokenFetch('admin/users/all');
    const { loading: loadAgents, data: allAgents, fetchData: getAgents } = useTokenFetch('admin/agents/all');
    const { loading: loadDealers, data: allDealers, fetchData: getDealers } = useTokenFetch('admin/dealers/all');

    function getCarDetails(carId) {
        setLoading(true);
        AdminApi.get(`/admin/cars/${ carId }`)
            .then((res) => {
                if (res.status === false) {
                    setLoading(false);
                } else {
                    setLoading(false);
                    setCarData(res.data);
                }
            })
            .catch((error) => {
                setLoading(false);

            });
    }

    const [removing, setRemoving] = useState(false);

    function deleteCar(id, name) {
        if (id) {
            if (window.confirm(`Are you sure you want to remove ${ name }?`)) {
                setRemoving(true);
                SecureApi.delete(`/admin/cars/${ id }`)
                    .then((res) => {
                        if (res.status === false) {
                            toast.warn(res.message);
                            setRemoving(false);
                        } else {
                            setRemoving(false);
                            toast.success(res.message);
                            getCars();
                        }
                    })
                    .catch((error) => {
                        setRemoving(false);
                    });
            }
        } else {
            setRemoving(false);
            toast.warn("Select a car!");
        }
    }
    function deleteDealer(id, name) {
        if (id) {
            if (window.confirm(`Are you sure you want to remove ${ name } from being a dealer ?`)) {
                setRemoving(true);
                SecureApi.delete(`/admin/dealers/${ id }`)
                    .then((res) => {
                        if (res.status === false) {
                            toast.warn(res.message);
                            setRemoving(false);
                        } else {
                            setRemoving(false);
                            toast.success(res.message);
                            getDealers();
                        }
                    })
                    .catch((error) => {
                        setRemoving(false);
                    });
            }
        } else {
            setRemoving(false);
            toast.warn("Select an dealer!");
        }
    }
    function deleteAgent(id, name) {
        if (id) {
            if (window.confirm(`Are you sure you want to remove ${ name } from being an agent ?`)) {
                setRemoving(true);
                SecureApi.delete(`/admin/agents/${ id }`)
                    .then((res) => {
                        if (res.status === false) {
                            toast.warn(res.message);
                            setRemoving(false);
                        } else {
                            setRemoving(false);
                            toast.success(res.message);
                            getAgents();
                        }
                    })
                    .catch((error) => {
                        setRemoving(false);
                    });
            }
        } else {
            setRemoving(false);
            toast.warn("Select an agent!");
        }
    }




    return (
        <AdminContext.Provider value={{
            cars: { loadCars, allCars, getCars, deleteCar, removing },
            carInfo: { getCarDetails, carData, loading },
            brands: { loadBrands, allBrands, getBrands },
            engine: { loadEngine, allEngine, getEngine },
            carType: { loadCarType, allCarType, getCarType },

            loans: { loadLoans, allLoans, getLoans },
            users: { loadUsers, allUsers, getUsers },
            agents: { loadAgents, allAgents, getAgents, deleteAgent, removing },
            dealers: { loadDealers, allDealers, getDealers, deleteDealer, removing },
        }}>
            {children}
        </AdminContext.Provider>
    );
};

// make sure use
export const useAdminContext = () => {
    return useContext(AdminContext);
};
