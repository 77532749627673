import styled from "styled-components";
import HomeWrapper from "../../../layout/HomeWrapper";
import { Form } from "../../../styles";
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { StyledAuth } from "../../../pages/auth/Register";
import AppLoader from "../../../utils/AppLoader";
import BackButton from "../../BackButton";
import SuccessApplication from "./SuccessApplication";
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import LoanFormAboutMe from "./LoanFormAboutMe";
import LoanFormProfession from "./LoanFormProfession";
import LoanFormPreference from "./LoanFormPreference";
import LoanFormConsent from "./LoanFormConsent";
import SecureApi from "../../../api/secureApi";


const LoanForm = () => {

      const [sending, setsending] = useState(false);

      // Form
      const [formField, setFormField] = useState({});
      const [vehiclePurpose, setvehiclePurpose] = useState('');
      const [firstLoanYear, setfirstLoanYear] = useState('');
      const [maintenancePlanSubscription, setmaintenancePlanSubscription] = useState('');
      const [subscribeRoadWorthiness, setsubscribeRoadWorthiness] = useState('');
      const [paymentFeesUpfront, setpaymentFeesUpfront] = useState('');
      const [consent, setconsent] = useState('');
      const [termsAndCondition, settermsAndCondition] = useState('');


      // Modal
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = (props) => setShow(true);;


      const params = useParams();

      const { id } = params;

      const handleChange = (e) => {
            if (e.target.multiple) {
                  setFormField((inputs) => ({
                        ...inputs,
                        [e.target.name]: Array.from(e.target.selectedOptions, (option) => option.value)
                  }));
            } else {
                  setFormField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value
                  }));
            }
      };

      function submitFn(e) {
            e.preventDefault();

            if (
                  formField.firstName &&
                  formField.lastName &&
                  formField.phone &&
                  formField.email &&
                  formField.dob &&
                  formField.nin &&
                  formField.inquiry &&
                  formField.bvn &&
                  formField.gender &&
                  formField.nationality &&
                  formField.nationalStatus &&
                  formField.profession &&
                  formField.industry &&
                  // formField.nameOfBusiness ||
                  // formField.businessRegNumber ||
                  // formField.businessType ||
                  // formField.areYouADirector ||
                  // formField.monthlyGrossIncomeBeforeDeduction ||
                  // formField.businessMonthlyNetProfit ||
                  formField.desiredEquityContribution &&
                  formField.desiredMonthlyPayment &&
                  formField.desiredInterateRate &&
                  formField.desiredResidual &&
                  formField.desiredLoanTerms &&
                  formField.desiredRepaymentDate &&
                  formField.paymentItems &&
                  firstLoanYear && maintenancePlanSubscription &&
                  subscribeRoadWorthiness && paymentFeesUpfront &&
                  consent && termsAndCondition
            ) {
                  setsending(true);
                  const data = {
                        carId: id,
                        firstName: formField.firstName,
                        lastName: formField.lastName,
                        phone: formField.phone,
                        email: formField.email,
                        dob: formField.dob,
                        nin: formField.nin,
                        inquiry: formField.inquiry,
                        bvn: formField.bvn,
                        gender: formField.gender,
                        nationality: formField.nationality,
                        nationalStatus: formField.nationalStatus,
                        profession: formField.profession,
                        industry: formField.industry,
                        nameOfBusiness: formField.nameOfBusiness,
                        businessRegNumber: formField.businessRegNumber,
                        businessType: formField.businessType,
                        areYouADirector: JSON.parse(formField.areYouADirector),
                        monthlyGrossIncomeBeforeDeduction: +formField.monthlyGrossIncomeBeforeDeduction,
                        businessMonthlyNetProfit: +formField.businessMonthlyNetProfit,
                        vehiclePurpose: vehiclePurpose,
                        desiredEquityContribution: +formField.desiredEquityContribution,
                        desiredMonthlyPayment: +formField.desiredMonthlyPayment,
                        desiredInterateRate: +formField.desiredInterateRate,
                        desiredResidual: +formField.desiredResidual,
                        desiredLoanTerms: formField.desiredLoanTerms,
                        desiredRepaymentDate: formField.desiredRepaymentDate,
                        firstLoanYear: JSON.parse(firstLoanYear),
                        maintenancePlanSubscription: JSON.parse(maintenancePlanSubscription),
                        subscribeRoadWorthiness: JSON.parse(subscribeRoadWorthiness),
                        paymentFeesUpfront: JSON.parse(paymentFeesUpfront),
                        paymentItems: formField.paymentItems,
                  };
                  console.log(data);
                  SecureApi.post(`/loan/apply`, data)
                        .then((res) => {
                              console.log(res);
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    handleShow();
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      const loanFormData = {
            handleChange, formField, setvehiclePurpose, setfirstLoanYear, setmaintenancePlanSubscription, setsubscribeRoadWorthiness, setpaymentFeesUpfront, setconsent, settermsAndCondition, sending
      };

      return (
            <HomeWrapper>
                  <AppLoader
                        load={sending}
                  />
                  <StyledLoanForm>
                        <StyledAuth>
                              <div className="container">
                                    <div className="light-bg py-5 shadow br-md">
                                          <div className="col-md-10 col-lg-10 mx-auto text-center container">
                                                <BackButton />

                                                <div className="row">

                                                      <div className="col-md-12">
                                                            <h5 className="mb-4">
                                                                  Loan Form
                                                            </h5>
                                                            <Form onSubmit={(e) => submitFn(e)} >

                                                                  <MDBAccordion borderless initialActive={1}>
                                                                        <MDBAccordionItem collapseId={1} headerTitle='About Me' className="p-">
                                                                              <LoanFormAboutMe
                                                                                    {...loanFormData}
                                                                              />

                                                                        </MDBAccordionItem>
                                                                        <MDBAccordionItem collapseId={2} headerTitle=' Profession Details'>

                                                                              <LoanFormProfession
                                                                                    {...loanFormData}
                                                                              />

                                                                        </MDBAccordionItem>
                                                                        <MDBAccordionItem collapseId={3} headerTitle=' Loan Perference'>

                                                                              <LoanFormPreference
                                                                                    {...loanFormData}
                                                                              />

                                                                        </MDBAccordionItem>
                                                                        <MDBAccordionItem collapseId={4} headerTitle=' Consent, Terms and Conditions'>

                                                                              <LoanFormConsent
                                                                                    {...loanFormData}
                                                                              />

                                                                        </MDBAccordionItem>
                                                                  </MDBAccordion>
                                                            </Form>
                                                      </div>

                                                </div>



                                          </div>
                                    </div>
                              </div>

                        </StyledAuth>
                  </StyledLoanForm>
                  <SuccessApplication
                        show={show}
                        onHide={handleClose}
                  />
            </HomeWrapper>
      );
};

export default LoanForm;

const StyledLoanForm = styled.main`
.accordion-header{
      
      .accordion-button {
            border: 1px solid var(--grey-color);
            margin-bottom: 10px;
            border-radius: 8px;
            
            font-weight: 700 !important;
            font-size: 18px;
            background-color: transparent;
            :hover, :focus {
                  border-radius: 8px;
                  border: 1px solid var(--pry-color) !important;
            }
      }
}
.accordion-button:not(.collapsed){
      color: var(--dark-color) !important;
      border: 1px solid transparent;
      border-bottom: 1px solid var(--grey-color) !important;
      border-radius: 0;
      background-color: transparent !important;
}

.accordion-body {
padding: 1.15rem 0.5rem;
}

label {
      color: var(--dark-color);
}

.custom-control-label {
      margin-top: 1rem;
      font-size: 20px !important;
      color: var(--dark-color) !important;

}
`;