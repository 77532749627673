import SideNav from "./SideNav";
import TopNav from "./TopNav";



const Navbar = ({ pagename }) => {

  return (
    <div className="d-flex align-items-center">
      <TopNav
        pagename={pagename}
      />
      <SideNav />
    </div>
  );
};

export default Navbar;
