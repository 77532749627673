import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Input, Label } from "../../../styles";
import BackButton from '../../BackButton';
import AdminWrapper from '../../../layout/AdminWrapper';
import SecureApi from '../../../adminApi/secureApi';

const CreateAgents = () => {

      const [formField, setformField] = useState({

      });
      const [sending, setsending] = useState(false);
      const navigate = useNavigate();



      // Form Fields
      function handleChange(e) {
            if (e.target.type === "file") {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.files[0],
                  }));
            } else {
                  setformField((inputs) => ({
                        ...inputs,
                        [e.target.name]: e.target.value,
                  }));
            }
      }
      // Form Field


      function submitFn(e) {
            e.preventDefault();
            if (
                  formField.name
            ) {
                  setsending(true);
                  const data = new FormData();

                  data.append('name', formField.name);
                  data.append('whatsAppNumber', formField.whatsapp);
                  data.append('cardNumber', formField.cardNumber);
                  data.append('phoneNumber', formField.phone);
                  data.append('email', formField.email);
                  data.append('password', '12345678');
                  data.append('passport', formField.photo);
                  data.append('card', formField.card);

                  SecureApi.post(`/admin/agents/create`, data, {
                        headers: {
                              "content-type": "multipart/form-data",
                        },
                  })
                        .then((res) => {
                              if (res.status === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    setTimeout(() => {
                                          toast.success(res.message);
                                    }, 10);
                                    navigate(`/admin/agents`);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }

      return (
            <>
                  <AdminWrapper>
                        <div className="light-bg br-sm py-3 px-3">
                              <h5 className="m-0">
                                    <span className="grey-bold-text">
                                          Admin /
                                    </span> Add new Agent
                              </h5>
                        </div>

                        <div className="light-bg my-2 container py-4 br-sm">
                              <BackButton />

                              <Form onSubmit={(e) => submitFn(e)} className="mt-4">
                                    <div className="row">

                                          <div className="col-md-6">
                                                <Label htmlFor="photo">
                                                      Cover Photo:
                                                </Label>

                                                <Input
                                                      accept="image/*"
                                                      type="file"
                                                      id="photo"
                                                      name='photo'
                                                      onChange={handleChange}
                                                />

                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="name">
                                                      Full Name:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="name"
                                                      id="name"
                                                      placeholder=""
                                                      onChange={handleChange}
                                                      value={formField.name}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">
                                                <Label htmlFor="email">
                                                      Email Address:
                                                </Label>
                                                <Input
                                                      type="email"
                                                      name="email"
                                                      id="email"
                                                      onChange={handleChange}
                                                      value={formField.email}
                                                      required
                                                />
                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="phone">
                                                      Phone Number :
                                                </Label>
                                                <Input
                                                      type="number"
                                                      name="phone"
                                                      id="phone"
                                                      onChange={handleChange}
                                                      value={formField.phone}
                                                      required
                                                />

                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="whatsapp">
                                                      Whatsapp Number :
                                                </Label>
                                                <Input
                                                      type="number"
                                                      name="whatsapp"
                                                      id="whatsapp"
                                                      onChange={handleChange}
                                                      value={formField.whatsapp}
                                                      required
                                                />

                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="card">
                                                      ID Card:
                                                </Label>

                                                <Input
                                                      accept="image/*"
                                                      type="file"
                                                      id="card"
                                                      name='card'
                                                      onChange={handleChange}
                                                />

                                          </div>

                                          <div className="col-md-6">

                                                <Label htmlFor="cardNumber">
                                                      ID Card Number:
                                                </Label>
                                                <Input
                                                      type="text"
                                                      name="cardNumber"
                                                      id="cardNumber"
                                                      onChange={handleChange}
                                                      value={formField.cardNumber}
                                                      required
                                                />
                                          </div>

                                          <div className="col-12 text-center">
                                                <Button
                                                      sec
                                                      className="btn"
                                                      type="submit"
                                                      disabled={sending}
                                                >
                                                      {sending ? 'loading...' : 'Submit'}
                                                </Button>
                                          </div>
                                    </div>
                              </Form>
                        </div>
                  </AdminWrapper>



            </>
      );
};

export default CreateAgents;