import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import { service1, service2, serviceHeader } from "../../assets";
import { service } from "../../utils/data";

const Service = () => {


      return (
            <HomeWrapper>
                  <StyledContact>
                        <div className="">
                              <h4 className="my-5 text-center"> Our Service </h4>
                              <img src={serviceHeader} alt="service" width="100%" />

                        </div>
                        <div className="container py-4 my-5 br-sm" style={{ background: '#FBFBFB' }}>

                              {
                                    service.map(({ img, title, content, reverse, color }, i) =>
                                          <div
                                                key={i}
                                                className={`row justify-content-between align-items-center mx-2 br-sm px-0 mb-4 ${ reverse && 'flex-row-reverse' } ${ color }`}>
                                                <div className="col-md-5 px-0 ">
                                                      <img src={img} alt={title} width='100%' className="br-sm" />
                                                </div>
                                                <div className="col-md-6 py-4 px-3 px-md-3 px-lg-5">
                                                      <h4 className="mb-4">
                                                            {title}
                                                      </h4>
                                                      <p className="">
                                                            {content}
                                                      </p>
                                                </div>
                                          </div>
                                    )
                              }

                        </div>
                  </StyledContact>
            </HomeWrapper>
      );
};

export default Service;

const StyledContact = styled.main`
      padding-bottom: 4rem 0;
`;