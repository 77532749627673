import React, { useContext, useEffect, useState } from 'react';
import { useTokenFetch } from '../api/useFetch';
import { toast } from 'react-toastify';
import SecureApi from '../api/secureApi';
import FetchApi from '../api/getAxios';
// import { useLocalStorage } from '../utils/useLocalStorage';
// import { base64ToObject, decrypt, encrypt } from '../encrypt';
// import { useLocation } from 'react-router-dom';

export const UserContext = React.createContext();


export const UserProvider = ({ children }) => {
    const userData = JSON.parse(localStorage.getItem('userdata'));

    const [accesstoken] = useState(JSON.parse(localStorage.getItem('accessToken')));
    let accessToken = accesstoken;


    const { loading: loadLoans, data: allLoans, fetchData: getLoans } = useTokenFetch('loan/apply');
    const { loading: loadPayment, data: allPayment, fetchData: getPayment } = useTokenFetch('payment/cash');
    const { loading: loadWishlist, data: allWishlist, fetchData: getWishlist } = useTokenFetch('wishlist/all');

    const [adding, setAdding] = useState(false);
    const [removing, setRemoving] = useState(false);


    // Add to WishList
    function addToWishlist(carId) {
        if (carId) {
            setAdding(true);
            const data = { carId, userId: userData?._id };
            SecureApi.post(`/wishlist/add`, data)
                .then((res) => {
                    if (res.status === false) {
                        toast.warn(res.message);
                        setAdding(false);
                    } else {
                        setAdding(false);
                        toast.success(res.message);
                        getWishlist();
                    }
                })
                .catch((error) => {
                    setAdding(false);
                });
        } else {
            setAdding(false);
            toast.warn("Select a car!");
        }
    }

    // Remove from WishList
    function removeFromWishlist(carId) {
        if (carId) {
            setRemoving(true);
            SecureApi.delete(`/wishlist?id=${ carId }`)
                .then((res) => {
                    if (res.status === false) {
                        toast.warn(res.message);
                        setRemoving(false);
                    } else {
                        getWishlist();
                        setRemoving(false);
                        toast.success(res.message);
                    }
                })
                .catch((error) => {
                    setRemoving(false);
                });
        } else {
            setRemoving(false);
            toast.warn("Select a car!");
        }
    }

    function getUserData() {
        if (accessToken) {
            FetchApi.get('users/auth/profile', {
                headers: {
                    'Authorization': `Bearer ${ accessToken }`
                }
            })
                .then((res) => {
                    // setuserData(res?.data.userInformation);
                    localStorage.setItem('userdata', JSON.stringify(res?.data.userInformation));
                })
                .catch((error) => {
                });
        }
    }

    function updateProfile(e, formField, setUpdating) {
        e.preventDefault();
        if (
            formField?.email &&
            formField.phone &&
            formField.firstName &&
            formField.lastName
        ) {
            setUpdating(true);
            const data = {
                firstName: formField.firstName,
                lastName: formField.lastName,
                phone: formField.phone,
                email: formField.email
            };
            SecureApi.put(`/users/auth/profile`, data)
                .then((res) => {
                    if (res.status === false) {
                        toast.warn(res.message);
                        setUpdating(false);
                    } else {
                        setUpdating(false);
                        toast.success(res.message);
                        getUserData();
                    }
                })
                .catch(() => {
                    setUpdating(false);
                });
        } else {
            setUpdating(false);
            toast.warn("Empty Fields!");
        }
    }

    function changePassword(e, formField, setChanging, setFormfield) {
        const { opword, npword, cpword } = formField;
        e.preventDefault();
        if (
            opword, npword, cpword
        ) {
            setChanging(true);
            const data = {
                old_password: opword,
                new_password: npword,
                new_password_confirmation: cpword,
            };
            SecureApi.post(`/users/auth/change-password`, data)
                .then((res) => {
                    if (res.status === false) {
                        toast.warn(res.message);
                        setChanging(false);
                    } else {
                        setChanging(false);
                        toast.success(res.message);
                        setFormfield({ ...formField, opword: '', npword: '', cpword: '' });
                    }
                })
                .catch(() => {
                    setChanging(false);
                });
        } else {
            setChanging(false);
            toast.warn("Empty Fields!");
        }
    }

    function changeAvatar(e, formField, setSaving, setformField) {
        e.preventDefault();
        if (
            formField
        ) {
            setSaving(true);

            const data = new FormData();
            data.append("image", formField);

            SecureApi.patch(`/users/auth/profile-pic`, data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
                .then((res) => {
                    if (res.status === false) {
                        toast.warn(res.message);
                        setSaving(false);
                    } else {
                        setSaving(false);
                        toast.success(res.data.message);
                        getUserData();
                        setformField(prev => ({ ...prev, photo: res.data.downloadurl || '' }));
                    }
                })
                .catch(() => {
                    setSaving(false);
                });
        } else {
            setSaving(false);
            toast.warn("Select an image!");
        }
    }



    return (
        <UserContext.Provider value={{
            userData, accessToken,

            loans: { loadLoans, allLoans, getLoans },
            payment: { loadPayment, allPayment, getPayment },

            wishlist: { addToWishlist, removeFromWishlist, adding, removing, loadWishlist, allWishlist, getWishlist },

            updateProfile, changePassword, changeAvatar

        }}>
            {children}
        </UserContext.Provider>
    );
};

// make sure use
export const useUserContext = () => {
    return useContext(UserContext);
};
