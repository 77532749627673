import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import 'jquery';
import "react-toastify/dist/ReactToastify.css";
import "react-glidejs/dist/index.css";
import './index.css';
import './styles/app.css';
import App from './app/app';
import { ToastContainer, Flip } from 'react-toastify';
import AppLoader from './utils/AppLoader';
import ScrollToTop from './utils/ScrollToTop';
import { UserProvider } from './context/user_context';
import { CarProvider } from './context/car_context';
import { AdminProvider } from './context/admin_context';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AdminProvider>
        <UserProvider>
          <CarProvider>
            <ScrollToTop />
            <App />
            <ToastContainer
              hideProgressBar={true}
              autoClose={1500}
              theme="colored"
              newestOnTop
              transition={Flip}
            />
            <AppLoader />
          </CarProvider>
        </UserProvider>
      </AdminProvider>
    </BrowserRouter>
  </React.StrictMode>
);