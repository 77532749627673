import styled from "styled-components";
import HomeWrapper from "../../layout/HomeWrapper";
import SearchHeader from "../../components/search/SearchPageHeader";
import AllCars from "../../components/CarPage/AllCars";


const AllCarsPage = () => {
      return (
            <HomeWrapper>
                  <SearchHeader />
                  <div className="container">
                        <StyledAllCarsPage>
                              <AllCars />
                        </StyledAllCarsPage>
                  </div>
            </HomeWrapper>
      );
};

export default AllCarsPage;

const StyledAllCarsPage = styled.main`
      padding: 2rem 0;

`;