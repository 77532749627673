import styled from "styled-components";
import UserWrapper from "../../layout/UserWrapper";
import MiniLoader from "../../utils/MIniLoader";
import CarItem from "../../components/CarPage/CarItem";
import { useUserContext } from "../../context/user_context";
import { useEffect } from "react";
import EmptyData from "../../utils/EmptyData";


const UserWishlist = () => {

      const {
            wishlist: { loadWishlist, allWishlist, getWishlist }
      } = useUserContext();

      useEffect(() => {
            getWishlist();
      }, []);

      return (
            <UserWrapper>
                  <StyledUserWishlist>

                        <div className="light-bg py-4 container-fluid br-sm min-height-lg">
                              <h5 className="mt-4">
                                    <span className="grey-bold-text">
                                          User /
                                    </span> Wishlist
                              </h5>
                              <hr />
                              <div className="d-flex mt-5">
                                    <h6 className="mb-0 fw-bold">
                                          Saved Cars
                                    </h6>
                                    <MiniLoader
                                          load={loadWishlist}
                                    />
                              </div>
                              <hr className="mb-5" />

                              <EmptyData
                                    data={allWishlist}
                                    title={'Wishlist'}
                              />
                              <div className="row">
                                    {allWishlist?.map((item, i) =>
                                          <div className="col-md-12 col-lg-6 mb-4" key={i}>
                                                <CarItem {...item} key={i} />
                                          </div>
                                    )}
                              </div>

                        </div>
                  </StyledUserWishlist>
            </UserWrapper>
      );
};

export default UserWishlist;

const StyledUserWishlist = styled.main`
      padding-bottom: 2rem;
`;