import { Navigate } from 'react-router-dom';
import { useLocalStorage } from '../utils/useLocalStorage';

const ProtectAdmin = ({ children }) => {

  const [mainAccess] = useLocalStorage('mainAccess');

  if (!mainAccess) {
    return <Navigate to="/noAccess" replace />;
  }
  return children;
};

export default ProtectAdmin;
