import { Link } from 'react-router-dom';
import { ICON_BELL, ICON_LIST } from '../../../utils/icons';
import { Hamburger, StyledTopNav } from './Nav.styled';
import { logo } from '../../../assets';

const TopNav = () => {

    // Open Navbar 
    const openNav = (e) => {
        document.getElementById("mySidenav").style.width = "250px";
    };
    // Open Navbar

    return (
        <StyledTopNav
            className="navbar bottom-border navbar-light fixed-top shadow-none"
        >
            <div className="container-fluid">
                <div className="d-flex">
                    <Hamburger
                        onClick={(e) => openNav(e)}
                    >
                        {ICON_LIST}
                    </Hamburger>

                    {/* <span className="page-title">
                        {pagename}
                    </span> */}
                </div>
                <li className="list-unstyled mr-auto ml-5 pl-4 d-flex">
                    <Link to='#'
                        className='grey-bold-text mx-2'
                    >
                        <span className="position-relative" >
                            {ICON_BELL}
                            {/* {unread >= 1 &&
                                <span className="position-absolute bell translate-middle badge m-0 rounded-pill pry light-text "
                                >
                                    {unread > 99 ? '99+' : unread}
                                </span>
                            } */}
                        </span>
                    </Link>
                    <Link to='/admin/setting'
                        className='grey-bold-text mx-2'
                    >
                        <img src={logo} alt="user_picture" />
                    </Link>
                </li>

            </div>
        </StyledTopNav>
    );
};

export default TopNav;