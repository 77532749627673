import { Routes, Route } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import Home from '../pages/homepage/Home';
import NoPageAccess from '../pages/NoPageAccess';
import Login from '../pages/auth/Login';
import SearchPage from '../pages/homepage/SearchPage';
import SearchResult from '../components/search/SearchResult';
import Register from '../pages/auth/Register';
import CarDetails from '../components/CarPage/CarDetails';
import AllCarsPage from '../pages/homepage/AllCarsPage';
import BuyForm from '../components/CarPage/ApplyForCar/BuyForm';
import LoanForm from '../components/CarPage/ApplyForCar/LoanForm';
import LoanSetup from '../components/CarPage/ApplyForCar/LoanSetup';
import AdminLogin from '../pages/admin/AdminLogin';
import Contact from '../pages/homepage/Contact';
import AdminDashboard from '../pages/admin/AdminDashboard';
import AdminCar from '../pages/admin/AdminCar';
import AllCars from '../components/admin/cars/AllCars';
import AddCar from '../components/admin/cars/AddCar';
import EditCar from '../components/admin/cars/EditCar';
import ProtectUser from '../app/protectUser';
import UserDashboard from '../pages/user/UserDashboard';
import UserCar from '../pages/user/UserCar';
import UserLoan from '../pages/user/UserLoan';
import UserWishlist from '../pages/user/UserWishlist';
import UserSetting from '../pages/user/UserSetting';
import LoanReview from '../components/user/loan/LoanReview';
import UserBuyRequest from '../pages/user/UserBuyRequest';
import About from '../pages/homepage/About';
import Service from '../pages/homepage/Service';
import AdminAgent from '../pages/admin/AdminAgent';
import AdminDealer from '../pages/admin/AdminDealer';
import ForgetPassword from '../pages/auth/ForgetPassword';
import CreateAgents from '../components/admin/agents/CreateAgents';
import CreateDealers from '../components/admin/dealers/CreateDealers';
import EditDealer from '../components/admin/dealers/EditDealer';
import EditAgent from '../components/admin/agents/EditAgent';

const Router = () => {
    return (
        <Routes>

            {/* Landing */}
            <Route path="/" element={<Home />} />

            {/* Landing */}

            {/* Search */}
            <Route path='search'>
                <Route index element={<SearchPage />} />
                <Route path="result" element={<SearchResult />} />
            </Route>
            {/* Search */}

            {/* Cars */}
            <Route path='cars'>
                <Route index element={<AllCarsPage />} />
                <Route path=":id" element={<CarDetails />} />
                <Route path="buy/:id" element={
                    <ProtectUser>
                        <BuyForm />
                    </ProtectUser>
                } />
                <Route path="loan/setup/:id" element={<LoanSetup />} />
                <Route path="loan/:id" element={
                    <ProtectUser>
                        <LoanForm />
                    </ProtectUser>
                } />
            </Route>
            {/* Cars */}

            {/* Contact */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Service />} />
            <Route path="/about" element={<About />} />
            {/* Contact */}

            {/* User */}
            <Route path='user'>
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forget-password" element={<ForgetPassword />} />

                <Route path="dashboard" element={<UserDashboard />} />
                <Route path="wishlist" element={<UserWishlist />} />
                <Route path='purchase'>
                    <Route index element={<UserCar />} />
                    {/* <Route path=":id" element={<EditCar />} /> */}
                </Route>
                <Route path='loans'>
                    <Route index element={<UserLoan />} />
                    <Route path=":id" element={<LoanReview />} />
                </Route>
                <Route path='buy-request'>
                    <Route index element={<UserBuyRequest />} />
                    {/* <Route path=":id" element={<LoanReview />} /> */}
                </Route>
                <Route path="setting" element={<UserSetting />} />
            </Route>
            {/* User


{/* Admin */}
            <Route path='admin'>
                <Route index element={<ErrorPage />} />
                <Route path="login" element={<AdminLogin />} />
                <Route path="dashboard" element={<AdminDashboard />} />

                <Route path='cars'>
                    <Route index element={<AdminCar />} />
                    <Route path="all" element={<AllCars />} />
                    <Route path="add" element={<AddCar />} />
                    <Route path=":id" element={<EditCar />} />
                </Route>
                <Route path='agents'>
                    <Route index element={<AdminAgent />} />
                    <Route path="add" element={<CreateAgents />} />
                    <Route path=":id" element={<EditAgent />} />

                </Route>
                <Route path='dealers'>
                    <Route index element={<AdminDealer />} />
                    <Route path="add" element={<CreateDealers />} />
                    <Route path=":id" element={<EditDealer />} />

                </Route>
            </Route>
            {/* Admin */}




            {/* Error */}
            <Route path="noAccess" element={<NoPageAccess />} />
            <Route path="*" element={<ErrorPage />} />

        </Routes>
    );
};

export default Router;