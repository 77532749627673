import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../../styles";
import { userNavLink } from "../../../utils/data";
import { removeData } from "../../../utils/helpers";
import { ICON_LOGOUT, ICON_SETTING } from "../../../utils/icons";
import { logo } from "../../../assets";

const NavLinks = () => {

  const navigate = useNavigate();

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); };
  // Modal

  function logout() {

    removeData('accessToken');
    toast.success('Logout successful! Redirecting...');
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }

  return (
    <>
      <div className="ml-3">
        <h4 className="ms-4 mt-3">
          User
        </h4>
      </div>


      <div className="row align-content-between"
        style={{ height: '90%' }}
      >
        <div className="col-12">
          <ul className="navbar-nav mt-3">
            {userNavLink.map(({ title, to, icon }, i) => {
              return (
                <li className="nav-item" key={i}>
                  <NavLink to={to}>
                    {icon}
                    {title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-12 border-top">
          <ul className="navbar-nav mt-3">
            <li className="nav-item">
              <NavLink to='/user/setting'>
                {ICON_SETTING}
                Setting
              </NavLink>

            </li>
            <li className="nav-item">
              <a className="text-danger pointer"
                onClick={() => handleShow()}
              >
                {ICON_LOGOUT}
                Logout
              </a>
            </li>
          </ul>

        </div>
      </div>




      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        size="sm"
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body >
          <div className=" text-center ">
            <div className="text-danger br-lg shadow fit-content mx-auto px-3 mb-3" style={{ fontSize: '3rem' }}>
              {ICON_LOGOUT}
            </div>
            <h5 className="my-5">
              Are you sure you want to logout?
            </h5>

            <div className="d-flex gap-2">
              <Button className="btn bg-danger light-text w-50"
                onClick={() => logout()}>
                Confirm
              </Button>
              <Button className="btn border border-danger text-danger w-50"
                onClick={() => handleClose()}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default NavLinks;
