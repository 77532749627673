import styled from "styled-components";
import { whyAuto } from "../../utils/data";
import { car2, shield } from "../../assets";

const WhyAutoSale = () => {

      return (
            <div className="container">
                  <StyledWhyAutoSale>
                        <div className="row justify-content-between align-items-center">
                              <div className="col-md-6">
                                    <img src={car2} alt="Cars" width='100%' />

                              </div>

                              <div className="col-md-4 mt-5 mt-md-0 text-center text-md-start">
                                    <h3 className="mb-5 mt-5 mt-md-0">
                                          Why AutoBest?
                                    </h3>
                                    {whyAuto?.map((text, i) =>
                                          <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-3" key={i}>
                                                <img src={shield} alt="Cars" width='30%' />
                                                <h5 className=" shadow py-4 px-3 br-md">
                                                      {text}
                                                </h5>
                                          </div>

                                    )}

                              </div>

                        </div>
                  </StyledWhyAutoSale>
            </div>
      );
};

export default WhyAutoSale;

const StyledWhyAutoSale = styled.section`
      padding: 4rem 0;     
overflow-x: hidden;

`;